import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";

// mui icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";

//
// HOW TO USE:
//
// Category {
//   title: string (required, the title of the category),
//   icon: Material UI Icon component (optional, the icon of the category),
//   info: string (optional, the info of the category),
//   items: array of Item components (required, the items of the category),
// }
//
// Item {
//   title: string (required, the title of the item),
//   href: string (required, the href of the a tag),
// }
//

export const Category = ({
  title,
  icon = null,
  info = false,
  children,
  darkMode,
  style,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div
      style={{
        borderRadius: "0.6rem",
        ...style,
      }}
    >
      <div
        style={{
          margin: "0.4rem 0.8rem",
          color: darkMode ? "white" : "var(--purple)",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "0.4rem",
        }}
      >
        <div>
          {icon}
          <span>{title}</span>
        </div>
        {info && (
          <div
            style={{ position: "relative" }}
            onMouseEnter={() => {
              setShowInfo(true);
            }}
            onMouseLeave={() => {
              setShowInfo(false);
            }}
          >
            <InfoOutlinedIcon
              sx={{ opacity: showInfo ? 1 : 0.6, transition: "400ms ease" }}
            />
            <div
              style={{
                // backgroundColor: "#3A3D4D",
                backgroundColor: "white",
                boxShadow: "0 1rem 2rem rgb(0 0 0 / 10%), 0 0 0 0.1rem rgb(43 43 43 / 10%)",
                position: "absolute",
                left: "-25rem",
                top: "2rem",
                width: "34rem",
                padding: "1.4rem 2rem",
                borderRadius: "0.6rem",
                zIndex: 20,
                transition: `transform 250ms ${
                  showInfo ? "0ms" : "250ms"
                } ease, opacity 250ms ${
                  showInfo ? "0ms" : "250ms"
                } ease, visibility 0ms ${showInfo ? "0ms" : "500ms"}`,
                ...(showInfo
                  ? {
                      transform: "scale(1) translateY(0)",
                      opacity: 1,
                      visibility: "visible",
                    }
                  : {
                      transform: "scale(0.92) translateY(-2rem)",
                      opacity: 0,
                      visibility: "hidden",
                    }),
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.6rem",
                }}
              >
                {info}
              </Typography>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0.4rem 0 0 0.8rem",
          gap: "0.12rem",
          borderRadius: "0.6rem",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const Item = ({ title, href, darkMode = false, style }) => {
  return (
    <a
      href={href}
      style={{
        color: darkMode ? "white" : "var(--purple)",
        textDecoration: "none",
        overflow: "hidden",
        ...style,
      }}
      target="_self"
    >
      <Box
        sx={{
          padding: "0.4rem 0.8rem",
          "&:hover": {
            background: darkMode ? "rgba(255,255,255,0.1)" : "#ededed",
          },
        }}
      >
        <span>{title}</span>
      </Box>
    </a>
  );
};
