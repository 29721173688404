import { Route, Routes, Navigate } from "react-router-dom";
import ServicosComponent from "./ServicosComponent";
import {page_data} from "./data.js" 
import BmCursosInfo from "./ServicosComponent/BmCursosInfo";

const pages = [
  "/BM-Kids",
  "/BM-Pro",
  "/BM-Senior",
  "/BM-Voyage",
  "/BM-Test",
  "/BM-VIP",
  "/BM-Talks",
]

const ForNaturalPerson = () => {
  return (
    <Routes>
      {pages.map((page, index) => (
        <Route
          key={index}
          path={page}
          element={<ServicosComponent data={page_data[page]} />}
        />
      ))}

      <Route path="bm-cursos/o-que-sao" element={<BmCursosInfo />} />

      {/* generic 404 */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default ForNaturalPerson;