import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, IconButton, Input, Pagination } from "@mui/material";

// mui icons
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { DELETE, GET } from "../../utilities/requests";
import { renderAlert } from "../../utilities/alerts";
import useSearch from "../../utilities/hooks/useSearch";

const ListTags = () => {
  const history = useNavigate();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pageNumber, setPageNumber] = React.useState(1)
  const [pagination, setPagination] = React.useState({
    current_page: 0,
    total_pages: 0,
    per_page: 0,
  });
  const { search, handleSearch } = useSearch(setLoading)

  React.useEffect(() => {
    getData()
  }, [pageNumber, search]);

  React.useEffect(() => {
    setPageNumber(1)
  }, [search]);

  const getData = async () => {
    const response = await GET(`tag/list?page=${pageNumber}&search=${search}`)
    setLoading(false)

    if (!response) {
      return
    }

    setData(response.tags)
    setPagination(response.pagination)
  }

  const handleDelete = async (id) => {
    const response = await DELETE(`tag/delete/${id}`)

    if (!response) {
      return false
    }

    setData(data.filter(item => item.id !== id))
    return response.message
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex">
          <div className="flex-column">
            <h1 className="header-title">Lista de tags</h1>
            <Input
              placeholder='Buscar...'
              endAdornment={<SearchIcon />}
              onChange={handleSearch} />
          </div>
          <div className="align-self-end ms-auto">
            <Button
              variant="contained"
              size="large"
              endIcon={<AddRoundedIcon />}
              onClick={() => history("/home/tags/add")}>
              Adicionar
            </Button>
          </div>
        </div>
        <table className="table table-striped table-hover text-center mt-5">
          <thead className="text-center">
            <tr>
              <th scope="col">Tag</th>
              <th scope="col">Descrição</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          {!loading ?
            <tbody>
              {data.length > 0 ? data.map(item => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.description ?? 'N/A'}</td>
                  <td>
                    <IconButton size="large">
                      <EditIcon
                        size="large"
                        color="secondary"
                        onClick={() => history(`/home/tags/edit/${item.id}`)}
                      />
                    </IconButton>

                    <IconButton size="large">
                      <DeleteIcon color="error" onClick={() => renderAlert(async () => handleDelete(item.id))} />
                    </IconButton>
                  </td>
                </tr>
              ))
                : <tr><td colSpan={3}>Sem registros cadastrados</td></tr>}
            </tbody>
            :
            <tbody>
              <tr><td colSpan={3}><CircularProgress /></td></tr>
            </tbody>
          }
        </table>
        <div className='d-flex justify-content-end'>
          {pagination.total_pages !== 0 &&
            <Pagination
              color='primary'
              shape="rounded"
              count={Math.ceil(pagination.total_pages / pagination.per_page)}
              page={pagination.page_number}
              onChange={(e, page) => setPageNumber(page)} />}
        </div>
      </div>
    </div>
  );
};

export default ListTags;
