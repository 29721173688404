import { Box } from '@mui/material'
import React from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { URL } from '../../variables'
import { useSelector } from 'react-redux'
import { FaUserCog } from 'react-icons/fa'

const UserDashboard = () => {
  const history = useNavigate()
  const user = useSelector(state => state.AppReducer.user)

  return (
    <div className="d-flex vh-100">
      <div className="col-3 text-white" style={{ backgroundColor: '#8b7db2' }}>
        <div className="d-flex justify-content-center flex-column align-items-center mt-5">
          <div className='text-center' style={{ width: 75, height: 75 }}>
            {user.file_path
              ? <img src={`${URL}storage/${user.file_path}`} alt="img_user" style={{ width: 75, height: 75, borderRadius: '50%' }} />
              : <div style={{ width: 75, height: 75, borderRadius: '50%', backgroundColor: '#F7F7F7' }}>
                <p style={{ fontSize: '3rem', fontWeight: '700', color: '#8b7db2', margin: 0 }}>{user.name.charAt(0).toUpperCase()}</p>
              </div>}
          </div>
          <p className='mt-2' style={{ fontWeight: 300 }}>Bem vindo {user.name}!</p>

          <div className="mt-5">
            <div className="d-flex align-items-center pointer">
              <FaUserCog className='me-2' size={23} />
              <p onClick={() => history('/user/profile')} style={{ fontSize: '1.3rem', fontWeight: 300 }}>Meu Perfil</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-9">
        <div className="rounded shadow bg-white p-5 m-5">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default UserDashboard