import React from 'react'
import { Calendar } from 'react-calendar'
import Input from '../NewsLetter/Input';
import { useSelector } from "react-redux";
import { TextField, IconButton, Button, CircularProgress, Typography, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Table, Alert, InputLabel, FormControl, Select, MenuItem, ThemeProvider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
// import 'react-calendar/dist/Calendar.css';
import ModalPartners from './ModalPartners';
import dayjs from 'dayjs';
import { URL } from '../../variables';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Theme from '../Theme/Theme';
import './Calendar.css';

const Schedules = ({ edit }) => {
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const [calendar, setCalendar] = React.useState([]);
  const [form, setForm] = React.useState({
    descricao: { label: 'Descrição*', value: '', error: false, type: 'multiline' },
    responsavel: { label: 'Responsável*', value: '', id: '', error: false, type: 'select' },
    agenda: { label: 'Tipo de Agenda*', value: '', id: '', error: false, type: 'select' },
    horario: { label: 'Horário', value: '', id: '', error: false, type: 'time' },
    loading: false,
  })
  const [state, setState] = React.useState({
    fillResponsavel: [],
    fillParticipantes: [],
    participantesInseridos: [],
    subjects: [],
    classes: [],
    subject_id: '',
    class_id: '',
    nullParticipantes: false,
    loading_save: false,
    tiposAgenda: [{ name: 'Aula', id: 1 }, { name: 'Reunião', id: 2 }, { name: 'Reposição', id: 3 }, { name: 'Evento', id: 4 }],
    loading: true,
    pagination: { current_page: '', total_pages: '', per_page: '' },
    pageNumber: 1,
    fillEvents: [],
    user: '',
  })
  const [loadingTable, setLoadingTable] = React.useState(false);
  const token = useSelector(state => state.AppReducer.token);
  const history = useNavigate();

  // -----------------------------------------Effect to load user permission----------------------------------------------------
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    fetch(`${URL}api/type_user/get_type_users/${user.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        let inseridos = []

        // ----If the prop edit comes full (sign that is an item for update) the form fields will receive the value of json-----
        if (edit) {
          form.loading = true

          // ---------------------------------------Adding each participant to the list-----------------------------------------
          let type = state.tiposAgenda.filter(item => item.name == edit.schedule.type)[0]
          edit.schedule.partners_schedule.forEach(item => {
            let participante = {
              id: item.partner_id,
              name: item.partner_user.name,
            }
            inseridos = [...inseridos, participante]
          })
          // ------------------------------------------------Formatting date----------------------------------------------------
          let data = edit.schedule.date.replace(/-/g, ',')
          setDate(new Date(data))

          setForm({
            ...form,
            responsavel: { ...form.responsavel, value: edit.schedule.responsible_schedule.name, id: edit.schedule.responsible_schedule.id },
            agenda: { ...form.agenda, value: type.name, id: type.id },
            descricao: { ...form.descricao, value: edit.schedule.description },
            loading: false,
          })
        }
        // ------------------Regardless of whether it is a value for update or not, the states will be saved--------------------
        setState({
          ...state,
          fillResponsavel: json.resps,
          participantesInseridos: inseridos,
          loading: false,
          user: user,
        })
      });
    // -------------------------------------Calling function that loads holidays via API----------------------------------------
    feriados()
    // searchEvent(new Date())
  }, [])

  // --------------------------------------Function for rendering inserted participants-----------------------------------------
  function renderInseridos() {
    return (
      state.participantesInseridos.map(item =>
      (
        <TextField variant='filled' className='m-3' disabled value={item.name} InputProps={{
          endAdornment: <IconButton><CloseIcon onClick={() => {
            let inseridos = state.participantesInseridos.filter(item2 => item2.id != item.id)
            setState({ ...state, participantesInseridos: inseridos })
          }} /></IconButton>,
        }} />
      )
      )
    )
  }

  // --------------------Function for registering or editing schedule based on type called, add or update-----------------------
  function storeSchedule(type) {
    setState({ ...state, loading_save: true })
    let schedule_id = ''
    if (edit) schedule_id = edit.schedule.id
    fetch(`${URL}api/schedule/${type}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ responsible_id: form.responsavel.id, description: form.descricao.value, type: form.agenda.value, date: date.toDateString(), hour: dayjs(form.horario.value).format('hh:mm'), partners: state.participantesInseridos, schedule_id: schedule_id, subject_id: state.subject_id, class_id: state.class_id })
    })
      .then(async (result) => {
        const json = await result.json();
        setState({ ...state, loading_save: false })
        return json;
      }).then((result) => {
        console.log('res', result)
        if (result.status == false) {

        } else {
          history('/home/schedules');
        }
      })
  }

  // ----------------------------Function to send out the current year and receives its holidays--------------------------------
  function feriados() {
    const year = new Date()
    fetch(`https://brasilapi.com.br/api/feriados/v1/${year.getFullYear()}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then(async (result) => {
        let dates = []
        // ------Adding each json holiday in the dates variable-----------------------------------------------------------------
        result.map(item => {
          // ----Formatting each date-------------------------------------------------------------------------------------------
          let date_split = item.date.split('-')
          item = new Date(date_split[0], date_split[1] - 1, date_split[2])
          dates = [...dates, item]
          return item
        })
        setCalendar(dates)
      })
  }

  // -----------------------------Function to fill "History" table that shows current schedules---------------------------------
  function searchEvent(e) {
    setLoadingTable(true)
    state.fillEvents = []
    setDate(e)
    // ------------------Formatting in a way that the API can fetch the date in bd, using the dayjs library---------------------
    const formatedDate = dayjs(e).format('YYYY-MM-DD')
    fetch(`${URL}api/schedule/search_events/${formatedDate}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then(async (result) => {
        setState({ ...state, fillEvents: result })
        setLoadingTable(false)
      })
  }

  // --------------------------------------Function to validate the necessary form data-----------------------------------------
  function dataValidate() {
    const keys = Object.keys(form)
    let form2 = { ...form }
    let error = false

    keys.forEach(item => {
      if (form2[item].value == '' && form2[item].type != 'time') {
        form2[item].error = true
        error = true
      }
    })

    setForm(form2)
    if (state.participantesInseridos.length == 0) setState({ ...state, nullParticipantes: true })
    // If the error has false, will call the storeSchedule function according to the prop edit to pass the type as update or add
    if (!error) {
      if (edit) storeSchedule('update'); else storeSchedule('add')
    }
  }

  function get_modules() {
    fetch(`${URL}api/schedule/get_subjects`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then(async (result) => {
        setState({ ...state, subjects: result.subjects })
      })
  }

  // ------Component return-----------------------------------------------------------------------------------------------------
  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          {!state.loading && !form.loading ?
            <>
              <div className="row mb-5">
                <h1 className="title">Agendar atividades</h1>
                <span className='lead'>Agende compromissos, atividades e mantenha-se organizado!</span>
              </div>
              {/*------Selects and modal button------------------------------------------------------------------------------ */}
              <div className="row">
                <div className="col-12 d-flex flex-row justify-content-center align-items-start">
                  {/* <Input item={'agenda'} form={form} setForm={(e) => setForm(e)} fill={state.tiposAgenda} /> */}
                  <FormControl className='mx-3' fullWidth>
                    <InputLabel htmlFor='id'>Agenda*</InputLabel>
                    <Select label='Agenda*' id='id' error={form.agenda.error} value={form.agenda.value} onChange={(e) => {
                      if (e.target.value == 'Aula' || e.target.value == 'Reposição') get_modules(e.target.value)
                      setForm({
                        ...form, agenda: { ...form.agenda, value: e.target.value, error: false }
                      })
                    }}>{state.tiposAgenda.map(item => (<MenuItem value={item.name}>{item.name}</MenuItem>))}</Select>
                  </FormControl>
                  {console.log('teste', state.fillResponsavel)}
                  <FormControl className='mx-3' fullWidth>
                    <InputLabel htmlFor='id'>Responsável*</InputLabel>
                    <Select id='id' error={form.responsavel.error} label='Responsável*' value={form.responsavel.id} onChange={(e) => {
                      setForm({ ...form, responsavel: { ...form.responsavel, id: e.target.value, error: false } })
                    }}>{state.fillResponsavel.filter(item => !item.type_user[0].student).map(item => (<MenuItem value={item.id}>{item.name}</MenuItem>))}</Select>
                  </FormControl>

                  <Button className='mx-md-3 mt-3' variant='outlined' color={'inherit'} fullWidth onClick={() => setOpen(!open)}>Adicionar Participantes*</Button>
                </div>
              </div>

              {(form.agenda.value == 'Aula' || form.agenda.value == 'Reposição') &&
                <div className="row mx-1 my-3">
                  <div className="col-6">
                    <FormControl fullWidth>
                      <InputLabel htmlFor='id'>Módulo*</InputLabel>
                      <Select label='Módulo*' id='id' value={state.subject_id} onChange={(e) => {
                        const classes = state.subjects.filter(item => item.id == e.target.value)[0]
                        setState({ ...state, subject_id: e.target.value, classes: classes.classes })
                      }}>
                        {state.subjects.map(item => (
                          <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-6">
                    <FormControl fullWidth>
                      <InputLabel htmlFor='id'>Aula*</InputLabel>
                      <Select label='Aula*' value={state.class_id} id='id' onChange={(e) => setState({ ...state, class_id: e.target.value })}>
                        {state.classes.length > 0 ? state.classes.map(item => (
                          <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                        )) : 'Sem aulas disponíveis'}
                      </Select>
                    </FormControl>
                  </div>
                </div>}

              {/*-----Render partners div--------------------------------------------------------------------------------------*/}
              <div className="row my-5">
                {state.participantesInseridos.length > 0 &&
                  <div>
                    <h3>Participantes inseridos</h3>
                    {renderInseridos()}
                  </div>}
              </div>

              {/*------Static inputs section-----------------------------------------------------------------------------------*/}
              <div className="row mt-5 pt-xl-5">
                <div className="col-xl-4 col-lg-6 mb-5">
                  <div className="calendar-container d-flex justify-content-center">
                    <Calendar className='me-md-5' onChange={(e) => searchEvent(e)} value={date} tileDisabled={({ date, view }) =>
                      (view === 'month') && // Block day tiles only
                      calendar.some(disabledDate =>
                        date.getFullYear() === disabledDate.getFullYear() &&
                        date.getMonth() === disabledDate.getMonth() &&
                        date.getDate() === disabledDate.getDate()
                      )} />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                  <div className='mx-md-5 d-flex justify-content-center'>
                    <Input item={'horario'} form={form} setForm={(e) => setForm(e)} />
                  </div>
                </div>
                <div className="col-xl-4 col-12">
                  {/* Static part of table */}
                  <TableContainer component={Paper} sx={{ maxHeight: 400, height: 400 }}>
                    <Typography textAlign={'center'}>HISTÓRICO DE COMPROMISSOS</Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Data</TableCell>
                          <TableCell>Hora</TableCell>
                          <TableCell>Compromisso</TableCell>
                          <TableCell>Descrição</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.fillEvents.length > 0 && state.fillEvents.map(item => {
                          let date_split = item.date.split('-')
                          let date = date_split[2] + '/' + date_split[1]
                          return (
                            <TableRow key={item.id}>
                              <TableCell>{date}</TableCell>
                              <TableCell>{item.hour ? item.hour : 'N/A'}</TableCell>
                              <TableCell>{item.type}</TableCell>
                              <TableCell>{item.description}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                    {loadingTable && <div className='d-flex justify-content-center m-5'><CircularProgress /></div>}
                    {state.fillEvents.length == 0 && !loadingTable && <Typography className='m-5' textAlign={'center'}>SEM REGISTROS</Typography>}
                  </TableContainer>
                </div>
              </div>

              {/*-----Description input--------------------------------------------------------------------------------------- */}
              <div className="row mb-5">
                <Input item={'descricao'} form={form} setForm={(e) => setForm(e)} />
              </div>

              <div className="d-flex mt-5">
                <div className="align-self-center">
                  <Button variant="outlined" onClick={() => history('/home/schedules')} startIcon={<ReplyAllIcon />}> Voltar</Button>
                </div>
                <div className="align-self-center ms-auto">
                  <LoadingButton loading={state.loading_save} onClick={() => dataValidate()} loadingPosition="end" endIcon={<SaveIcon />} variant="outlined">Salvar</LoadingButton>
                </div>
              </div>
            </> : <div className="d-flex justify-content-center p-sm-5 m-sm-5"><CircularProgress /></div>}
        </div>
        <div className="fixed-top text-center">
          {state.nullParticipantes ?
            <Alert severity='error' textAlign={'center'}>Adicione ao menos um participante</Alert> : ''}
        </div>
        {open &&
          // -----------Calling modal component----------------------------------------------------------------------------------
          <ModalPartners open={open} setOpen={(e) => setOpen(e)} state={state} setState={(e) => setState(e)} />}
      </div>
    </ThemeProvider>
  )
}

export default Schedules