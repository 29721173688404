import { ThemeProvider } from '@mui/material';
import './App.css'
import RoutesContainer from './routes';
import { ToastContent } from './utilities/alerts';
import Theme from './routes/Theme/Theme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <RoutesContainer />
        <ToastContent />
      </ThemeProvider>
    </div>
  );
}

export default App;
