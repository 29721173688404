import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Signup from "../pages/Auth/Signup";
import Home from "../pages/Private/Home";

import GuestRoutes from "./GuestRoutes";
import ListUser from "./User/ListUser";
import AddUser from "./User/AddUser";
import EditUser from "./User/EditUser";

import ListNews from "./NewsLetter/ListNews";
import ListUserReports from "./UserReports/ListUserReports";
import ReplyNews from "./NewsLetter/ReplyNews";

import PolicyAndTerms from "./PolicyAndTerms";

import Schedules from "./Schedule/Schedules";
import AddSchedules from "./Schedule/AddSchedule";
import EditSchedule from "./Schedule/EditSchedule";
import Testimonies from "./Testimonies";
import ListClasses from "./Classes/ListClasses";
import AddClassroom from "./Classes/AddClassroom";
import EditClassrom from "./Classes/EditClassrom";
import ListSubject from "./Subject/ListSubject";
import AddSubject from "./Subject/AddSubject";
import EditSubject from "./Subject/EditSubject";
import Subject from "./Subject/Subject";
import Dashboard from "./Dashboard";

// blog
import ListPost from "./Blog/ListPost";
import AddPost from "./Blog/AddPost";
import EditPost from "./Blog/EditPost";

// work with us
import ListWorkWithUs from "./WorkWithUs/ListWorkWithUs";
import ReplyWorkWithUs from "./WorkWithUs/ReplyWorkWithUs";

// blog tags
import ListBlogTags from "./Blog/Tags/ListPost";
import AddBlogTag from "./Blog/Tags/AddPost";
import EditBlogTag from "./Blog/Tags/EditPost";

// e-books
import AddEbook from "./Ebook/AddEbook";
import ListEbooks from "./Ebook/ListEbooks";
import EditEbook from "./Ebook/EditEbook";
import ListNewUsers from "./NewUsers/ListNews";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import PrimeiroAcessoPage from "../pages/Auth/PrimeiroAcesso";
import CustomPageBlog from "./Blog/CustomPage";
import PainelAlunoPage from "../pages/PainelAluno";

// tags
import ListTags from "./Tags/ListTags";
import AddTag from "./Tags/AddTag";
import EditTag from "./Tags/EditTag";
import ListSubjectTags from "./Subject/SubjectTag/ListSubjectTags";
import AddSubjectTag from "./Subject/SubjectTag/AddSubjectTag";
import EditSubjectTag from "./Subject/SubjectTag/EditSubjectTag";
import UserDashboard from "../pages/UserDashboard/UserDashboard";
import Profile from "../pages/UserDashboard/Profile/Profile";

const PrivateRoute = (props) => {
  // const stream = useSelector(state => state.AppReducer.stream);
  const token = useSelector((state) => state.AppReducer.token);

  const user = JSON.parse(localStorage.getItem("user"));
  if (!user?.type_user[0]?.adm) {
    return <Navigate to={{ pathname: "/" }} />;
  }

  return token !== null ? (
    <Home {...props}></Home>
  ) : (
    <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  );
};

const StudentRoute = (props) => {
  // const stream = useSelector(state => state.AppReducer.stream);
  const token = useSelector((state) => state.AppReducer.token);

  const user = JSON.parse(localStorage.getItem("user"));
  if (!user?.type_user[0]?.student) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return token !== null ? (
    <PainelAlunoPage {...props} />
  ) : (
    <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
  );
};

const PrivateUserRoute = (props) => {
  const token = useSelector(state => state.AppReducer.token)

  return token != null ? (
    <UserDashboard {...props}></UserDashboard>) : (
    <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
  )
};

const LoginRoute = (props) => {
  const token = useSelector((state) => state.AppReducer.token);

  return token === null ? (
    <Login {...props} />
  ) : (
    <Navigate
      to={{ pathname: "/home/dashboard", state: { from: props.location } }}
    />
  );
};

const RoutesContainer = () => {
  const dispatch = useDispatch();
  let token = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  if (user == null || user == undefined) {
    user = {};
  }
  console.log(token);
  dispatch({ type: "login", payload: { token: token, user: user } });
  return (
    <Routes>
      <Route path={"/login"} element={<LoginRoute />} />
      <Route path={"/signup"} element={<Signup />} />
      <Route path={"/forgot-password"} element={<ForgotPassword />} />
      <Route path={"/primeiro-acesso"} element={<PrimeiroAcessoPage />} />
      <Route path={"/painel-aluno"} element={<StudentRoute />} />
      <Route path={"/home"} element={<PrivateRoute />}>
        <Route path={"dashboard"} element={<Dashboard />} />

        <Route path={"list"} element={<ListUser />} />
        <Route path={"add"} element={<AddUser />} />
        <Route path={"edit/:id"} element={<EditUser />} />

        <Route path={"news/list"} element={<ListNews />} />
        <Route path={"user-reports/list"} element={<ListUserReports />} />
        <Route path={"news/reply/:id"} element={<ReplyNews />} />
        <Route path={'list'} element={<ListUser />} />
        <Route path={'add/:pendentUserId'} element={<AddUser />} />
        <Route path={'edit/:id'} element={<EditUser />} />

        <Route path={"policy-and-terms"} element={<PolicyAndTerms />} />
        <Route path={"testimonies"} element={<Testimonies />} />

        <Route path={"schedules"} element={<Schedules />} />
        <Route path={"schedules/add"} element={<AddSchedules />} />
        <Route path={"schedules/edit/:id"} element={<EditSchedule />} />

        <Route path={"classes"} element={<ListClasses />} />
        <Route path={"classes/add/:id"} element={<AddClassroom />} />
        <Route path={"classes/edit/:id"} element={<EditClassrom />} />

        <Route path={"subjects"} element={<ListSubject />} />
        <Route path={"subjects/add"} element={<AddSubject />} />
        <Route path={"subjects/edit/:id"} element={<EditSubject />} />
        <Route path={"subjects/subject/:id"} element={<Subject />} />

        {/* blog */}
        {/* <Route path={"blog/custom"} element={<CustomPageBlog />} /> */}
        <Route path={"blog/list"} element={<ListPost />} />
        <Route path={"blog/add"} element={<AddPost />} />
        <Route path={"blog/edit/:id"} element={<EditPost />} />

        {/* work with us */}
        <Route path={"work-with-us/list"} element={<ListWorkWithUs />} />
        <Route path={"work-with-us/reply/:id"} element={<ReplyWorkWithUs />} />

        {/* new users */}
        <Route path={"new-users/list"} element={<ListNewUsers />} />

        {/* tags */}
        <Route path={"blog/tags/list"} element={<ListTags />} />
        <Route path={"blog/tags/add"} element={<AddTag />} />
        <Route path={"blog/tags/edit/:id"} element={<EditTag />} />

        {/* e-books */}
        <Route path={"ebooks/list"} element={<ListEbooks />} />
        <Route path={"ebooks/add"} element={<AddEbook />} />
        <Route path={"ebooks/edit/:id"} element={<EditEbook />} />
        {/* blog tags */}
        <Route path={'blog/tags/list'} element={<ListBlogTags />} />
        <Route path={'blog/tags/add'} element={<AddBlogTag />} />
        <Route path={'blog/tags/edit/:id'} element={<EditBlogTag />} />

        {/* e-books */}
        <Route path={'ebooks/list'} element={<ListEbooks />} />
        <Route path={'ebooks/add'} element={<AddEbook />} />
        <Route path={'ebooks/edit/:id'} element={<EditEbook />} />

        {/* search tags */}
        <Route path={'tags/list'} element={<ListTags />} />
        <Route path={'tags/add'} element={<AddTag />} />
        <Route path={'tags/edit/:id'} element={<EditTag />} />

        {/* subject tags */}
        <Route path={'subject/tags/list'} element={<ListSubjectTags />} />
        <Route path={'subject/tags/add'} element={<AddSubjectTag />} />
        <Route path={'subject/tags/edit/:id'} element={<EditSubjectTag />} />

      </Route>
      <Route path={'/user'} element={<PrivateUserRoute />} >
        <Route path={'profile'} element={<Profile />} />
      </Route>
      <Route path={"/*"} element={<GuestRoutes />} />
    </Routes>

  );
};

export default RoutesContainer;
