import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";

const ToastContent = () => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  )
}

export default ToastContent

export function notification(props) {
  toast[props.type](props.error, {
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export function Alert(props) {
  return (
    swal({
      title: "Deletar item selecionado?",
      text: "Uma vez deletado, não dará para recuperá-lo!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Item deletado com sucesso!", {
          icon: "success",
        });
        props.Delete(props.id)
        props.setState({ ...props.state, pageNumber: 1, reload: !props.state.reload })
      }
    })
  )
}