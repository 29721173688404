import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import Card from "../../../../components/Card";
import Collapse from "./Collapse";
import holding_lamp from "../../../../assets/static/holding_lamp.jpg";

import ContatoForm from "../../../Contato/ContatoForm";

const ServicosComponent = ({ data }) => {
  console.log(data)
  return (
    <div style={{ background: "rgb(240, 244, 247)", width: '100vw' }}>
      <Container maxWidth="lg">
        <div
          style={{
            minHeight: "100vh",
            // width: '100vw',
            display: "flex",
            padding: "4rem 0",
            flexDirection: "column",
            gap: "2rem",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h1"
            component="div"
            sx={{
              fontSize: { xs: "2.8rem", md: "4.2rem" },
              fontFamily: "var(--Freight)",
              fontWeight: "bold",
              margin: "4rem 0 2rem 0",
              color: "var(--purple)",
              textAlign: "center",
            }}
          >
            Serviços BM-Words
          </Typography>

          {/* <Collapse {...data} /> */}
          <Typography variant="h4" sx={{ color: "var(--purple)" }}>
            {data.question}
          </Typography>

          {/* <Typography variant="h6">{data.answer}</Typography> */}

          {data.answer.map((item, index) =>
            item.renderAsComponent ? (
              item.data
            ) : (
              <Typography
                key={index}
                variant="subtitle1"
                sx={{ fontFamily: "var(--Raleway)", textAlign: "justify" }}
              >
                {item.data}
              </Typography>
            )
          )}

          <Typography variant="h6" sx={{ color: "var(--purple)" }}>
            Veja também:{" "}
            <a
              href="bm-cursos/o-que-sao"
              className="normal-archor purple"
              style={{ display: "inline" }}
            >
              O que é um BM-Curso? - Perguntas frequentes
            </a>
          </Typography>

          {/* <Card
            title={"Nosso diferencial."}
            text={
              "Dois aspectos se destacam como diferenciais: em primeiro lugar, nossa formação, que nos permite aplicar, no mercado, um conhecimento científico em Linguística consolidado; em segundo lugar, nossa proposta, que transforma serviços de idiomas em assessorias personalizadas. Assim, somos especialistas que, além de prestarem consultoria sobre assuntos relativos à linguagem, atuam, principalmente, como assessores de nossos clientes, acompanhando seu desenvolvimento de perto e adequando seu trabalho às necessidades deles."
            }
            bg_image={holding_lamp}
          /> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              gap: "3rem",
              padding: "4rem 0",
            }}
          >
            <Typography
              variant="h3"
              component="div"
              sx={{
                fontFamily: "var(--Freight)",
                fontWeight: "bold",
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              Quer saber mais? Estamos aqui para te ajudar.
            </Typography>

            <ContatoForm />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ServicosComponent;
