import React from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useSelector } from 'react-redux';
import { Alert, Button, CircularProgress, IconButton, Input, Pagination, TextField, ThemeProvider, Typography } from '@mui/material';
import { URL } from '../../variables';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Theme from '../Theme/Theme';
import swal from 'sweetalert';
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';

const Schedules = () => {
  const [state, setState] = React.useState({
    schedules: [],
    listaParticipantes: [],
    pagination: '',
    pageNumber: 1,
    loading: true,
    loadingSearch: false,
    reload: false,
    token: '',
    search: '',
  })
  const [date, setDate] = React.useState(new Date())
  const history = useNavigate();
  state.token = useSelector(state => state.AppReducer.token);

  // ---------------------------------Effect to load schedules based on page and search-----------------------------------------
  React.useEffect(() => {
    fetch(`${URL}api/schedule/list?page=${state.pageNumber}&search=${state.search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        console.log('json', json)
        setState({
          ...state,
          schedules: json.schedules,
          pagination: json.pagination,
          loading: false,
          loadingSearch: false,
        })
      });
  }, [state.pageNumber, state.search, state.reload]);


  function renderAlert(id) {
    return (
      swal({
        title: "Deletar compromisso selecionado?",
        text: "Uma vez deletado, não dará para recuperá-lo!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          swal("Compromisso deletado com sucesso!", {
            icon: "success",
          });
          Delete(id)
          setState({ ...state, pageNumber: 0, reload: !state.reload })
        }
      })
    )
  }

  // ------------------------------------------------------Delete function------------------------------------------------------
  function Delete(id) {
    fetch(`${URL}api/schedule/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    });
  }

  // --------------------------------------Edit function(aqui encaminha o id para o componente de edit, assim buscando as informações no servidor e carregando o AddSchedule com os valores para update)--------------------------------------
  function EditSchedule(id) {
    history('/home/schedules/edit/' + id);
  }

  // --------------------------------Function where you create the "More information" list--------------------------------------
  function SetPartners(id) {
    let filter = []
    // ----------------------------------checking whether the item already belongs to the array---------------------------------
    filter = state.listaParticipantes.filter(item2 => item2.id == id)

    // ----------------------------------formatting of the date field-----------------------------------------------------------
    let schedule = state.schedules.filter(item => item.id == id)[0]
    let schedule_class = schedule.class_schedule[0]
    let formated_date = schedule.date.split('-')
    formated_date = formated_date[2] + '/' + formated_date[1] + '/' + formated_date[0]

    // ----------------------------------if the item does not belong to the array, it is added----------------------------------
    if (filter.length == 0) {
      filter = [
        ...state.listaParticipantes,
        {
          id: schedule.id,
          participantes: schedule.partners_schedule,
          data: formated_date,
          hora: schedule.hour,
          responsavel: schedule.responsible_schedule.name,
          tipo: schedule.type,
          modulo: schedule_class ? schedule_class.subject.name : '',
          aula: schedule_class ? schedule_class.class.name : ''
        }
      ]
    } else {
      // --------------------------------if the item belongs to the array, it is not added--------------------------------------
      filter = state.listaParticipantes.filter(item2 => item2.id != id)
    }
    setState({ ...state, listaParticipantes: filter })
  }

  function pagination() {
    const { total_pages, per_page } = state.pagination;
    return (
      <div className='d-flex justify-content-end'>
        <Pagination color='primary' shape="rounded" count={Math.ceil(total_pages / per_page)} page={state.pageNumber} onChange={(e, page) => setState({ ...state, pageNumber: page })} />
      </div>
    )
  }

  // -----------------------------Function to filter the list according to the selected day-----------------------------------
  function searchEvent(e) {
    setDate(e)
    state.loading = true
    const formatedDate = dayjs(e).format('YYYY-MM-DD')
    fetch(`${URL}api/schedule/search_events/${formatedDate}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then(async (result) => {
        setState({ ...state, schedules: result, loading: false })
      })
  }

  // ---------------------------Function where renders the values of the "More information"-----------------------------------
  function renderInseridos() {
    return (
      <>
        <Typography>MAIS INFORMAÇÕES</Typography>
        {state.listaParticipantes.map(item =>
        (
          <div className='row mx-2 my-4 p-3 bg-light rounded'>
            <label className="col-sm-8 col-form-label" >Responsável:
              <span style={{ fontWeight: 'normal' }}> {item.responsavel}</span>
            </label>
            <div className='col-4 d-flex justify-content-end'>
              <IconButton>
                <CloseIcon onClick={() => {
                  let inseridos = state.listaParticipantes.filter(item2 => item2.id != item.id)
                  setState({ ...state, listaParticipantes: inseridos })
                }} />
              </IconButton>
            </div>
            <label className="col-sm-12 col-form-label" >Data:
              <span style={{ fontWeight: 'normal' }}> {item.data}</span>
            </label>
            <label className="col-sm-12 col-form-label" >Hora:
              <span style={{ fontWeight: 'normal' }}> {item.hora ? item.hora : 'N/A'}</span>
            </label>
            {console.log('item', item.tipo)}
            {(item.tipo == 'Aula' || item.tipo == 'Reposição') && <label className="col-sm-12 col-form-label" >Tipo:
              <span style={{ fontWeight: 'normal' }}> {item.tipo}</span>
            </label>}
            {(item.tipo == 'Aula' || item.tipo == 'Reposição') && <label className="col-sm-12 col-form-label" >Módulo:
              <span style={{ fontWeight: 'normal' }}> {item.modulo}</span>
            </label>}
            {(item.tipo == 'Aula' || item.tipo == 'Reposição') && <label className="col-sm-12 col-form-label" >Aula:
              <span style={{ fontWeight: 'normal' }}> {item.aula}</span>
            </label>}
            <label className="col-sm-12 col-form-label" >Participantes: </label>
            {item.participantes.map(item3 => {
              return (
                <span className='col-2 p-2 mx-2 mt-2' style={{ borderRadius: '5%', backgroundColor: '#f1f1f1' }}>
                  <Typography>{item3.partner_user.name}</Typography></span>
              )
            })}
          </div>
        )
        )}
      </>
    )
  }

  let timer
  function search(e) {
    clearTimeout(timer);
    timer = setTimeout(() => { setState({ ...state, search: e.target.value, loadingSearch: true }) }, 750);
  }

  // ---------------------------------------------------Component return--------------------------------------------------------
  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className='card-body'>
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="d-flex flex-column">
                <h1 className='header-title'>Lista de Compromissos</h1>
                <Input placeholder='Buscar...' endAdornment={state.loadingSearch ? <CircularProgress size={24} /> : <SearchIcon />} onChange={(e) => search(e)}></Input>
              </div>
            </div>

            <div className="col-12 col-lg-7 mt-3">
              <div className="d-flex justify-content-end align-items-end h-100">
                {/* <Typography className='mb-2'>FILTRAR COMPROMISSOS</Typography> */}
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'pt-br'}
                  localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                  <DesktopDatePicker
                    label="Filtrar data"
                    value={date}
                    onChange={(e) => searchEvent(e)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <div>
                  <Button className='ms-2' variant="contained" size='large' endIcon={<CalendarMonthIcon />} onClick={() => history('/home/schedules/add')}>Adicionar</Button>
                </div>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover text-center mt-5">
            <thead className='text-center'>
              <tr>
                <th scope="col">Responsável</th>
                <th scope="col">Tipo</th>
                <th scope="col">Descrição</th>
                <th scope="col">Data</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            {state.schedules && !state.loading && <tbody>
              {state.schedules.map((item) => {
                let formated_date = item.date.split('-')
                let participante = state.listaParticipantes.filter(item2 => item2.id == item.id)[0]
                return (
                  <tr key={item.id}>
                    <td>
                      <div className="row">
                        <div class='col-1 d-flex align-items-start'>
                          {item.new &&
                            <Alert severity='warning' sx={{ maxWidth: 100, maxHeight: 50 }}>New</Alert>
                            // <label className='col-form-label' style={{ fontSize: '0.6rem' }}>New</label>
                          }
                        </div>
                        <div className="col-10">
                          {item.responsible_schedule.name}
                        </div>
                      </div>


                    </td>
                    <td>{item.type}</td>
                    <td>{item.description}</td>
                    <td>{formated_date[2] + '/' + formated_date[1] + '/' + formated_date[0]}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <IconButton size='small'>
                        <EditIcon color='secondary' onClick={() => EditSchedule(item.id)} />
                      </IconButton>

                      {participante ?
                        <IconButton size='small'>
                          <VisibilityOffIcon color='success' onClick={() => { SetPartners(item.id) }}></VisibilityOffIcon>
                        </IconButton>
                        : <IconButton size='small'>
                          <VisibilityIcon color='success' onClick={() => { SetPartners(item.id) }}></VisibilityIcon>
                        </IconButton>}

                      <IconButton size='small'>
                        <DeleteIcon color='error' onClick={() => renderAlert(item.id)} />
                      </IconButton>
                    </td>
                  </tr>
                )
              })}
            </tbody>}
          </table>
          {state.loading && <div className="row"> <div className="col-12 p-5 d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"  ></div></div></div>}
          {state.schedules && pagination()}
          <div className='mt-5'>
            {state.listaParticipantes.length > 0 && renderInseridos()}
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Schedules