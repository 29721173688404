import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress, IconButton, Typography } from '@mui/material';
import swal from 'sweetalert';
import { URL } from '../../variables';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PaginationTable from './PaginationTable';

const Subject = () => {
  const [state, setState] = React.useState({
    name: '',
    description: '',
    img: '',
    classes: [],
    loading_table: false,
    loading: true,
  })
  const token = useSelector(state => state.AppReducer.token)
  const params = useParams()
  const id = params.id
  const history = useNavigate()

  React.useEffect(() => {
    fetch(`${URL}api/subject/get_subject/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'aplication/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(async (response) => {
      const json = await response.json()
      return json
    }).then(async (json) => {
      const sub = json.subject
      console.log('json', sub)
      setState({ ...state, name: sub.name, description: sub.description, img: sub.img, classes: sub.classes, loading: false })
    })
  }, [state.reload])

  async function deleteClass(id) {
    setState({ ...state, reload: !state.reload })
    await fetch(`${URL}api/classroom/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    });
    // setState({...state, })
  }

  async function renderAlert() {
    return (
      swal({
        title: "Deseja deletar a aula selecionada?",
        text: "Uma vez deletada, não dara para recupera-la!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Poof! Your imaginary file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your imaginary file is safe!");
        }
      })
    )
  }


  return (
    <div className="card">
      <div className="card-body">
        {!state.loading ? <div className="row m-3">
          <div className="d-flex align-items-center mb-5">
            <img style={{ width: 75, height: 75, borderRadius: '100%' }} src={state.img ? `${URL}storage/${state.img}` : `${URL}storage/fotos/user_not_found.png`} alt='subject'></img>
            <h1 className='title ms-2'>{state.name}</h1>
          </div>
          <div className="col-12 mb-3">
            <div className="d-flex align-items-center">
              <Typography variant={'h6'}>DESCRIÇÃO: </Typography>
              <Typography className='ms-2'>{state.description}</Typography>
            </div>
          </div>

          <div className="col-12">
            <div className="d-flex">
              <Typography variant={'h6'}>AULAS: </Typography>
              <IconButton onClick={() => history(`/home/classes/add/${id}`)} size='small'>
                <AddBoxIcon color="success" size='large' />
              </IconButton>
            </div>
            {state.classes.length > 0 ?
              <div>{<PaginationTable classes={state.classes} delete_function={(e) => deleteClass(e)} />}</div>
              : <div className="bg-light rounded p-3">Sem Registros</div>}
          </div>
        </div> : <div className="d-flex justify-content-center p-5 m-5"><CircularProgress /></div>}
      </div>
      {state.show_msg && renderAlert()}
    </div>
  )
}

export default Subject