import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import OwlCarousel from "react-owl-carousel";
import { get } from "../../../../utilities/requests";
import { STORAGE_URL, URL } from "../../../../variables";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const responsive = {
  0:{
    items: 1
  },
  480:{
    items: 3
  },
  769:{
    items: 6
  }
}

const RenderTags = () => {
  // const [tags, setTags] = useState([]);

  // const { isLoading } = useQuery(
  //   "tags",
  //   () => get(`${URL}api/blog/tags/list`),
  //   {
  //     onSuccess: (data) => {
  //       setTags(data?.tags?.data);
  //     },
  //   }
  // );

  const [tags, setTags] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch(`${URL}api/blog/tags/list`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        // 'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setTags(json.tags || []);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {tags?.length > 0 && (
        <OwlCarousel
          className="owl-theme"
          loop
          dots={false}
          nav={false}
          items={5}
          margin={10}
          autoplay
          stagePadding={40}
          autoplayTimeout={8000}
          style={{
            overflow: "hidden",
            borderRadius: "0.6rem",
          }}
          responsive={responsive}
        >
          {tags?.map((tag, index) => (
            <Box
              key={index}
              s
              className="item"
              sx={{
                height: "5rem",
                backgroundColor:
                  tag.image === null ? "rgb(230, 230, 230)" : "black",
                boxShadow: "inset 0 0 4rem rgb(0 0 0 / 20%)",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                overflow: "hidden",
                display: "flex",
                "&:hover .tag-image": {
                  opacity: 0.9,
                  transform: "scale(1.1)",
                },
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "white",
                  zIndex: 10,
                  backdropFilter: "blur(1rem)",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  padding: "0 0.4rem 0.2rem 0.4rem",
                  borderRadius: "0.5rem",
                  display: "flex",
                }}
              >
                <div style={{ color: "rgb(210, 210, 210)" }}>#</div>
                {tag.label}
              </span>
              {tag.image !== null && (
                <img
                  className="tag-image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    position: "absolute",
                    transition: "all 0.3s ease",
                  }}
                  src={STORAGE_URL + tag.image}
                  alt="Imagem da tag"
                />
              )}
            </Box>
          ))}
        </OwlCarousel>
      )}
      {tags?.length === 0 && (
        <Typography variant="h6" sx={{ color: "rgb(70, 70, 70)" }}>
          Nenhuma tag encontrada
        </Typography>
      )}
    </>
  );
};

export default RenderTags;
