import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "../../utilities/helpers";

import "./index.modules.css";

// import orb_logo from "../../assets/orb_logo.png";

import LinkBtn from "../LinkBtn";

// mui icons
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import BusinessIcon from "@mui/icons-material/Business";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from '@mui/icons-material/Dashboard';

import LogosObj from "../../utilities/logos";
// import { HorizontalDropdown, VerticalDropdown } from "../Dropdown";
// import { Category, Item } from "../Dropdown/DropdownUtils";
import Servicos from "./Servicos";
import { Box, Menu, MenuItem } from "@mui/material";
import MobileItems from "./MobileItems";
import { STORAGE_URL } from "../../variables";
import { useDispatch } from "react-redux";
import { logout } from "../actions/AppActions";
import { useNavigate } from "react-router-dom";

const navbar_links = [
  { text: "Sobre nós", href: "/sobre-nos" },
  { text: "Serviços", href: "/servicos" },
  { text: "Blog", href: "/blog/navegar" },
  { text: "Contato", href: "/contato" },
  { text: "Trabalhe conosco", href: "/trabalhe-conosco" },
  { text: "BM eBook Store", href: "/bm-ebook-store" },
];

const Navbar = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const logo = LogosObj.main_without_circle.purple;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [navbarOnTop, setNavbarOnTop] = useState(true);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [anchorUserMenu, setAnchorUserMenu] = React.useState(null);
  const isUserMenuOpen = Boolean(anchorUserMenu);
  const handleOpenUserMenu = (event) => {
    setAnchorUserMenu(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorUserMenu(null);
  };

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
      currentScrollPos < 10
    );

    setNavbarOnTop(currentScrollPos < 40);

    setPrevScrollPos(currentScrollPos);
  }, 100);

  const logout_ = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    dispatch(logout());
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const navbarStyles = {
    position: "fixed",
    height: "max-content",
    transition: "top 0.6s",
    width: "100%",
  };

  return (
    <div style={{ ...navbarStyles, top: visible ? "0" : "-10rem", zIndex: 10 }}>
      <nav className="main-navbar">
        <div className={"nav-content " + (navbarOnTop ? "navbar-on-top" : "")}>
          <div
            style={{
              maxWidth: "1536px",
              margin: "0 auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.6rem",
              paddingLeft: "1.2rem",
              marginBottom: "0",
            }}
          >
            <MobileItems
              {...{ navbar_links, showMobileMenu, setShowMobileMenu }}
            />

            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                },
                width: {
                  xs: "max-content",
                  sm: "7rem",
                },
                alignItems: "center",
                gap: "0.8rem",
              }}
            >
              <MenuRoundedIcon
                sx={{
                  color: "var(--purple)",
                }}
                onClick={() => setShowMobileMenu(true)}
              />

              <Box
                sx={{
                  display: {
                    xs: "flex",
                    sm: "none",
                    md: "none",
                  },
                }}
              >
                <a href="/">
                  <Box
                    component={"img"}
                    sx={{
                      width: "10rem",
                    }}
                    src={logo}
                    alt="BM Words logotipo"
                  />
                </a>
              </Box>
            </Box>

            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                },
              }}
            >
              <a href="/">
                <Box
                  component={"img"}
                  sx={{
                    width: "10rem",
                  }}
                  src={logo}
                  alt="BM Words logotipo"
                />
              </a>
            </Box>

            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                },
                gap: "1.2rem",
              }}
            >
              {navbar_links.map((item, index) => {
                if (item.href === "/servicos") {
                  return <Servicos key={index} {...{ item, index }} />;
                } else {
                  return (
                    <LinkBtn
                      key={index}
                      href_content={item.href}
                      archor_content={item.text}
                      archor_type="purple"
                    />
                  );
                }
              })}
            </Box>

            <Box
              sx={{
                width: {
                  xs: "max-content",
                  sm: "max-content",
                  md: "10rem",
                },
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {token !== null ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                      padding: "0.4rem",
                      paddingRight: "0.8rem",
                      borderRadius: "2rem",
                      background: "#f0ecfc",
                      cursor: "pointer",
                    }}
                    aria-controls={isUserMenuOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={isUserMenuOpen ? "true" : undefined}
                    onClick={handleOpenUserMenu}
                  >
                    <div>
                      {user.file_path !== null && user.file_path ? (
                        <img
                          src={STORAGE_URL + user.file_path}
                          alt="Usuário"
                          style={{
                            width: "1.8rem",
                            height: "1.8rem",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <AccountCircleIcon
                          sx={{
                            width: "1.8rem",
                            height: "1.8rem",
                          }}
                        />
                      )}
                    </div>

                    <span
                      style={{
                        color: "varr(--purple)",
                      }}
                    >
                      {user.name.split(" ")[0]}
                    </span>
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorUserMenu}
                    open={isUserMenuOpen}
                    onClose={handleCloseUserMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        history('/painel-aluno')
                      }}
                    >
                      <DashboardIcon sx={{ marginRight: "0.4rem" }} />
                      {
                        user.type_user[0].student ? 'Painel do aluno' : 'Dashboard'
                      }
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        history('/user')
                      }}
                    >
                      <AccountCircleIcon sx={{ marginRight: "0.4rem" }} />
                      Meu perfil
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseUserMenu();
                        logout_();
                      }}
                    >
                      <LogoutIcon sx={{ marginRight: "0.4rem" }} />
                      Sair
                    </MenuItem>
                  </Menu>
                </div>
              ) : (
                <LinkBtn
                  href_content="/login"
                  archor_content="Login"
                  archor_type="special"
                  archor_icon={<PersonRoundedIcon />}
                />
              )}
            </Box>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
