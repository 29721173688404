import { TextField, Typography } from "@mui/material";
import LogosObj from "../../utilities/logos";
import React from 'react';

const Dashboard = () => {
  const logo = LogosObj.main.purple;
  // const [value, setValue] = React.useState('');
  // const [isPending, startTransition] = React.useTransition()
  // const [array, setArray] = React.useState([])

  // function handleChange(e) {
  //   setValue(e.target.value)
  //   startTransition(() => {
  //     let array = new Array(8000).fill(e.target.value)
  //     setArray(array)
  //     console.log('array', array)
  //   })
  // }
  return (
    <>
      <div
        style={{
          width: "max-content",
          height: "40rem",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: '2rem',
          color: 'var(--purple)',
          fontFamily: 'var(--Raleway)',
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 'bold', }}>Seja bem-vindo(a) ao Dashboard!</Typography>
        <img
          style={{ display: "block", width: "18rem" }}
          src={logo}
          alt="BM Words logotipo"
        />

      </div>
      {/* <div className="row">
        <div className="col-12">
          <TextField fullWidth label='teste' value={value} onChange={(e) => handleChange(e)} />
        </div>
        <div className="col-6 m-auto">
          {value ? array.map(item => (
            <div className="m-auto my-2 rounded" style={{ width: 200, height: 50, backgroundColor: 'tomato' }}>{item}</div>
          )) :
            <div className="m-auto my-2 rounded" style={{ width: 200, height: 50, backgroundColor: 'tomato' }}>Loading...</div>}
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;
