import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField, ThemeProvider, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import React from 'react'
import Input from '../User/Input';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { URL } from '../../variables';
import { useNavigate } from 'react-router-dom';
import { FileDrop } from 'react-file-drop';
import '../../components/FileDrop/styles.css';
import Theme from '../Theme/Theme';
import { MdClose } from 'react-icons/md';
import { GET } from '../../utilities/requests';

const AddSubject = ({ edit }) => {
  const { tags = '' } = edit || {}
  const history = useNavigate();
  const token = useSelector(state => state.AppReducer.token);

  const [loading, setLoading] = React.useState(tags ? false : true)
  const [subjectTags, setSubjectTags] = React.useState(tags ?? [])
  const [subjectTagRelation, setSubjectTagRelation] = React.useState([])
  const [state, setState] = React.useState({
    img: { file: '', url: '' },
    loading_save: false,
  })
  const [form, setForm] = React.useState({
    name: { label: 'Nome*', value: '', error: false },
    description: { label: 'Descrição*', value: '', error: false },
  })

  React.useEffect(() => {
    if (!edit) {
      getTags()
      return
    }

    setSubjectTagRelation(edit.subject.tags.map(item => item.id))
    setForm({
      ...form, name: { ...form.name, value: edit.subject.name }, description: { ...form.description, value: edit.subject.description }
    })
    setState({ ...state, img: edit.subject.img })
  }, [])

  const getTags = async () => {
    const response = await GET('subject/get_tags')
    setSubjectTags(response.tags ?? [])
    setLoading(false)
  }

  function addSubject(type) {
    setState({ ...state, loading_save: true })

    const keys = Object.keys(form)
    let form2 = { ...form }
    keys.forEach(item => {
      if (form2[item].value == '') form2[item].error = true
    })
    setForm(form2)

    if (!form2.name.error && !form2.description.error) {
      const formData = new FormData();
      formData.append('name', form.name.value);
      formData.append('description', form.description.value);
      formData.append('img', state.img.file);
      if (edit) formData.append('id', edit.subject.id)

      subjectTagRelation.forEach(item => {
        formData.append('subject_tags[]', item)
      })

      fetch(`${URL}api/subject/${type}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      }).then(async (response) => {
        const resp = await response.json()
        console.log('state2', state)
        if (resp.status) {
          renderInput()
          history('/home/subjects');
        }
      }).finally(() => {
        setState({ ...state, loading_save: false })
      });
    }
  }

  function renderInput() {
    const keys = Object.keys(form);
    return (
      keys.map(item => {
        return <Input item={item} form={form} setForm={(e) => setForm(e)} />
      })
    )
  }

  function changeFile(file) {
    let fr = new FileReader()
    fr.onload = (e) => {
      setState({ ...state, img: { file: file, url: e.target.result } })
    }
    fr.readAsDataURL(file)
  }

  const handleAddTag = ({ target }) => {
    const { value } = target

    if (subjectTagRelation.some(item => item === value)) {
      return
    }

    setSubjectTagRelation([...subjectTagRelation, value])
  }

  const handleDeleteTag = (tagId) => {
    setSubjectTagRelation([...subjectTagRelation.filter(item => item !== tagId)])
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          {!loading ?
            <div className="row">
              <div className="col-12 col-md-8 m-auto">
                <h1 className='title ms-2 my-5'>CRIAR NOVO MÓDULO</h1>
                {/* <div className="d-flex align-items-center mb-5">
              <img style={{ width: 75, height: 75, borderRadius: '100%' }} src={state.img ? state.img.url : `${URL}storage/fotos/user_not_found.png`}></img>
            </div> */}
                {renderInput()}

                <FormControl fullWidth className='my-3'>
                  <InputLabel id="subjectTag">Tag</InputLabel>
                  <Select
                    labelId="subjectTag"
                    id="demo-simple-select"
                    label="Tag"
                    onChange={handleAddTag}
                  >
                    {subjectTags.filter(item => !subjectTagRelation.includes(item.id)).map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div className="d-flex mb-3">
                  {subjectTagRelation.map(tagId => (
                    <div key={tagId + 'o'} className='d-flex mx-2'>
                      <p>{subjectTags.find(item => item.id === tagId).name}</p>
                      <div className="px-3 pointer" onClick={() => handleDeleteTag(tagId)}>
                        <MdClose color='#FF0000' />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="mb-5" style={{ height: 150 }}>
                  <FileDrop onDrop={(files, event) => changeFile(files[0])}>
                    <Button style={{ color: '#666666', width: '100%', height: '100%', padding: 0 }} component="label">
                      {!state.img.url && <Typography variant='p' style={{ color: '#666666' }}>Arraste a capa da aula ou escolha um arquivo</Typography>}
                      {state.img.url && <img style={{ width: '100%', height: '100%', borderRadius: 5 }} alt='banner' src={state.img.url ? state.img.url : `${URL}storage/fotos/no_banner.png`}></img>}
                      <input hidden onChange={(e) => changeFile(e.target.files[0])} accept="image/*" multiple type="file" />
                    </Button>
                  </FileDrop>
                </div>
                <div className="d-flex mt-5">
                  <div className="align-self-center">
                    <Button size='large' variant="outlined" onClick={() => history('/home/subjects')} startIcon={<ReplyAllIcon />}> Voltar</Button>
                  </div>
                  <div className="align-self-center ms-auto">
                    <LoadingButton size='large' loading={state.loading_save} onClick={() => edit ? addSubject('update') : addSubject('add')} loadingPosition="end" endIcon={<SaveIcon />} variant="outlined">Salvar</LoadingButton>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="d-flex justify-content-center p-5">
              <CircularProgress />
            </div>}
        </div>
      </div >
    </ThemeProvider>
  )
}

export default AddSubject