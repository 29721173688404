import { Typography } from "@mui/material";

import StarPurple500OutlinedIcon from "@mui/icons-material/StarPurple500Outlined";

const TestimonialCard = ({
  text,
  name,
  occupation,
  company,
  avatar,
}) => {
  return (
    <div
      style={{
        flexGrow: 1,
        maxWidth: "26rem",
        gap: "1.2rem",
        display: "flex",
        flexDirection: "column",
        margin: "0.4rem 0.4rem",
      }}
    >
      <div style={{ color: "#e9c46a" }}>
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
        <StarPurple500OutlinedIcon />
      </div>
      
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <div>
          <img
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "50%",
              backgroundPosition: "center",
              backgroundSize: "cover",
              boxShadow: "0 0 0 0.1rem rgba(105, 59, 159, 1)",
              objectFit: "cover",
            }}
            src={avatar}
            alt=""
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              fontSize: "1.4rem",
              fontFamily: "var(--Freight)",
              fontWeight: "bold",
            }}
          >
            {name}
          </span>
          <span style={{ fontSize: "1rem", fontFamily: "var(--Raleway)" }}>
            {occupation}
          </span>
        </div>
      </div>

      <Typography
        variant="body1"
        component="div"
        sx={{
          fontFamily: "var(--Raleway)",
          color: "black",
          fontSize: "1.05rem",
          // backgroundColor: "white",
          // height: "6rem",
        }}
      >
        "{text}"
      </Typography>
    </div>
  );
};

export default TestimonialCard;
