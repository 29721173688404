import React from 'react';
import AddSchedule from './AddSchedule';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { URL } from './../../variables';
import { CircularProgress } from '@mui/material';

const EditSchedule = () => {
  const [state, setState] = React.useState({
    data: {},
    loading: true,
  })
  const token = useSelector(state => state.AppReducer.token);
  const params = useParams();
  const id = params.id;

  // --------------------------------------Effect to load user data according to your id----------------------------------------
  React.useEffect(() => {
    fetch(`${URL}api/schedule/edit/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (result) => {
      const json = await result.json();
      return json;
    }).then((json) => {
      console.log('json', json)
      setState({
        ...state,
        data: json,
        loading: false,
      })
    })
  }, [])

  // ---------------------------------------------------Component return------------------------------------------------------

  return (
    <>
      {state.loading ?
        <div className="card p-5">
          <div className="card-body m-5">
            <div className='d-flex justify-content-center'>
              <CircularProgress />
            </div>
          </div>
        </div> : <AddSchedule edit={state.data} />}
    </>
  )
}

export default EditSchedule