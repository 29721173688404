import { Box, Typography } from "@mui/material";
import Section100vh from "../../components/Section100vh";
import ContatoForm from "../Contato/ContatoForm";
import LandingPage from "./LandingPage";
import SobreNosCards from "./SobreNosCards";
import Depoimentos from "./Depoimentos";
import Clientes from "./Clientes";

const Home = () => {
  return (
    <>
      <LandingPage />

      <SobreNosCards />

      <Depoimentos />

      <Clientes />

      {/* contato */}
      <Section100vh bg_color="#f0f4f7">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            gap: "3rem",
            padding: "4rem 0",
            width: {
              xs: "100%",
              md: "80%",
              lg: "70%",
            }
          }}
        >
          <Typography
            variant="h3"
            component="div"
            sx={{
              fontFamily: "var(--Freight)",
              fontWeight: "bold",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            #BeMoreanywhere
          </Typography>

          <ContatoForm />
        </Box>
      </Section100vh>
      {/* fim contatos */}
    </>
  );
};

export default Home;
