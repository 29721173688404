import React from 'react';
import { Button, TextField, Typography } from "@mui/material";
import { FileDrop } from 'react-file-drop';
import { VALIDATE } from '../../variables';

const Input = ({ state, setState, item }) => {
  // ---------------------Verificação de cada tipo de input de acordo com o item pasado no componente pai, exemplo de uso: 
  //em um state para os valores de formulário é passado um estado de objeto com vários outros objetos dentro, ex:
  // name: { label: 'Nome*', value: "", error: false, col: 'col-sm-6', type: 'text' },
  //onde ao passar o componente de input, são esperadas as seguintes props:
  //  item: nesse caso 'name',
  //  state: o estado em que se encontra(form no compontent pai)
  //  setState: função para atualizar o estado, exemplo de como passar: setState={(e) => setForm(e)}
  //então será buscado o state[item] que seria igual a state.name e assim passando seus respectivos valores como label,value, error, entre outros

  //Explicação de uso - base de exemplo(name: { label: 'Nome*', value: "", error: false, col: 'col-sm-6', type: 'text' })
  //name: key do objeto, recomendavel usar igual está no backend, caso usar a função para montar o corpo da requisição(MOUNT_JSON_BODY ou MOUNT_FORM_DATA_BODY a key vai ser o nome do objeto tambem)
  //label: label do input, adicionar * caso venha ser requerido, assim as válidações são aplicadas no objeto
  //value: valor do objeto, o que for escrito ficará aqui
  //error: nas validações caso definido como true, altera o estado de erro do input
  //col: esse componente foi pensado para ser montado em bootstrap, caso tenha uma div com a classe 'row' antes de gerar os campos cada col será baseada na do objeto
  //type: de acordo com o type será gerado um input com propriedades e funções diferentres, disponíveis no momento: text, number, file, cep, multiline, phone e select(em manutenção)

  //Exemplo de uso:
  // const [form, setForm] = React.useState({
  //   name: { label: 'Nome*', value: "", error: false, col: 'col-sm-6', type: 'text' },
  //   description: { label: 'Descrição*', value: "", error: false, col: 'col-sm-6', type: 'text', },
  //   author: { label: 'Autor*', value: "", error: false, col: 'col-sm-4', type: 'text' },
  //   days: { label: 'Dias disponíveis*', value: "", error: false, col: 'col-sm-4', type: 'number', },
  //   document: { label: 'PDF*', value: "", error: false, col: 'col-sm-4', type: 'file', url: '' },
  // })

  //Cada key do estado é separada e a função de map renderiza cada uma de forma individual
  // function renderInputs() {
  //   let keys = { ...form }
  //   keys = Object.keys(keys)
  //   return keys.map(item => (
  //     <div key={form[item].label} className={`${form[item].col} col-12 my-2`}>
  //       <Input state={form} setState={(e) => setForm(e)} item={item} />
  //     </div>)
  //   )
  // }
  //-----------------------

  // function changeImgFile(file) {
  //   let fr = new FileReader()
  //   fr.onload = (e) => {
  //     setState({ ...state, [item]: { ...state[item], value: file, url: e.target.result } })
  //   }
  //   fr.readAsDataURL(file)
  // }

  function changeFile(file) {
    let url = window.URL.createObjectURL(file);
    setState({ ...state, [item]: { ...state[item], value: file, url, error: false } })
  }

  function pesquisaCep(e) {
    let state2 = { ...state }
    state2.cep.value = e.target.value
    state2.cep.error = false
    state2.state.error = false
    state2.city.error = false
    setState(state2)

    if (Array.from(e.target.value).length >= 8) {
      validaCep();
    } else if (e.target.value === '') {
      clearCEP()
    }
  }

  async function validaCep() {
    const endereco = await fetch(`https://viacep.com.br/ws/${state.cep.value}/json/`)
      .then(response => {
        return response;
      })
      .catch((error) => {
        clearCEP();
        setState({ ...state, [item]: { ...state[item], value: '', error: true, msg: 'CEP inválido' } })
      })
    const data = await endereco.json();
    if (data.hasOwnProperty('erro')) {
      clearCEP();
      setState({ ...state, [item]: { ...state[item], value: '', error: true, msg: 'CEP não encontrado!' } })
    } else {
      setState({
        ...state,
        city: { ...state.city, value: data.localidade, disabled: true },
        state: { ...state.state, value: data.uf, disabled: true },
        street: { ...state.street, value: data.logradouro, disabled: true },
      })
    }
  }

  function clearCEP() {
    let state2 = { ...state };
    let form = Object.keys(state2)
    const filter = form.filter(item => item === 'cep' || item === 'state' || item === 'city')
    filter.forEach(item => {
      state2[item].value = '';
      state2[item].disabled = false;
    })
    setState(state2);
  }

  const phoneMaskBrazil = (e) => {
    var element = e.target;
    var isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    var inputValue = element.value;
    if (inputValue.length < 16) {
      inputValue = inputValue.replace(/\D/g, "");
      inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
      inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

      setState({ ...state, [item]: { ...state[item], value: inputValue, error: false } });
    }
  };

  function handleChange({ value }) {
    setState({ ...state, [item]: { ...state[item], value: value, error: false } })
  }

  function render() {
    switch (state[item].type) {
      case 'text':
        return (
          <TextField fullWidth label={state[item].label} error={state[item].error} value={state[item].value}
            onChange={({ target }) => handleChange(target)}
            onBlur={(e) => VALIDATE({ form: state, setForm: (e) => setState(e) })} />
        )

      case 'select':
        return (
          <form className="form-floating">
            <select className={`form-control ${state[item].error && 'is-invalid'}`} value={state[item].value} onChange={(e) => setState({ ...state, [item]: { ...state[item], value: e.target.value, error: false } })} id="floatingSelect" aria-label="Floating label select example">
              {state[item].fillOption && state[item].fillOption.map(item => (<option key={item} value={item}>{item}</option>))}
            </select>
            <label htmlFor="floatingSelect">{state[item].label}</label>
          </form>
        )

      case 'number':
        return (
          <TextField fullWidth label={state[item].label} error={state[item].error} value={state[item].value}
            onChange={({ target }) => handleChange(target)} type='number'
            onBlur={() => VALIDATE({ form: state, setForm: (e) => setState(e) })} />
        )

      case 'cep':
        return (
          <TextField fullWidth label={state[item].label} error={state[item].error} value={state[item].value}
            onChange={(e) => pesquisaCep(e)} onBlur={() => VALIDATE({ form: state, setForm: (e) => setState(e) })} />
        )

      case 'file':
        return (
          <div style={{ height: 56 }}>
            <FileDrop
              // onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
              // onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
              // onDragOver={(event) => console.log('onDragOver', event)}
              // onDragLeave={(event) => console.log('onDragLeave', event)}
              onDrop={(files, event) => changeFile(files[0])}
            >
              <Button style={{ color: '#666666', width: '100%', height: '100%' }} component="label">
                <Typography variant='p' style={{ color: '#666666' }}>
                  {state[item].value ? state[item].value.name : 'Arraste ou clique e escolha um arquivo'}
                </Typography>
                <input hidden onChange={(e) => changeFile(e.target.files[0])} accept='application/pdf' multiple type="file" />
              </Button>
            </FileDrop>
          </div>
        )

      case 'multiline':
        return (
          <TextField fullWidth label={state[item].label} error={state[item].error} value={state[item].value}
            onChange={({ target }) => handleChange(target)} multiline rows={5}
            onBlur={() => VALIDATE({ form: state, setForm: (e) => setState(e) })} />
        )

      case 'phone':
        return (
          <TextField fullWidth label={state[item].label} error={state[item].error} value={state[item].value}
            onChange={(e) => phoneMaskBrazil(e)}
            onBlur={() => VALIDATE({ form: state, setForm: (e) => setState(e) })} />
        )

      default:
        break;
    }
  }
  return (
    render()
  )
}

export default Input