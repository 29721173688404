import React from 'react'
import { useSelector } from 'react-redux'
import Input from '../Form/Input'
import { FILL_FORM, MOUNT_FORM_DATA, POST_PATCH_FETCH_FORMDATA, URL, VALIDATE } from '../../variables'
import SaveButton from '../Form/SaveButton'
import Container from '../Form/Container'
import { useNavigate } from 'react-router-dom'
import { notification } from '../Toasts/Toasts'

const AddEbook = ({ edit }) => {
  //State with object responsible for generating each input field in the functions below
  const [form, setForm] = React.useState({
    name: { label: 'Nome*', value: "", error: false, col: 'col-sm-6', type: 'text' },
    description: { label: 'Descrição*', value: "", error: false, col: 'col-sm-6', type: 'text', },
    author: { label: 'Autor*', value: "", error: false, col: 'col-sm-4', type: 'text' },
    days: { label: 'Dias disponíveis*', value: "", error: false, col: 'col-sm-4', type: 'number', },
    document: { label: 'PDF*', value: "", error: false, col: 'col-sm-4', type: 'file', url: '' },
  })
  const [loading, setLoading] = React.useState(false)
  const token = useSelector(state => state.AppReducer.token)
  const history = useNavigate()

  //Effect to load editing data if it have editing props
  React.useEffect(() => {
    if (edit) {
      //FILL_FORM is responsible to fill the form object, sincronizing the edit data with form structure
      let fillForm = FILL_FORM({ edit, form })
      setForm(fillForm)
    }
  }, [edit])

  //Function to render inputs, every key from form state is separated and pass in map function, generating a div with col property and the Input according to each item
  function renderInputs() {
    let keys = { ...form }
    keys = Object.keys(keys)
    return keys.map(item => (
      <div key={form[item].label} className={`${form[item].col} col-12 my-2`}>
        <Input state={form} setState={(e) => setForm(e)} item={item} />
      </div>)
    )
  }

  //Function to consume save API, first the data is validated in VALIDATE, if the return be true is generated a notification, else the body of request is mounted in MOUNT_FORM_DATA and used in POST function
  async function save() {
    setLoading(false)
    let error = VALIDATE({ form, setForm: (e) => setForm(e) })
    console.log('error', error)
    if (error) {
      window.scrollTo(500, 0)
      notification({ error: 'Preencha os campos em branco', type: 'warning' })
    } else {
      let body = MOUNT_FORM_DATA({ form, id: edit ? edit.id : false })
      let resp = await POST_PATCH_FETCH_FORMDATA({ url: `${URL}api/ebook/${edit ? 'update' : 'add'}`, body, token, method: 'POST' })
      if (resp.status) {
        history('/home/ebooks/list')
      } else {
        notification({ error: 'Ocorreu um erro ao salvar E-book', type: 'error' })
      }
    }
    setLoading(false)
  }

  //Return component
  return (
    // Component that contains the basic structure of screens
    <Container title='Cadastrar Ebook' subTitle='Crie e cadastre ebooks, disponibilizando-os no sistema!'>
      <div className="row">
        {/* Function to render inputs */}
        {renderInputs()}
        {(edit || form.document.url) &&
          // PDF viewer
          <iframe style={{ maxWidth: '100%', height: 1200, margin: '10px 0' }} src={form.document.url ? form.document.url : `${URL}storage/${form.document.value}`} title='file'></iframe>
        }
      </div>
      <SaveButton path={'/home/ebooks/list'} save={(e) => save(e)} loading={loading} />
    </Container>
  )
}

export default AddEbook