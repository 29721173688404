import { useEffect, useState } from "react";
import LinkBtn from "../LinkBtn";

import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { Box } from "@mui/material";

//
// VerticalDropdown and HorizontalDropdown are different components that share the same logic.
// 
// HOW TO USE:
// 
// VerticalDropdown {
//   title: string (required, the title of the dropdown)),
//   className: string (to load a custom css style for the dropdown's title ),
//   children: array of Category components (required, the categories of the dropdown),
// }
//
// HorizontalDropdown {
//   title: string (required, the title of the dropdown)),
//   dropdownAsItem: boolean (optional, if true, the dropdown will be rendered as Item component),
//   className: string (to load a custom css style for the dropdown's title ),
//   children: array of Item components (required, the items of the dropdown),
// }
//
// please check the Category and Item components -> ./DropdownUtils
// 

export const VerticalDropdown = (props) => {
  const { title, children, className = null } = props;
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        // cursor: "pointer",
        fontFamily: "var(--Raleway)",
      }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <span className={className}>
        {title}{" "}
        <KeyboardArrowUpRoundedIcon
          sx={{
            transition: `400ms ${show ? "0ms" : "250ms"} ease`,
            ...(show && { transform: "rotate(180deg)" }),
          }}
        />
      </span>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f6f6f6",
          position: "absolute",
          marginTop: "2rem",
          marginLeft: "-0.2rem",
          gap: "0.12rem",
          width: "max-content",
          boxShadow: "0 0 0 0.1rem #e4dcec",
          borderRadius: "0.6rem",
          zIndex: 10,
          transition: `transform 250ms ${
            show ? "0ms" : "250ms"
          } ease, opacity 250ms ${
            show ? "0ms" : "250ms"
          } ease, visibility 0ms ${show ? "0ms" : "500ms"}`,
          ...(show
            ? {
                transform: "scale(1)",
                opacity: 1,
                visibility: "visible",
              }
            : {
                transform: "scale(0.92)",
                opacity: 0,
                visibility: "hidden",
              }),
          "& a:first-child": {
            borderRadius: "0.4rem 0.4rem 0 0",
          },
          "& a:last-child": {
            borderRadius: "0 0 0.4rem 0.4rem",
          },
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export const HorizontalDropdown = (props) => {
  const { title, children, className = null, dropdownAsItem = 0 } = props;
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        cursor: "pointer",
        fontFamily: "var(--Raleway)",
      }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {dropdownAsItem ? (
        <Box
          sx={{
            color: "var(--purple)",
            display: "flex",
            justifyContent: "space-between",
            padding: "0.4rem 0.8rem",
            "&:hover": {
              background: "#ededed",
            },
          }}
        >
          <span>{title}</span>
          <KeyboardArrowUpRoundedIcon
            sx={{
              transition: `400ms ${show ? "0ms" : "250ms"} ease`,
              ...(show
                ? { transform: "rotate(90deg)" }
                : { transform: "rotate(270deg)" }),
            }}
          />
        </Box>
      ) : (
        <span className={className}>
          {title}{" "}
          <KeyboardArrowUpRoundedIcon
            sx={{
              transition: `400ms ${show ? "0ms" : "250ms"} ease`,
              ...(show
                ? { transform: "rotate(90deg)" }
                : { transform: "rotate(270deg)" }),
            }}
          />
        </span>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f6f6f6",
          position: "absolute",
          left: "101%",
          gap: "0.12rem",
          width: "max-content",
          boxShadow: "0 0 0 0.1rem #e4dcec",
          borderRadius: "0.6rem",
          zIndex: 10,
          transition: `transform 250ms ${
            show ? "0ms" : "250ms"
          } ease, opacity 250ms ${
            show ? "0ms" : "250ms"
          } ease, visibility 0ms ${show ? "0ms" : "500ms"}`,
          ...(show
            ? {
                transform: "scale(1)",
                opacity: 1,
                visibility: "visible",
              }
            : {
                transform: "scale(0.92)",
                opacity: 0,
                visibility: "hidden",
              }),
          "& a:first-child": {
            borderRadius: "0.4rem 0.4rem 0 0",
          },
          "& a:last-child": {
            borderRadius: "0 0 0.4rem 0.4rem",
          },
        }}
      >
        {children}
      </Box>
    </div>
  );
};

// export const HorizontalDropdown = (props) => {
//   const { title, children, className = null, dropdownAsItem = false } = props;
//   const [show, setShow] = useState(false);

//   return (
//     <div
//       style={{
//         display: "flex",
//         position: "relative",
//         flexDirection: "column",
//         cursor: "pointer",
//       }}
//       onMouseEnter={() => setShow(true)}
//       onMouseLeave={() => setShow(false)}
//     >
//       {dropdownAsItem ? (
//         <Box
//           sx={{
//             background: "white",
//             padding: "0.4rem 1rem",
//             "&:hover": {
//               background: "#f2f2f2",
//             },
//           }}
//         >
//           <span>{title}</span>
//           <KeyboardArrowUpRoundedIcon
//             sx={{
//               transition: `400ms ${show ? "0ms" : "250ms"} ease`,
//               ...(show
//                 ? { transform: "rotate(90deg)" }
//                 : { transform: "rotate(270deg)" }),
//             }}
//           />
//         </Box>
//       ) : (
//         <span className={className}>
//           {title}{" "}
//           <KeyboardArrowUpRoundedIcon
//             sx={{
//               transition: `400ms ${show ? "0ms" : "250ms"} ease`,
//               ...(show
//                 ? { transform: "rotate(90deg)" }
//                 : { transform: "rotate(270deg)" }),
//             }}
//           />
//         </span>
//       )}

//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           backgroundColor: "#e4dbee",
//           position: "absolute",
//           // marginTop: "2rem",
//           left: "100%",
//           gap: "0.11rem",
//           padding: "0.2rem",
//           width: "max-content",
//           borderRadius: "0.6rem",
//           zIndex: 10,
//           transition: `transform 250ms ${
//             show ? "0ms" : "250ms"
//           } ease, opacity 250ms ${
//             show ? "0ms" : "250ms"
//           } ease, visibility 0ms ${show ? "0ms" : "500ms"}`,
//           ...(show
//             ? {
//                 transform: "scale(1) translateX(0)",
//                 opacity: 1,
//                 visibility: "visible",
//               }
//             : {
//                 transform: "scale(0.92) translateX(-2rem)",
//                 opacity: 0,
//                 visibility: "hidden",
//               }),

//           "& a:first-of-type": {
//             borderRadius: "0.4rem 0.4rem 0 0",
//           },
//           "& a:last-of-type": {
//             borderRadius: "0 0 0.4rem 0.4rem",
//           },
//         }}
//       >
//         {children}
//       </Box>
//     </div>
//   );
// };
