import { Box } from "@mui/material";
import Servicos from "../Servicos";
import LinkBtn from "../../LinkBtn";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LogosObj from "../../../utilities/logos";
import { useEffect } from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FeedIcon from "@mui/icons-material/Feed";
import CallIcon from "@mui/icons-material/Call";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import HandshakeIcon from '@mui/icons-material/Handshake';
import MenuBookIcon from '@mui/icons-material/MenuBook';

const navbar_links = [
  { text: "Sobre nós", href: "/sobre-nos", icon: <GroupsIcon /> },
  { text: "Serviços", href: "/servicos", icon: <HomeRepairServiceIcon /> },
  { text: "Blog", href: "/blog/navegar", icon: <FeedIcon /> },
  { text: "Contato", href: "/contato", icon: <ContactPhoneIcon /> },
  { text: "Trabalhe conosco", href: "/trabalhe-conosco", icon: <HandshakeIcon /> },
  { text: "BM eBook Store", href: "/bm-ebook-store", icon: <MenuBookIcon /> },
];

const MobileItems = ({ showMobileMenu, setShowMobileMenu }) => {
  const logo = LogosObj.main_without_circle.purple;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        zIndex: "100",
        position: "absolute",
        top: "0",
        right: showMobileMenu ? "0" : "100vw",
        opacity: showMobileMenu ? "1" : "0",
        backgroundColor: "white",
        minHeight: "100vh",
        width: "100vw",
        transition: "600ms ease",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <a href="/">
          <Box
            component={"img"}
            sx={{
              width: "10rem",
            }}
            src={logo}
            alt="BM Words logotipo"
          />
        </a>
        <CloseRoundedIcon
          sx={{
            color: "var(--purple)",
            fontSize: "1.8rem",
          }}
          onClick={() => setShowMobileMenu(false)}
        />
      </div>

      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
          },
          gap: "1.2rem",
          display: "flex",
          flexDirection: "column",
          fontWeight: "bold!important",
          padding: "0.8rem",
          marginTop: "2rem",
        }}
      >
        {navbar_links.map((item, index) => {
          return (
            <LinkBtn
              key={index}
              archor_icon={item.icon}
              href_content={item.href}
              archor_content={item.text}
              archor_type="purple"
              style={{
                fontWeight: "bold",
                gap: "1rem",
                alignItems: "center",
              }}
            />
          );
        })}
      </Box>
    </div>
  );
};

export default MobileItems;
