import React from 'react'
import Input from './Input'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { URL } from '../../variables';

const ReplyWorkWithUs = () => {
  const [form, setForm] = React.useState({
    name: { label: 'Nome', value: '', error: false, type: 'text' },
    email: { label: 'Email', value: '', error: false, type: 'email' },
    message: { label: 'Mensagem', value: '', error: false, type: 'multiline' },
  })
  const params = useParams()
  const id = params.id
  const [state, setState] = React.useState({
    data: {},
    token: '',
    redirect: false,
  })
  state.token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    fetch(`${URL}api/work-with-us/reply/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (response) => {
      const json = await response.json();
      return json;
    }).then(async (json) => {
      console.log(json)
      setForm({ ...form, name: { ...form.name, value: json.work.name }, email: { ...form.email, value: json.work.email } })
    });
  }, [])

  function renderInput() {
    const keys = Object.keys(form)
    console.log(form)
    return (
      keys.map(item => {
        return <Input type={form[item].type} item={item} form={form} setForm={(e) => setForm(e)} />
      })
    )
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          {form.name.value && renderInput()}
        </div>
      </div>
    </div>
  )
}

export default ReplyWorkWithUs