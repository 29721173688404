import React from "react";
import { URL } from "../../variables";
import { connect, useSelector, useDispatch } from "react-redux";
import Logo from "../../assets/es-logo-colorido.png";
import BG from "../../assets/perguntas1.jpg";
import { Box, Button, Grid, Typography } from "@mui/material";
import Input from "../../components/Input/Input";
// import Link from "../Link"
import Navbar from "../../components/Navbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import swal from "sweetalert";

// mui icons
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

import signup_image from "../../assets/static/signup_image.svg";
import bm_world from "../../assets/static/bm_world.png";
import Footer from "../../components/Footer";
import ShowAlert from "../../components/ShowAlert";
import { post } from "../../utilities/requests";
import { login } from "../../components/actions/AppActions";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.ref_password2 = React.createRef();
    this.ref_password1 = React.createRef();
    this.state = {
      loading: false,
      submitted: false,
      msgErro: "",
      name: "",
      email: "",
      temp_password1: "",
      temp_password2: "",
      password: "",
      name: "",
      phone: "",
      redirect: false,
      path: "",
      loading_save: false,
      hide_password: true,

      // password check
      min6chars: false,
      max20chars: true,
      hasNumber: false,
      hasLetter: false,
      hasSpecialChar: false,
      hasSpace: false,
      hasMatch: false,
    };
  }

  phoneMaskBrazil(e) {
    var element = e.target;
    var isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    var inputValue = element.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
    inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

    this.setState({ phone: inputValue });
  }

  // checkPassword() {
  //   if (this.state.temp_password1.length < 6) {
  //     this.setState({
  //       msgErro: "A senha deve ter no mínimo 6 caracteres",
  //       min6chars: false,
  //       password: "",
  //     });

  //     this.ref_password1.current.style.border =
  //       "0.16rem solid rgb(255, 150, 150)";
  //     // return true;
  //   } else {
  //     this.setState({ min6chars: true });
  //   }

  //   if (this.state.temp_password1.length > 20) {
  //     this.setState({
  //       msgErro: "A senha deve ter no máximo 20 caracteres",
  //       max20chars: false,
  //       password: "",
  //     });
  //     this.ref_password1.current.style.border =
  //       "0.16rem solid rgb(255, 150, 150)";
  //     // return true;
  //   } else {
  //     this.setState({ max20chars: true });
  //   }

  //   if (this.state.temp_password1.search(/\d/) == -1) {
  //     this.setState({
  //       msgErro: "A senha deve conter pelo menos um número",
  //       hasNumber: false,
  //       password: "",
  //     });
  //     this.ref_password1.current.style.border =
  //       "0.16rem solid rgb(255, 150, 150)";
  //     // return true;
  //   } else {
  //     this.setState({ hasNumber: true });
  //   }

  //   if (this.state.temp_password1.search(/[a-zA-Z]/) == -1) {
  //     this.setState({
  //       msgErro: "A senha deve conter pelo menos uma letra",
  //       hasLetter: false,
  //       password: "",
  //     });
  //     this.ref_password1.current.style.border =
  //       "0.16rem solid rgb(255, 150, 150)";
  //     // return true;
  //   } else {
  //     this.setState({ hasLetter: true });
  //   }

  //   if (
  //     this.state.temp_password1.search(
  //       /[^a-zA-Z0-9\!\@\#\$\.\%\^\&\*\(\)\_\+]/
  //     ) != -1
  //   ) {
  //     this.setState({
  //       msgErro: "A senha deve conter apenas caracteres válidos",
  //       hasSpecialChar: false,
  //       password: "",
  //     });
  //     this.ref_password1.current.style.border =
  //       "0.16rem solid rgb(255, 150, 150)";
  //     // return true;
  //   } else {
  //     this.setState({ hasSpecialChar: true });
  //   }

  //   if (this.state.temp_password1.search(/\s/) != -1) {
  //     this.setState({
  //       msgErro: "A senha não deve conter espaços",
  //       hasSpace: true,
  //       password: "",
  //     });
  //     this.ref_password1.current.style.border =
  //       "0.16rem solid rgb(255, 150, 150)";
  //     // return true;
  //   } else {
  //     this.setState({ hasSpace: false });
  //   }

  //   if (this.state.temp_password1 != this.state.temp_password2) {
  //     this.setState({
  //       msgErro: "As senhas não coincidem",
  //       hasMatch: false,
  //       password: "",
  //     });
  //     this.ref_password2.current.style.border =
  //       "0.16rem solid rgb(255, 150, 150)";
  //     // return true;
  //   } else {
  //     this.setState({ hasMatch: true });
  //   }

  //   this.ref_password1.current.style.border = "none";
  //   this.ref_password2.current.style.border = "none";
  //   this.setState({
  //     password: this.state.temp_password2,
  //     msgErro: "",
  //   });
  //   return true;
  // }

  // foca o input da div
  focusInput(e) {
    e.currentTarget.lastChild.focus();
  }
  focusInputSibling(e) {
    e.currentTarget.lastChild.previousElementSibling.focus();
  }

  async handleSubmit() {
    if (this.state.name == "") {
      swal({
        title: "Preencha o campo nome",
        icon: "warning",
        button: "Ok",
      });
      return;
    } else if (this.state.email == "") {
      swal({
        title: "Preencha o campo e-mail",
        icon: "warning",
        button: "Ok",
      });
      return;
    } else if (this.state.phone.length < 14) {
      swal({
        title: "Insira um número de telefone válido",
        icon: "warning",
        button: "Ok",
      });
      return;
    }

    const store = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
    };
    this.setState({ isLoading: true });

    let res = await post(`${URL}api/new-user/add`, JSON.stringify(store));

    if (res.status === true) {
      this.setState({
        name: "",
        email: "",
        phone: "",
      });

      swal({
        title: "Cadastro realizado com sucesso!",
        text: "Em breve um de nossos colaboradores entrará em contato com você.",
        icon: "success",
        button: "Ok",
      });
    } else {
      swal({
        title: "Erro ao cadastrar",
        text: res.error[0],
        icon: "error",
        button: "Ok",
      });
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "rgb(240, 244, 247)",
          background: `url(${bm_world}) no-repeat`,
          backgroundPositionX: "120%",
          backgroundPositionY: "20rem",
          paddingTop: "4rem",
        }}
      >
        <Navbar />

        {this.submitted && (
          <ShowAlert
            message="Cadastro realizado com sucesso! Em breve um de nossos colaboradores entrará em contato com você."
            type="success"
          />
        )}

        {/* <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--Raleway)",
            margin: "auto 0",
            marginBottom: "2rem",
            textAlign: "center",
            width: "100%",
          }}
        >
          <div style={{ width: "50rem", margin: "0 auto" }}>
            Faça cadastro para ser cliente BM-Words. Após o cadastro um dos
            nossos colaboradores entrará em contato com você.
          </div>
        </Typography> */}
        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--Raleway)",
            margin: "auto 0",
            marginBottom: "0.2rem",
            // marginTop: '8rem',
            textAlign: "center",
            fontWeight: "bold",
            color: "rgb(70, 70, 70)",
            display: { xs: "none", md: "block" },
          }}
        >
          Faça cadastro para ser cliente BM-Words.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "var(--Raleway)",
            margin: "auto 0",
            marginTop: "0",
            marginBottom: 0,
            textAlign: "center",
            color: "rgb(70, 70, 70)",
            display: { xs: "none", md: "block" },
          }}
        >
          Após o cadastro um dos nossos colaboradores entrará em contato com
          você.
        </Typography>

        <Box
          sx={{
            // display: "flex",
            // margin: "auto",
            // marginTop: "2rem",
            // borderRadius: "1rem",
            // overflow: "hidden",
            // boxShadow: { xs: "none", md: "0 1rem 2rem rgba(0, 0, 0, 0.1)" },
            // flexDirection: { xs: "column", md: "row" },
            // // flexDirection: { xs: "column-reverse", md: "row-reverse" },
            display: "flex",
            margin: "auto",
            marginTop: { xs: "2rem", md: "2rem" },
            borderRadius: "1rem",
            overflow: "hidden",
            boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.1)",
            flexWrap: { xs: "wrap", md: "nowrap" },
            width: {
              xs: "auto",
              md: "80%",
              lg: "60%",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "var(--purple)",
              padding: "4rem",
              color: "white",
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%!important", md: "max-content" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={signup_image}
              alt="Imagem de login"
              style={{ objectFit: "contain", width: "10rem" }}
            />

            {/* <Typography
              variant="body1"
              sx={{
                fontFamily: "var(--Raleway)",
                color: "rgb(210, 210, 210)",
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <p style={{ maxWidth: "18rem", textAlign: "center" }}>
                Crie sua conta agora mesmo para receber os benefícios de ser um
                cliente BM-Words.
              </p>
            </Typography> */}
            <Typography
              variant="body1"
              sx={{
                fontFamily: "var(--Raleway)",
                color: "rgb(210, 210, 210)",
                maxWidth: "18rem",
                textAlign: "center",
              }}
            >
              Ao criar uma conta, você concorda com nossos{" "}
              <a
                className="normal-archor white"
                href={"/termos-de-uso"}
                style={{ display: "inline" }}
              >
                Termos de Serviço
              </a>{" "}
              e{" "}
              <a
                className="normal-archor white"
                href={"/politica-de-privacidade"}
                style={{ display: "inline" }}
              >
                Política de Privacidade
              </a>
              .
            </Typography>

            {/* <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: this.state.min6chars ? "#a4ff91" : "#f59d9d",
                }}
              >
                {this.state.min6chars === true ? (
                  <CheckRoundedIcon />
                ) : (
                  <CloseRoundedIcon />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--Raleway)",
                    maxWidth: "18rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Mínimo de 6 caracteres.
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: this.state.max20chars ? "#a4ff91" : "#f59d9d",
                  fontWeight: "bold",
                }}
              >
                {this.state.max20chars === true ? (
                  <CheckRoundedIcon />
                ) : (
                  <CloseRoundedIcon />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--Raleway)",
                    maxWidth: "18rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Máximo de 20 caracteres.
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: this.state.hasNumber ? "#a4ff91" : "#f59d9d",
                  fontWeight: "bold",
                }}
              >
                {this.state.hasNumber === true ? (
                  <CheckRoundedIcon />
                ) : (
                  <CloseRoundedIcon />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--Raleway)",
                    maxWidth: "18rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Ter pelo menos um número.
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: this.state.hasLetter ? "#a4ff91" : "#f59d9d",
                  fontWeight: "bold",
                }}
              >
                {this.state.hasLetter === true ? (
                  <CheckRoundedIcon />
                ) : (
                  <CloseRoundedIcon />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--Raleway)",
                    maxWidth: "18rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Ter pelo menos uma letra.
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: this.state.hasSpecialChar ? "#a4ff91" : "#f59d9d",
                  fontWeight: "bold",
                }}
              >
                {this.state.hasSpecialChar === true ? (
                  <CheckRoundedIcon />
                ) : (
                  <CloseRoundedIcon />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--Raleway)",
                    maxWidth: "18rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Ter caracteres válidos.
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: this.state.hasSpace ? "#f59d9d" : "#a4ff91",
                  fontWeight: "bold",
                }}
              >
                {this.state.hasSpace === true ? (
                  <CloseRoundedIcon />
                ) : (
                  <CheckRoundedIcon />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--Raleway)",
                    maxWidth: "18rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Não ter espaços.
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: this.state.hasMatch ? "#a4ff91" : "#f59d9d",
                  fontWeight: "bold",
                }}
              >
                {this.state.hasMatch === true ? (
                  <CheckRoundedIcon />
                ) : (
                  <CloseRoundedIcon />
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--Raleway)",
                    maxWidth: "18rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  As senhas devem ser iguais.
                </Typography>
              </div>
            </div> */}
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              padding: "4rem",
              width: { xs: "100%!important", md: "max-content" },
            }}
          >
            <form
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              onSubmit={(e) => {
                e.preventDefault();
                this.handleSubmit();
              }}
            >
              <div
                className="normal-input"
                onClick={(e) => {
                  this.focusInput(e);
                }}
              >
                <PersonIcon sx={{ color: "rgb(100, 100, 100)" }} />
                <input
                  type="text"
                  placeholder="Nome completo"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>

              <div
                className="normal-input"
                onClick={(e) => {
                  this.focusInput(e);
                }}
              >
                <EmailIcon sx={{ color: "rgb(100, 100, 100)" }} />
                <input
                  type="email"
                  placeholder="E-mail"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>

              <div
                className="normal-input"
                onClick={(e) => {
                  this.focusInput(e);
                }}
              >
                <LocalPhoneIcon sx={{ color: "rgb(100, 100, 100)" }} />
                <input
                  maxLength="15"
                  type="text"
                  placeholder="Telefone"
                  value={this.state.phone}
                  onChange={(e) => {
                    this.setState({ phone: e.target.value });
                    this.phoneMaskBrazil(e);
                  }}
                />
              </div>

              {/* <div
                className="normal-input"
                ref={this.ref_password1}
                onClick={(e) => {
                  this.focusInputSibling(e);
                }}
              >
                <HttpsIcon sx={{ color: "rgb(100, 100, 100)" }} />
                <input
                  type={this.state.hide_password ? "password" : "text"}
                  placeholder="Senha"
                  value={this.state.temp_password1}
                  onChange={(e) => {
                    this.setState(
                      {
                        temp_password1: e.target.value,
                      },
                      () => {
                        this.checkPassword(e.target.value);
                      }
                    );
                  }}
                />
                {this.state.hide_password ? (
                  <VisibilityOffIcon
                    sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                    onClick={() => this.setState({ hide_password: false })}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                    onClick={() => this.setState({ hide_password: true })}
                  />
                )}
              </div>

              <div
                className="normal-input"
                ref={this.ref_password2}
                onClick={(e) => {
                  this.focusInputSibling(e);
                }}
              >
                <HttpsIcon sx={{ color: "rgb(100, 100, 100)" }} />
                <input
                  type={this.state.hide_password ? "password" : "text"}
                  placeholder="Confirme a senha"
                  value={this.state.temp_password2}
                  onChange={(e) => {
                    this.setState(
                      {
                        temp_password2: e.target.value,
                      },
                      () => {
                        this.checkPassword(e.target.value);
                      }
                    );
                  }}
                />
                {this.state.hide_password ? (
                  <VisibilityOffIcon
                    sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                    onClick={() => this.setState({ hide_password: false })}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                    onClick={() => this.setState({ hide_password: true })}
                  />
                )}
              </div> */}

              {this.state.msgErro && (
                <Typography variant="body2" sx={{ color: "red" }}>
                  <HighlightOffRoundedIcon /> {this.state.msgErro}
                </Typography>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                  alignItems: "center",
                  justifyItems: "center",
                  gap: "0.4rem",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "var(--Raleway)" }}
                >
                  Já tem uma conta?{" "}
                  <a className="normal-archor purple" href={"/login"}>
                    Faça login
                  </a>
                </Typography>

                <input
                  type="submit"
                  className="form-submit-button"
                  style={{
                    padding: "1rem 2rem",
                    backgroundColor: "var(--purple)",
                    border: "none",
                    outline: "none",
                    color: "white",
                    fontFamily: "var(--Raleway)",
                    width: "max-content",
                    alignSelf: "flex-end",
                    borderRadius: "1rem",
                    fontSize: "1rem",
                  }}
                  // onClick={this.login.bind(this)}
                  value="Entrar"
                />
              </div>
            </form>
          </Box>
        </Box>

        <div style={{ marginTop: "2rem" }}>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Signup;
