import { Box, Icon, Typography } from "@mui/material";

import { StyledCardHover, StyledCardHoverBg } from "./styles";

const CardHover = ({
  icon = false,
  mui_icon = false,
  title,
  text,
  bg_image,
}) => {
  return (
    <StyledCardHover>
      <Box
        sx={{
          zIndex: 2,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: {
            xs: "2rem",
            sm: "4rem",
          },
        }}
      >
        {icon && (
          <img src={icon} style={{ width: "2rem" }} alt="Fundo do Cartão"></img>
        )}

        {mui_icon && (
          <Icon
            component={mui_icon}
            sx={{ fontSize: "2rem", color: "white" }}
          />
        )}

        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {title}
        </Typography>
        <Typography
          className="cardText"
          sx={{
            textAlign: "justify",
            fontFamily: "var(--Raleway)",
            color: "rgb(220, 220, 220)",
            transition: "400ms ease, backdrop-filter 0ms 800ms",
            "&:hover": {
              color: "white",
            },
          }}
          variant="body1"
        >
          {text}
        </Typography>
      </Box>

      <StyledCardHoverBg src={bg_image} alt="Fundo do cartão" />
    </StyledCardHover>
  );
};

export default CardHover;
