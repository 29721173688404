import FormInput from "./FormInput";
import { Icon } from "@mui/material";
import { useEffect, useState } from "react";

import { get, post } from "../../utilities/requests";
import { URL } from "../../variables";

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const PolicyAndTerms = () => {
  const [readyToRender, setReadyToRender] = useState(false);
  const [save_button_icon, setSave_button_icon] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });

  const [all_policy, setAll_policy] = useState({});
  const [all_terms, setAll_terms] = useState({});

  // funcao responsavel por alterar o content de algum formulario especifico
  // também responsável por criar novos forumlarios
  const HandleFormContent = (data) => {
    let { type, content, key = null } = data;

    let temp;

    if (type === "policy") {
      // caso a chave seja passada, quer dizer que algum formulario está sendo editado
      // do contrário, quer dizer que um formulario foi adicionado
      key = key === null ? Object.keys(all_policy).length : key;

      temp = { ...all_policy };
      temp[key] = content;
      setAll_policy(temp);
    } else {
      // caso a chave seja passada, quer dizer que algum formulario está sendo editado
      // do contrário, quer dizer que um formulario foi adicionado
      key = key === null ? Object.keys(all_terms).length : key;

      temp = { ...all_terms };
      temp[key] = content;
      setAll_terms(temp);
    }
  };

  // requisicao para pegar as politicas e termos
  // essa funcao tbm renderiza os componentes com o conteudo
  const getto = async () => {
    const res = await get(`${URL}api/policy-and-terms`);

    console.log(res);

    let obj_temp = {};

    // salva os objetos pegos do banco de dados nos respectivos estados
    res["policy"].map((policy) => {
      obj_temp = { ...obj_temp };
      obj_temp[Object.keys(obj_temp).length] = policy.content;
    });
    setAll_policy(obj_temp);

    obj_temp = {};
    res["terms"].map((terms) => {
      obj_temp = { ...obj_temp };
      obj_temp[Object.keys(obj_temp).length] = terms.content;
    });
    setAll_terms(obj_temp);

    setReadyToRender(true);
  };

  const saveContent = async () => {
    let form_data = {};
    let obj_data = [];

    // salvar as politicas no data
    Object.keys(all_policy).map((obj) => {
      if (all_policy[obj] !== null) {
        let [title, text] = all_policy[obj].split("_{slice}_");

        if (title.length > 0 || text.length > 0) {
          obj_data.push({ type: "policy", content: all_policy[obj] });
        }
      }
    });

    // salvar os termos no data
    Object.keys(all_terms).map((obj) => {
      if (all_terms[obj] !== null) {
        let [title, text] = all_terms[obj].split("_{slice}_");

        if (title.length > 0 || text.length > 0) {
          obj_data.push({ type: "terms", content: all_terms[obj] });
        }
      }
    });

    setSave_button_icon({
      status: "Salvando...",
      icon: <HourglassTopRoundedIcon />,
    });

    form_data.data = obj_data;
    let response = await post(
      `${URL}api/policy-and-terms/save`,
      JSON.stringify(form_data)
    );

    setTimeout(() => {
      if (response.httpCode === 200) {
        setSave_button_icon({
          status: "Salvo!",
          icon: <CheckRoundedIcon />,
        });
        setTimeout(() => {
          setSave_button_icon({
            status: "Salvar tudo",
            icon: <SaveIcon />,
          });
        }, 3000);
      }
    }, 500);
  };

  useEffect(() => {
    getto().catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        height: "100%",
        // padding: "2rem",
        gap: "1rem",
        fontFamily: "var(--Raleway)",
      }}
    >
      {readyToRender && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            height: "100%",
            padding: "1.2rem",
            gap: "1rem",
            position: "relative",
          }}
        >
          <button
            style={{
              gap: "0.4rem",
              position: "fixed",
              top: "5rem",
              right: "1rem",
              padding: "0.4rem 1rem",
              backgroundColor: "#84eb7f",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              zIndex: "4",
              borderRadius: "0.4rem",
              border: "none",
              color: "black",
            }}
            onClick={saveContent}
          >
            {save_button_icon.icon} {save_button_icon.status}
          </button>

          <div className="form-policy-terms-container">
            <div
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Políticas de Privacidade</span>
              <button
                style={{
                  width: "max-content",
                  gap: "0.4rem",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  HandleFormContent({ type: "policy", content: null });
                }}
              >
                <AddIcon /> Adicionar
              </button>
            </div>

            {
              // loop responsavel por renderizar as politicas nos formularios
              Object.keys(all_policy).length > 0 &&
                Object.keys(all_policy).map((index) => (
                  <FormInput
                    type="policy"
                    obj_key={index}
                    fun_handle_form={HandleFormContent}
                    content={all_policy[index]}
                    key={index}
                  />
                ))
            }

            {Object.keys(all_policy).length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  fontSize: "1.2rem",
                }}
              >
                <span
                  style={{
                    marginTop: "2rem",
                    background: "#f1f1f1",
                    padding: "0.4rem 1rem",
                    borderRadius: '0.4rem'
                  }}
                >
                  Nenhuma política de privacidade adicionada.
                </span>
              </div>
            )}
          </div>

          <div className="form-policy-terms-container">
            <div
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Termos de Uso</span>
              <button
                style={{
                  width: "max-content",
                  gap: "0.4rem",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  HandleFormContent({ type: "terms", content: null });
                }}
              >
                <AddIcon /> Adicionar
              </button>
            </div>

            {
              // loop responsavel por renderizar os termos nos formularios
              Object.keys(all_terms).length > 0 &&
                Object.keys(all_terms).map((index) => (
                  <FormInput
                    type="terms"
                    obj_key={index}
                    fun_handle_form={HandleFormContent}
                    content={all_terms[index]}
                    key={index}
                  />
                ))
            }

            {Object.keys(all_terms).length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  fontSize: "1.2rem",
                }}
              >
                <span
                  style={{
                    marginTop: "2rem",
                    background: "#f1f1f1",
                    padding: "0.4rem 1rem",
                    borderRadius: '0.4rem'
                  }}
                >
                  Nenhum termo de uso adicionado.
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PolicyAndTerms;
