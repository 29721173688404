import { toast, ToastContainer } from "react-toastify";
import swal from "sweetalert";

export const ToastContent = () => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  )
}

export const renderToast = (type, message) => {
  toast[type](message, {
    position: "bottom-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export const renderAlert = (callback) => {
  return (
    swal({
      title: "Deletar registro selecionado?",
      text: "Uma vez deletado, não dará para recuperá-lo!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (!willDelete) {
        return
      }
      const callbackStatus = await callback()

      if (callbackStatus) {
        swal(callbackStatus ?? "Registro deletado com sucesso!", {
          icon: "success",
        });
      } else {
        swal("Falha ao deletar registro", {
          icon: "error",
        });
      }
    })
  )
}
