import LogosObj from "../../utilities/logos";
import { Typography } from "@mui/material";
import React from "react";
import Navbar from "../../components/Navbar";
import SchoolIcon from '@mui/icons-material/School';

export default function PainelAlunoPage() {
  const logo = LogosObj.main.purple;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "rgb(240, 244, 247)",

        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        padding: "6rem 1rem",
        color: "var(--purple)",
      }}
    >
      <Navbar />
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          backgroundColor: "var(--purple)",
          color: "white",
          padding: "0.5rem 1rem",
          borderRadius: "0.5rem",
          marginBottom: "1rem",
          paddingLeft: "0.6rem",
          textAlign: "center",
        }}
      >
        <SchoolIcon
          sx={{
            fontSize: "2.5rem",
          }}
        />{" "}
        Painel do Aluno
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Página em construção.
      </Typography>
      <Typography variant="h5">#BeMoreanywhere</Typography>
      <img
        src={logo}
        alt="logo"
        style={{
          display: "block",
          width: "50%",
          maxWidth: "22rem",
          marginTop: "2rem",
        }}
      />
    </div>
  );
}
