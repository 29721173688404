import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { URL } from '../../variables';
import { Button, FormControl, IconButton, TextField, ThemeProvider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveIcon from '@mui/icons-material/Save';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { LoadingButton } from '@mui/lab';
import JoditEditor from 'jodit-react';
import Theme from '../Theme/Theme';

const AddClassroom = ({ edit }) => {
  const [state, setState] = React.useState({
    nome: { value: '', error: false },
    descricao: { value: '', error: false },
    conteudo: '',
    material: '',
    titulo: '',
    edit_titulo: '',
    conteudos: [],
    materiais: [{ titulo: '', total: 0, materiais: [] }],
    urls: [],
    foto: {},
    loading_save: false,
    render: false,
    render_material: false,
    content_error: false,
  })
  const [form, setForm] = React.useState({
    align: 'left',
    bold: false,
    italic: false,
    underline: false,
    error: false,
  })

  const paragraphStyle = (item) => {
    const style = {
      textAlign: item.align,
      fontWeight: item.bold ? 'bold' : 'normal',
      fontStyle: item.italic ? 'italic' : 'normal',
      textDecoration: item.underline ? 'underline' : 'none'
    }
    return style
  }
  const params = useParams()
  const id = params.id
  const token = useSelector(state => state.AppReducer.token)
  const history = useNavigate()

  React.useEffect(() => {
    if (edit) {
      let conteudos = []
      edit.class.contents.forEach(item => {
        const new_conteudo = {
          'titulo': item.title,
          'conteudo': item.paragraphs,
        }
        conteudos = [...conteudos, new_conteudo]
      })

      setState({
        ...state,
        nome: { value: edit.class.name, error: false },
        descricao: { value: edit.class.description, error: false },
        conteudos: conteudos,
        material: edit.class.material,
      })
    }
  }, [state.materiais])

  function salvar() {
    let keys = ['nome', 'descricao']
    let state2 = { ...state }
    console.log(state2)
    keys.forEach(item => {
      if (state2[item].value === '') state2[item].error = true
    })
    if (state2.conteudos.length === 0) state2.content_error = true
    // setState({ ...state2, loading_save: true })

    if (!state.nome.error && !state.descricao.error && !state.content_error) {
      const formData = new FormData()
      formData.append('subject_id', id)
      formData.append('name', state.nome.value)
      formData.append('description', state.descricao.value)
      state.materiais.forEach(item => {
        item.materiais.forEach(item2 => {
          formData.append(`files[]`, item2.file);
        })
      });
      state.conteudos.forEach(item => {
        formData.append(`contents[]`, JSON.stringify(item));
      });
      console.log('materiais', formData.getAll('files[]'));

      // let schedule_id = ''
      // if (edit) schedule_id = edit.schedule.id
      fetch(`${URL}api/classroom/add`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      })
        .then(async (result) => {
          const json = await result.json();
          setState({ ...state, loading_save: false })
          return json;
        }).then((resp) => {
          if (resp.status) history(`/home/subjects/subject/${id}`)
        })
    }
  }

  function renderMateriais(titulo) {
    console.log('materiais state', state.materiais)
    return (
      state.materiais.map(item => {
        if (item.titulo == titulo) {
          return (
            item.materiais.map(item2 => (
              <>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <div className="d-flex rounded p-2 my-3 bg-light">
                    <div className="justify-content-start">
                      <iframe style={{ maxWidth: '100%' }} src={item2.url} title='file'></iframe>
                      <p>{item2.file.name}</p>
                    </div>
                    <div className='justify-contend-end'>
                      <IconButton onClick={() => {
                        let material_body = state.materiais.filter(item3 => item3.titulo === item.titulo)[0]
                        material_body = material_body.materiais.filter(item4 => item4.file.name !== item2.file.name)

                        let materiais = [...state.materiais]
                        materiais.map(item3 => {
                          if (item3.titulo === item.titulo) {
                            item3.materiais = material_body
                          }
                          return item3
                        })

                        setState({ ...state, materiais: materiais })
                      }}>
                        <CloseIcon></CloseIcon>
                      </IconButton>
                    </div>
                  </div>
                </div>
              </>
            ))
          )
        }
      })
    )
  }


  function renderBox() {
    return (
      <FormControl fullWidth>
        <JoditEditor value={state.conteudo} onChange={value => setState({ ...state, conteudo: value })} />

        {/* <textarea className='rounded-top' style={contentStyle} value={state.conteudo} onChange={(e) => setState({ ...state, conteudo: e.target.value, error: false })}></textarea> */}

        <div className="input-group">
          <Button className='col-6' variant="contained" component="label" endIcon={<UploadFileIcon />}>
            Enviar Materiais
            <input hidden onChange={(e) => { state.edit_titulo ? addMaterial(e, false) : addMaterial(e, true) }} accept="application/pdf" multiple type="file" />
          </Button>

          <Button className='col-6' color='success' variant='contained' onClick={() => addParagraph()} size='small'>
            {state.edit_titulo ? 'Editar ' : 'Adicionar '}
            {state.edit_titulo ? <EditIcon className='ms-1' fontSize='small' /> : <AddIcon fontSize='small' />}
          </Button>
        </div>
      </FormControl >
    )
  }

  function addMaterial(e, add) {
    let url = window.URL.createObjectURL(e.target.files[0]);
    let materiais = [...state.materiais]
    let new_material = { file: e.target.files[0], url: url }
    let content_materiais = ''
    if (add) {
      content_materiais = materiais.filter(item => item.titulo === state.titulo)[0]
    } else {
      content_materiais = materiais.filter(item => item.titulo === state.edit_titulo)[0]
    }

    if (content_materiais) {
      content_materiais.materiais.push(new_material)
    } else {
      materiais = [...materiais, { titulo: state.titulo, materiais: [new_material] }]
    }

    setState({ ...state, materiais: materiais })
  }

  function addParagraph() {
    let conteudos = [...state.conteudos]

    if (state.edit_titulo) {
      conteudos.map(item => {
        if (item.titulo === state.edit_titulo) {
          item.titulo = state.titulo
          item.conteudo = { text: state.conteudo }
          return item
        }
        return item
      })
    } else {
      conteudos = [...conteudos, {
        titulo: state.titulo,
        conteudo: { text: state.conteudo }
      }]
    }

    setState({ ...state, conteudos: conteudos, conteudo: '', titulo: '', edit_titulo: '' })
  }


  function renderConteudo() {
    return (
      state.conteudos.map((item, index) => {
        let style = paragraphStyle(item.conteudo)
        return (
          <div className='col-12 rounded my-3' key={index}>
            <Typography backgroundColor='inherit' variant='h6'>{item.titulo}</Typography>
            <div className='d-flex bg-light p-3 rounded mb-3'>
              <div dangerouslySetInnerHTML={{ __html: item.conteudo.text }} className="justify-content-start">
              </div>

              <div className='ms-auto' style={{ whiteSpace: 'nowrap' }}>
                <IconButton onClick={() => {
                  let conteudos = [...state.conteudos]
                  let old_conteudo = conteudos.filter(item2 => item2.titulo === item.titulo)[0]
                  window.scrollTo(0, 0);

                  setState({ ...state, titulo: old_conteudo.titulo, conteudo: old_conteudo.conteudo.text, edit_titulo: old_conteudo.titulo })
                }}>
                  <EditIcon />
                </IconButton>

                <IconButton onClick={() => {
                  let conteudos = [...state.conteudos]
                  conteudos = conteudos.filter(item2 => item2.titulo !== item.titulo)

                  setState({ ...state, conteudos })
                }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="row">
              {renderMateriais(item.titulo)}
            </div>
          </div >
        )
      }
      )
    )
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className='card'>
        <div className='card-body m-5'>
          <div className='row'>
            <div className="d-flex align-items-center mb-5">
              <h1 className='title ms-2'>Adicionar Aula</h1>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-5">
                <TextField fullWidth label='Nome*' value={state.nome.value} onChange={(e) => setState({ ...state, nome: { value: e.target.value, error: false } })} error={state.nome.error}></TextField>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className='mb-5'>
                <TextField fullWidth label='Descrição' value={state.descricao.value} onChange={(e) => setState({ ...state, descricao: { value: e.target.value, error: false } })} error={state.descricao.error}></TextField>
              </div>
            </div>

            <div className="col-12">
              <TextField fullWidth label='Título*' value={state.titulo} onChange={(e) => setState({ ...state, titulo: e.target.value, content_error: false })} error={state.content_error}></TextField>
            </div>

            <div className="row m-auto">
              {renderBox()}
            </div>
            {state.conteudos.length > 0 && <div className='rounded p-3 mt-5' style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
              <Typography>CONTEÚDOS</Typography>
              <div className="row">
                {renderConteudo()}
              </div>
            </div>}
          </div>

          <div className="d-flex mt-5">
            <div className="align-self-center">
              <Button size='large' variant="outlined" onClick={() => history('/home/classes')} startIcon={<ReplyAllIcon />}> Voltar</Button>
            </div>
            <div className="align-self-center ms-auto">
              <LoadingButton size='large' loading={state.loading_save} onClick={() => salvar()} loadingPosition="end" endIcon={<SaveIcon />} variant="outlined">Salvar</LoadingButton>
            </div>
          </div>
        </div >
      </div>
    </ThemeProvider >
  )
}

export default AddClassroom