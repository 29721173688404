// export const URL = 'http://localhost:8000/';
// export const THIS_URL = 'http://localhost:3000/';
export const URL = "https://api.bmwords.com.br/"
export const THIS_URL = "https://www.bmwords.com.br/"

export const API_URL = URL + 'api/';
export const STORAGE_URL = URL + 'storage/';

export function MOUNT_FORM_DATA(props) {
  let data = new FormData()
  let keys = { ...props.form }
  keys = Object.keys(keys)
  keys.forEach(item => {
    data.append(`${item}`, props.form[item].value)
  })
  if (props.id) data.append('id', props.id)
  // return data
}

export function MOUNT_JSON_BODY(props) {
  let body = {}
  let keys = { ...props.form }
  keys = Object.keys(keys)
  keys.forEach(item => {
    body = { ...body, [item]: props.form[item].value }
  })
  return body
}

export function FILL_FORM(props) {
  let form = {}
  let keys = { ...props.form }
  keys = Object.keys(keys)
  keys.forEach(item => {
    form = { ...form, [item]: { ...props.form[item], value: props.edit[item] } }
  })
  return form
}

export async function POST_FETCH(props) {
  return (fetch(props.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${props.token}`
    },
    body: JSON.stringify({ ...props.body })
  }).then(async (response) => {
    const resp = await response.json()
    return resp
  })
  )
}

export async function POST_PATCH_FETCH_FORMDATA(props) {
  return (fetch(props.url, {
    method: props.method,
    headers: {
      'Authorization': `Bearer ${props.token}`
    },
    body: props.body,
  }).then(async (response) => {
    const resp = await response.json()
    return resp
  })
  )
}

export async function GET_DELETE_FETCH(props) {
  return (fetch(props.url, {
    method: props.method,
    headers: {
      'Authorization': `Bearer ${props.token}`
    },
  }).then(async (response) => {
    const resp = await response.json()
    return resp
  })
  )
}

export function VALIDATE({ form, setForm }) {
  let error = false
  let form2 = { ...form }
  let keys = Object.keys(form)

  keys.forEach(item => {
    let validate = false
    let toValidade = Array.from(form[item].label)

    toValidade.forEach(item2 => { if (item2 === '*') validate = true })
    if (validate && form[item].value === '') {
      form2[item].error = true
      error = true
    }
  })
  setForm(form2)
  return error
}
