import { Container, Typography } from "@mui/material";
import Section100vh from "../../../components/Section100vh";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "./TestimonialCard";
import { get } from "../../../utilities/requests";
import { useEffect, useState } from "react";
import { STORAGE_URL, URL } from "../../../variables";

const responsive = {
  0: {
    items: 1,
  },
  480: {
    items: 1,
  },
  780: {
    items: 2,
  },
  1300: {
    items: 3,
  },
};

const Depoimentos = () => {
  const [testimonies, setTestimonies] = useState([]);
  const [readyToRender, setReadyToRender] = useState(false);

  const getto = async () => {
    const res = await get(`${URL}api/testimonies`);

    if (res.httpCode === 200) {
      // setTestimonies(res.testimonies);
      let temp_testimonies = res.testimonies.map((data) => {
        return {
          name: data.name,
          text: data.text,
          avatar: data.avatar_path,
          occupation: data.occupation,
        };
      });
      setTestimonies(temp_testimonies);
      setReadyToRender(true);
    }
  };

  useEffect(() => {
    getto().catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    readyToRender && (
      <Container
        maxWidth="xl"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.4rem",
          margin: "auto",
          padding: "4rem 0",
          width: "100vw",
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            alignSelf: "center",
          }}
        >
          Depoimentos
        </Typography>

        <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: "var(--Raleway)",
            alignSelf: "center",
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "40px",
              borderTop: "2px solid #444",
              margin: "30px auto 0",
            },
          }}
        >
          O que nossos clientes e parceiros falam de nós.
        </Typography>

        <OwlCarousel
          className="owl-theme"
          loop
          dots={false}
          nav={false}
          items={3}
          center
          autoplay={true}
          autoplayTimeout={6000}
          responsive={responsive}
        >
          {testimonies.map((testimony, index) => (
            <TestimonialCard key={index} {...testimony} />
          ))}
        </OwlCarousel>
      </Container>
    )
  );
};

export default Depoimentos;
