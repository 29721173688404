import { Box } from "@mui/system";
import "./index.modules.css";

/*
  tipos de A:

  1- normal, texto: azul (padrao)
  2- purple, texto: roxo
  3- special, com transicao de fundo (roxo para azul)

  sintaxe: <LinkBtn href_content='#' archor_content='Sobre nos' archor_type='purple' />
*/

function Navbar(props) {
  const {
    href_content = null,
    archor_content,
    archor_target = "_self",
    archor_type = 0,
    archor_icon = null,
    onMouseEnter = null,
    onMouseLeave = null,
    style,
  } = props;

  // caso um tipo não seja passado, o padrão será usado
  let class_archor = `normal-archor ${archor_type ? archor_type : ""}`;

  return (
    <Box
      component="a"
      target={archor_target}
      className={class_archor}
      href={href_content}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={style}
    >
      {archor_icon ? archor_icon : ""}
      {archor_content}
    </Box>
    // <a
    // >
    // </a>
  );
}

export default Navbar;
