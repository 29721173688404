import React from 'react'
import AddSubjectTag from './AddSubjectTag'
import { GET } from '../../../utilities/requests'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

const EditSubjectTag = () => {
  const { id } = useParams()
  const history = useNavigate()

  const [data, setData] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const response = await GET(`subject/tag/edit/${id}`)
    setLoading(false)

    if (!response) {
      history('/home/subject/tag/list')
      return
    }

    setData(response.tag)
  }

  return (
    <>
      {!loading && data
        ?
        <AddSubjectTag editData={data} />
        :
        <div className="d-flex justify-content-center">
          <CircularProgress color="secondary" />
        </div>}
    </>
  )
}

export default EditSubjectTag