import { Typography } from "@mui/material";
import Collapse from "../ServicosComponent/Collapse";

export const page_data = {
  "/BM-Scripta": {
    // question: "O que é BM-Scripta?",
    answer: [
      {
        renderAsComponent: true,
        data: (
          <>
            <Collapse
              question="O que é BM-Scripta?"
              answer={[
                {
                  data: "O BM-Scripta consiste em uma modalidade de serviços prestados pela BM Words que compreende assessorias voltadas à prática escrita e falada em diferentes contextos.",
                },
              ]}
            />
            <Collapse
              question="Quais são os serviços relacionados ao BM-Scripta?"
              answer={[
                {
                  renderAsComponent: true,
                  data: (
                    <Typography
                      variant="subtitle1"
                      sx={{ fontFamily: "var(--Raleway)" }}
                    >
                      Os serviços do BM-Scripta englobam, principalmente, quatro modalidades: o <b>BM-Red</b>, o <b>BM-Term</b>, o <b>BM-MKT</b> e o <b>BM-Ação</b>. Eles foram criados com o intuito de melhor atender aos objetivos e às necessidades de nosso(a)s clientes.
                    </Typography>
                  ),
                },
              ]}
            />
            <Collapse
              question="Em quais idiomas os serviços do BM-Scripta são oferecidos?"
              answer={[
                {
                  data: "Os serviços do BM-Scripta são oferecidos em francês, inglês e português. Ao contratá-los, o(a)s clientes recebem assessoria da equipe BM Words que conta com Mestres, Doutores e Pós-Doutores, que têm uma formação de excelência nas melhores universidades do Brasil e do exterior e experiência de mais de uma década.",
                },
              ]}
            />
            <Collapse
              question="Quem pode fazer?"
              answer={[
                {
                  data: "Os serviços do BM-Scripta são destinados a profissionais e a empresas que buscam aprimorar técnicas de liderança, de autoridade, de segurança comunicativa, de objetividade, de postura e de eloquência. Essas técnicas e outras mais ou menos específicas a depender do seu nicho ampliam o seu reconhecimento em sua área de atuação, além de lhe trazer mais segurança e tranquilidade em suas relações comerciais e de conseguirem captar mais clientes para seus respectivos negócios.",
                },
              ]}
            />
            {/* <Collapse
              question="Qual é o nosso diferencial?"
              answer={[
                {
                  data: "Com uma proposta que seja, de fato, personalizada, os serviços do BM-Scripta oferecem um acompanhamento mais próximo do(a)s clientes que precisam aprimorar sua performance linguística em seu contexto de trabalho. Assim, os objetivos e as necessidades de nosso(a)s clientes norteiam a proposta e a execução desses serviços. Contamos com uma equipe de Mestres, Doutores e Pós-Doutores em Linguística e Tradução, especialistas em Terminologia, Marketing Digital, Marketing de Conteúdo, dentre outras, formados por renomadas universidades brasileiras e estrangeiras e por grandes especialistas nas áreas em que atuamos.",
                },
              ]}
            /> */}
          </>
        ),
      },
    ],
  },
};
