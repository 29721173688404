import { Route, Routes, Navigate } from "react-router-dom";
import ServicosComponent from "./ServicosComponent";
import { page_data } from "./data.js";
import BmCursosInfo from "./ServicosComponent/BmCursosInfo";
import SaibaMais from "./SaibaMais";

const pages = [
  "/BM-Business",
  "/BM-Voyage",
  "/BM-Talks",
  "/BM-Scripta",
  "/BM-Red",
  "/BM-Trad",
  "/BM-Rev",
  "/BM-Term",
  "/BM-Acao",
  "/BM-MKT",
];

const ForCompaniesOrProfessionals = () => {
  return (
    <Routes>
      {pages.map((page, index) => (
        <Route
          key={index}
          path={page}
          element={<ServicosComponent data={page_data[page]} page={page} />}
        />
      ))}

      <Route path="bm-cursos/o-que-sao" element={<BmCursosInfo />} />

      <Route path="bm-cursos/saiba-mais/*" element={<SaibaMais />} />

      {/* generic 404 */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default ForCompaniesOrProfessionals;
