import React from 'react'
import { URL } from '../../../variables'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import useForm from '../../../utilities/hooks/useForm'
import { GET, POST } from '../../../utilities/requests'
import { renderToast } from '../../../utilities/alerts'
import SaveButton from '../../../routes/Form/SaveButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'

const Profile = () => {
  const history = useNavigate()
  const dispatch = useDispatch()
  const timeout = React.useRef(null)
  const fileInputRef = React.useRef(null);

  const [loading, setLoading] = React.useState(true)
  const [loadingSave, setLoadingSave] = React.useState(false)
  const [loadingPassword, setLoadingPassword] = React.useState(false)

  const [ablePassword, setAblePassword] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [showOldPassword, setShowOldPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

  const { form, setForm, errors, setError, handleChange, handleBlur, validateForm, handleFileChange } = useForm({
    name: '',
    file_path: '',
    email: '',
    password: '',
    old_password: '',
    confirm_password: '',
  })

  React.useEffect(() => {
    getData()
  }, [])

  // -------------------------*------------------------- //
  const getData = async () => {
    const response = await GET(`get_logged_user`)
    setLoading(false)

    if (!response) {
      renderToast('error', response.message)
      setTimeout(() => history('/user'), 1500)
    }

    setForm({
      ...form,
      name: response.user.name || '',
      email: response.user.email || '',
      file_path: { value: '', url: response.user.file_path || '' },
    })
  }

  // -------------------------*------------------------- //
  const confirmOldPassword = (e) => {
    handleChange(e)
    clearTimeout(timeout.current)

    timeout.current = setTimeout(async () => {
      setLoadingPassword(true)
      const response = await POST(`confirm_old_password`, JSON.stringify({ old_password: e.target.value }))
      setLoadingPassword(false)

      if (!response.status) {
        setError('old_password', 'Senhas não coincidem!')
        return
      }

      setAblePassword(true)
    }, 750)
  }

  // -------------------------*------------------------- //
  const handleValidatePassword = (e) => {
    handleChange(e)

    if (form.password !== e.target.value) {
      setError('confirm_password', 'Senhas não coincidem')
    }
  }

  // -------------------------*------------------------- //
  const handleSave = async () => {
    const isValid = validateForm(['file_path', 'old_password'])

    if (!isValid) {
      return
    }

    if (form.password !== form.confirm_password) {
      setError('confirm_password', 'Senhas não coincidem')
      setError('password', 'Senhas não coincidem')
      return
    }

    setLoadingSave(true)

    let formData = new FormData()
    for (const [key, value] of Object.entries(form)) {
      formData.append(key, value)
    }
    formData.append('file_path', form.file_path.value)

    const response = await POST(`user/update`, formData)
    setLoadingSave(false)

    if (response) {
      renderToast('success', response.message)
      dispatch({ type: 'user', payload: response.user })
      setTimeout(() => history('/user'), 1500)
    }
  }


  return (
    <div className='row'>
      <h1 style={{ fontSize: '2.5rem', fontWeight: 700, color: '#8b7db2' }}>Dados do Usuário</h1>

      {!loading ?
        <form onSubmit={(e) => { e.preventDefault(); handleSave() }}>
          {/* -------------------------User file input------------------------- */}
          <div className='mt-3 d-flex justify-content-center align-items-center pointer'>
            <div className='text-center me-5' style={{ width: 150, height: 150 }} onClick={() => fileInputRef.current.click()}>
              {form.file_path.url
                ? <img src={form.file_path.value ? form.file_path.url : `${URL}storage/${form.file_path.url}`} alt="img_user" style={{ width: 150, height: 150, borderRadius: '50%' }} />
                : <div className='d-flex align-items-center justify-content-center' style={{ width: 150, height: 150, borderRadius: '50%', backgroundColor: '#F7F7F7' }}>
                  <p style={{ fontSize: '5rem', fontWeight: '700', color: '#8b7db2', margin: 0 }}>{form.name.charAt(0).toUpperCase()}</p>
                </div>}

              <input hidden ref={fileInputRef} onChange={handleFileChange} name='file_path' accept="image/*" type="file" />
            </div>

            <div>
              {/* -------------------------Name input------------------------- */}
              <TextField
                fullWidth
                className='my-1'
                name='name'
                label='Nome *'
                value={form.name}
                onChange={handleChange}
                helperText={errors.name ?? ' '}
                onBlur={handleBlur}
                error={Boolean(errors.name)}
              />

              {/* -------------------------Email input------------------------- */}
              <TextField
                fullWidth
                disabled
                className='my-1'
                name='email'
                label='Email *'
                value={form.email}
                helperText={errors.email ?? ' '}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(errors.email)}
              />

              {/* -------------------------Confirm old password input------------------------- */}
              {!ablePassword ?
                <FormControl fullWidth className='my-2' variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{errors.old_password ?? 'Confirme sua senha antiga *'}</InputLabel>
                  <OutlinedInput
                    name='old_password'
                    label={errors.old_password ?? 'Confirme sua senha antiga *'}
                    type={showOldPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          edge="end"
                        >
                          {loadingPassword ? <CircularProgress size={18} /> : (showOldPassword ? <VisibilityOffIcon /> : <VisibilityIcon />)}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={form.old_password}
                    onBlur={handleBlur}
                    onChange={confirmOldPassword}
                    error={Boolean(errors.old_password)}
                  />
                </FormControl>
                :
                <>
                  {/* -------------------------Password input------------------------- */}
                  <FormControl fullWidth className='mb-3 mt-2' variant="outlined">
                    <InputLabel htmlFor="password">{errors.password ?? 'Nova senha *'}</InputLabel>
                    <OutlinedInput
                      name='password'
                      label={errors.password ?? 'Nova senha *'}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={form.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(errors.password)}
                    />
                  </FormControl>

                  {/* -------------------------Confirm password input------------------------- */}
                  <FormControl fullWidth className='my-3' variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{errors.confirm_password ?? 'Confirme sua senha *'}</InputLabel>
                    <OutlinedInput
                      name='confirm_password'
                      label={errors.confirm_password ?? 'Confirme sua senha *'}
                      type={showConfirmPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={form.confirm_password}
                      onBlur={handleBlur}
                      onChange={handleValidatePassword}
                      error={Boolean(errors.confirm_password)}
                    />
                  </FormControl>
                </>}
            </div>
          </div>
          {/* -------------------------Buttons------------------------- */}
          <div className="row">
            <SaveButton path={'/user'} loading={loadingSave} disabled={!ablePassword} />
          </div>
        </form>
        : <div className='d-flex justify-content-center p-5'><CircularProgress /></div>}
    </div>
  )
}

export default Profile