import React from 'react'

const useSearch = (setLoading) => {
  const [search, setSearch] = React.useState('')

  let timer
  const handleSearch = ({ target }) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setLoading(true)
      setSearch(target.value)
    }, 750);
  }

  return {
    search,
    handleSearch,
  }
}

export default useSearch