import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import HourglassTopRoundedIcon from "@mui/icons-material/HourglassTopRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useEffect, useState } from "react";
import TestimoniesCard from "./TestimoniesCard";
import { get, post } from "../../utilities/requests";
import { STORAGE_URL, URL } from "../../variables";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const absolute_button_style = {
  gap: "0.4rem",
  padding: "0.4rem 1rem",
  // backgroundColor: "#84eb7f",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  borderRadius: "0.4rem",
  border: "none",
  color: "black",
};

const Testimonies = () => {
  const [save_button_icon, setSave_button_icon] = useState({
    status: "Salvar tudo",
    icon: <SaveIcon />,
  });

  const [readyToRender, setReadyToRender] = useState(false);
  const [testimonies, setTestimonies] = useState([]);

  const addTestimonial = () => {
    setTestimonies([
      ...testimonies,
      {
        name: "",
        title: "",
        text: "",
        avatar: "",
        occupation: "",
      },
    ]);
  };

  const removeTestimonial = (index) => {
    let temp = [...testimonies];
    temp.splice(index, 1)

    setTestimonies(temp);
  };

  const handleTestimonialChange = (index, data) => {
    let temp = [...testimonies];
    temp[index].title =
      data.title !== undefined ? data.title : temp[index].title;
    temp[index].name = data.name !== undefined ? data.name : temp[index].name;
    temp[index].text = data.text !== undefined ? data.text : temp[index].text;
    temp[index].avatar =
      data.avatar !== undefined
        ? data.avatar
        : temp[index].avatar;
    temp[index].occupation =
      data.occupation !== undefined ? data.occupation : temp[index].occupation;
    console.log(temp);
    setTestimonies(temp);
  };

  const saveTestimonies = async () => {
    setSave_button_icon({
      status: "Salvando...",
      icon: <HourglassTopRoundedIcon />,
    });

  

    const form = new FormData()

    testimonies.forEach(data => {
      let data2 = {...data}
      data2.avatar_path = data.avatar
      delete data2.avatar

      form.append("testimonies[]", JSON.stringify(data2));
    });

    let response = await post(`${URL}api/testimonies/save`, form);

    setTimeout(() => {
      if (response.httpCode === 200) {
        setSave_button_icon({
          status: "Salvo!",
          icon: <CheckRoundedIcon />,
        });
        setTimeout(() => {
          setSave_button_icon({
            status: "Salvar tudo",
            icon: <SaveIcon />,
          });
        }, 3000);
      } else {
        alert(response.message);
        setSave_button_icon({
          status: "Erro ao salvar",
          icon: <SaveIcon />,
        });
        setTimeout(() => {
          setSave_button_icon({
            status: "Salvar tudo",
            icon: <SaveIcon />,
          });
        }, 3000);
      }
    }, 500);
  };

  const getto = async () => {
    const res = await get(`${URL}api/testimonies`);

    if (res.httpCode === 200) {
      // setTestimonies(res.testimonies);
      let temp_testimonies = res.testimonies.map((data) => {
        return {
          name: data.name,
          title: data.title,
          text: data.text,
          avatar: data.avatar_path,
          occupation: data.occupation,
        };
      });
      setTestimonies(temp_testimonies);
      setReadyToRender(true);
    }
  };

  useEffect(() => {
    getto().catch((e) => {
      console.log(e);
    });
  }, []);

  return (
    readyToRender && (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          height: "100%",
          gap: "2rem",
          fontFamily: "var(--Raleway)",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "5rem",
            right: "1rem",
            zIndex: "4",
            display: "flex",
            gap: "2rem",
          }}
        >
          <span
            style={{
              padding: "0.2rem 0.4rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f1f1f1",
              display: "flex",
              alignItems: "center",
              gap: "0.4rem",
              width: "max-content",
            }}
          >
            <ErrorOutlineIcon sx={{ color: "var(--purple)" }} />{" "}
            <b>Proporção de tela recomendada para a imagem:</b> 1:1
          </span>
          <button
            style={{ ...absolute_button_style, backgroundColor: "#b3c3c7" }}
            onClick={addTestimonial}
          >
            <AddIcon /> Adicionar
          </button>
          <button
            style={{ ...absolute_button_style, backgroundColor: "#84eb7f" }}
            onClick={saveTestimonies}
          >
            {save_button_icon.icon} {save_button_icon.status}
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            gap: "2rem",
            width: "100%",
          }}
        >
          {testimonies.map((user, index) => (
            <TestimoniesCard
              key={index}
              index={index}
              remove={removeTestimonial}
              change={handleTestimonialChange}
              title={user.title}
              text={user.text}
              avatar={user.avatar}
              name={user.name}
              occupation={user.occupation}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default Testimonies;
