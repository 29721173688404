import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { URL } from '../../variables';
import { useSelector } from 'react-redux';

export default function TransitionsModal(props) {
  const [state, setState] = React.useState({
    conteudos: [],
    loading: true,
  })
  const [open, setOpen] = React.useState(false);
  const [fullScreen, setFullScreen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    fetch(`${URL}api/classroom/edit/${props.id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        console.log('json', json)
        // json.class.contents.map(item => {
        //   if (item.materials.length > 0) {
        //     let materials = []
        //     item.materials.forEach(item2 => {
        //       var reader = new FileReader();
        //       reader.readAsDataURL('http://localhost:8000/storage/' + item2.file);
        //       reader.onload = function (e) {
        //         console.log('DataURL:', e.target.result);
        //       };
        //       let url = window.URL.createObjectURL('http://localhost:8000/storage/' + item2.file);
        //       materials = [...materials, { url: url, file: item2.file }]
        //     })
        //     item.materials = materials
        //   }
        //   return item
        // })
        // console.log('json2', json)

        setState({
          ...state,
          conteudos: json.class.contents,
          loading: false,
        })
      });
  }, [])

  const style = {
    position: 'absolute',
    // top: '50%',
    left: fullScreen ? '5%' : '50%',
    transform: 'translate(0, 0, 0, 50%)',
    width: fullScreen ? '95%' : '50%',
    height: '100%',
    bgcolor: 'background.paper',
    border: '2px solid transparent',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    '@media(max-width: 1000px)': {
      width: fullScreen ? '100%' : '90%',
      left: fullScreen ? '0%' : '5%',
    },
  };

  const paragraphStyle = (item) => {
    const style = {
      textAlign: item.align,
      fontWeight: item.bold ? 'bold' : 'normal',
      fontStyle: item.italic ? 'italic' : 'normal',
      textDecoration: item.underline ? 'underline' : 'none'
    }
    return style
  }

  return (
    <div className='js-w'>
      <IconButton color='success' onClick={handleOpen} size='small'>
        <VisibilityIcon fontSize='medium' />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <IconButton onClick={handleClose} size='small'>
                <CloseIcon fontSize='medium' />
              </IconButton>
              {fullScreen ?
                <IconButton onClick={() => setFullScreen(false)} size='small'>
                  <FullscreenExitIcon fontSize='medium' />
                </IconButton> :
                <IconButton onClick={() => setFullScreen(true)} size='small'>
                  <FullscreenIcon fontSize='medium' />
                </IconButton>}
            </div>
            <div className="row">
              <Typography variant='h5'>CONTEÚDO</Typography>
              {state.conteudos ? state.conteudos.map((item, index) => (
                <div className='col-12 rounded my-3' key={index}>
                  <Typography backgroundColor='inherit' variant='h6'>{item.title}</Typography>
                  <div className='d-flex bg-light p-3 rounded mb-3' key={index}>
                    <div className="justify-content-start" dangerouslySetInnerHTML={{ __html: item.text }}>
                    </div>
                  </div>
                  {item.materials && item.materials.map(item2 =>
                  (
                    <div className="col-6">
                      <iframe style={{ width: '100%', height: '100%' }} src={`${URL}storage/${item2.file}`} title='material'></iframe>
                      <a href={`${URL}storage/${item2.file}`} target="_blank" rel="noopener noreferrer">Abrir</a>
                    </div>
                  )
                  )}
                </div >
              )
              ) : <Typography>Sem conteúdos inseridos</Typography>}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
