import { Route, Routes, Navigate } from "react-router-dom";
import ForCompaniesOrProfessionals from "./ForCompaniesOrProfessionals";
import ForNaturalPerson from "./ForNaturalPerson";
import General from "./General";

const ServicosPage = () => {
  return (
    <>
      <Routes>
        {/* natural person */}
        <Route path={"/para-pessoas-fisicas/*"} element={<ForNaturalPerson />} />

        {/* companies or professionals */}
        <Route path={"/para-empresas-ou-profissionais/*"} element={<ForCompaniesOrProfessionals />} />

        {/* page for the user to choose which service hes nterested  */}
        <Route path={"/"} element={<General />} />
        
        {/* generic 404 */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

export default ServicosPage;