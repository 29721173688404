import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Button, ThemeProvider, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import React from "react";
import Input from "../Input";
import validator from "validator";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { STORAGE_URL, THIS_URL, URL } from "../../../variables";
import { useNavigate } from "react-router-dom";
import Theme from "../../Theme/Theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useEffect } from "react";
import { fileToBase64 } from "../../../utilities/helpers";

const AddTag = ({ edit }) => {
  const [form, setForm] = React.useState({
    name: { label: "Nome da tag", value: "" },
  });

  const [token, setToken] = React.useState(
    useSelector((state) => state.AppReducer.token)
  );

  const [file, setFile] = React.useState("");
  const [image_base64, setImage_base64] = React.useState("");

  const [state, setState] = React.useState({
    token: "",
  });
  const [error, setError] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const history = useNavigate();
  state.token = useSelector((state) => state.AppReducer.token);

  useEffect(() => {
    if (edit) {
      console.log(edit);
      setForm({
        ...form,
        name: { ...form.name, value: edit.label },
      });
      setImage_base64(STORAGE_URL + edit.image);
      setFile(1);
    }
  }, []);

  useEffect(() => {
    fileToBase64(file).then((data) => {
      setImage_base64(data);
    });
  }, [file]);

  async function addTag(type) {
    const keys = Object.keys(form);
    let form2 = { ...form };
    keys.forEach((item) => {
      if (form2[item].value == "") setError(true);
    });
    setForm(form2);

    if (!error) {
      const formData = new FormData();
      formData.append("label", form.name.value);

      if (file !== 1) {
        formData.append("image", file);
      }

      if (edit) formData.append("id", edit.id);

      let result = await fetch(`${URL}api/blog/tags/${type}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then(async (response) => {
          const json = await response.json();
          return json;
        })
        .then(async (json) => {
          if (json.status === 200) {
            renderInput();
            history("/home/blog/tags/list");
          }
        });
    }
  }

  function renderInput() {
    const keys = Object.keys(form);
    return keys.map((item) => {
      return (
        <Input
          key={item.label}
          item={item}
          form={form}
          setForm={(e) => setForm(e)}
        />
      );
    });
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          <div className="row p-sm-5">
            <div className="col-12 col-md-8 m-auto">
              <h1 className="title mb-5">Criar nova tag</h1>
              {renderInput()}
              <Typography variant="h6" component="div">
                Imagem
              </Typography>
              <label
                htmlFor="file-input-blog"
                style={{
                  width: "22rem",
                  height: "12rem",
                  cursor: "pointer",
                  border: "0.2rem dashed gray",
                  borderRadius: "0.6rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {file ? (
                  <img
                    src={image_base64}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <AddRoundedIcon style={{ fontSize: "2.2rem" }} />
                )}
                <input
                  hidden
                  onChange={(e) => setFile(e.target.files[0])}
                  accept="image/*"
                  multiple
                  type="file"
                  id="file-input-blog"
                />
              </label>
              <span
                style={{
                  padding: "0.2rem 0.4rem",
                  borderRadius: "0.4rem",
                  backgroundColor: "#f1f1f1",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.4rem",
                  width: "max-content",
                  marginTop: "0.8rem",
                }}
              >
                <ErrorOutlineIcon sx={{ color: "var(--purple)" }} />{" "}
                <b>Proporção de tela recomendada:</b> 16:9 ou 22:9
              </span>

              <div className="d-flex mb-5">
                <div className="me-3"></div>
              </div>
              <div className="d-flex mt-5">
                <div className="align-self-center">
                  <Button
                    variant="outlined"
                    onClick={() => history("/home/blog/tags/list")}
                    startIcon={<ReplyAllIcon />}
                  >
                    {" "}
                    Voltar
                  </Button>
                </div>
                <div className="align-self-center ms-auto">
                  <LoadingButton
                    loading={saving}
                    loadingPosition="end"
                    endIcon={<SaveIcon />}
                    variant="outlined"
                    onClick={() => (edit ? addTag("update") : addTag("add"))}
                  >
                    Salvar
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AddTag;
