import { TextField, Typography } from "@mui/material";
import React from "react";
import LogosObj from "../../utilities/logos";
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';


const BMeBookStorePage = () => {
  const logo = LogosObj.main.purple;

  return (
    <div
      style={{
        // margin: 'auto',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        padding: '6rem 1rem',
        color: 'var(--purple)',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          backgroundColor: 'var(--purple)',
          color: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '0.5rem',
          marginBottom: '1rem',
          paddingLeft: '0.6rem',
          textAlign: 'center',
        }}
      >
        <BookmarkBorderRoundedIcon sx={{
          fontSize: '2.5rem',
        }} /> BM eBook Store
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Página em construção.
      </Typography>
      <Typography
        variant="h5"
      >
        #BeMoreanywhere
      </Typography>
      <img
        src={logo}
        alt="logo"
        style={{
          display: 'block',
          width: '50%',
          maxWidth: '22rem',
          marginTop: '2rem',
        }}
      />

    </div>
  );
}

export default BMeBookStorePage;