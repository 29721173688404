import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CircularProgress, Input } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ListButton = (props) => {
  const history = useNavigate();
  let timer
  function search(e) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      props.setState({ ...props.state, search: e.target.value, loadingSearch: true })
    }, 750);
  }

  return (
    <div className="d-flex">
      <div className="flex-column">
        <h1 className='header-title'>{props.title}</h1>
        <Input placeholder='Buscar...' endAdornment={props.state.loadingSearch ? <CircularProgress size={24} /> : <SearchIcon />} onChange={(e) => search(e)}></Input>
      </div>
      <div className="align-self-end ms-auto">
        <Button classes={{ contained: 'bg-primary-bm' }} variant="contained" size='large' onClick={() => history(props.path)}>Adicionar</Button>
      </div>
    </div>
  )
}

export default ListButton