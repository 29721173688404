import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#6c3c9c 0%,#7b50a6 50%,#b69ece 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#6c3c9c 0%,#7b50a6 50%,#b69ece 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#f1f1f1",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  color: "black",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #6c3c9c 0%, #7b50a6 50%, #b69ece 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: "white",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #9b77ba 0%, #b89ece 50%, #c6b1d7 100%)",
    color: "white",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <BadgeIcon />,
    2: <AccountCircleIcon />,
    3: <HomeIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["E-mail da conta", "Código de verificação", "Nova senha"];

export default function CustomizedSteppers({ section_index }) {
  return (
    <Stack sx={{ width: "100%", maxWidth: '70rem', width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={section_index}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step
            key={label}
            sx={{
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "var(--text-color0)", // Just text label (COMPLETED)
                  fontFamily: "var(--Raleway)",
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "var(--text-color0)", // Just text label (ACTIVE)
                  fontSize: "1.1rem",
                  fontFamily: "var(--Raleway)",
                  fontWeight: "bold",
                },
                "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel":
                {
                  color: "var(--text-color0)", // Just text label (DISABLED)
                  fontFamily: "var(--Raleway)",
                },
            }}
          >
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}