import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { Icon, Modal, Tooltip } from "@mui/material";

// mui icons
import ShareIcon from "@mui/icons-material/Share";
import LinkIcon from "@mui/icons-material/Link";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { STORAGE_URL, THIS_URL } from "../../../variables";

const Card = ({
  id,
  firstCard,
  title,
  summary,
  background_image_path,
  tags,
  created_at,
  author,
}) => {
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTime, setFormattedTime] = useState("");

  const [clipboard_button_icon, setClipboard_button_icon] = useState(LinkIcon);

  // modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const getFormattedDate = (date) => {
    const d = new Date(date);

    if (d.toDateString() == new Date().toDateString()) return "Hoje";

    const month = d.toLocaleString("default", { month: "long" });
    const day = d.getDate();
    return `${day}/${month}`;
  };

  const URL_TO_SHARE = `${THIS_URL}/blog/ler${id}`;

  const HandleCopyClipboard = () => {
    navigator.clipboard.writeText(URL_TO_SHARE).then(
      () => {
        setClipboard_button_icon(CheckRoundedIcon);
      },
      function (err) {
        console.error("Erro ao copiar o link. Erro: ", err);
        setClipboard_button_icon(ErrorOutlineRoundedIcon);
      }
    );

    setTimeout(() => {
      setClipboard_button_icon(LinkIcon);
    }, 1500);
  };

  useEffect(() => {
    setFormattedDate(getFormattedDate(created_at));
    setFormattedTime(
      new Date(created_at).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexBasis: firstCard ? "100%" : "28rem",
          flexGrow: 1,
          width: firstCard ? "100%" : "28rem",
          backgroundColor: "white",
          gap: "0.4rem",
          overflow: "hidden",
          borderRadius: "0.6rem",
          cursor: "pointer",
          transition: "500ms 200ms ease",
          "&:hover .card-read-more": {
            opacity: "1!important",
          },
          "&:hover .card-image": {
            filter: "brightness(0.9)",
          },
        }}
        onClick={() => {
          window.location.href = `/blog/ler/${id}`;
        }}
      >
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            height: "16rem",
            backgroundImage: `url(${STORAGE_URL}${background_image_path})`,
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
          className="card-image"
        >
          <div style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            display: "flex",
            flexWrap: "wrap",
            gap: '0.4rem',
            width: '23rem',
            overflow: 'hidden',
          }}>
            {tags?.map((tag, index) => (
              <div
                key={index}
                style={{
                  padding: "0.2rem 0.8rem",
                  paddingBottom: "0.4rem",
                  borderRadius: "10rem",
                  backdropFilter: "blur(2rem)",
                  fontSize: "1.2rem",
                  backgroundColor: "rgba(0,0,0,0.3)",
                  color: "white",
                  display: "flex",
                }}
              >
                <div style={{ color: "rgb(210, 210, 210)" }}>#</div>
                {tag.label}
              </div>
            ))}
          </div>

          <Box
            sx={{
              position: "absolute",
              maxWidth: "2.4rem",
              right: "1rem",
              top: "1rem",
              backgroundColor: "rgba(0,0,0,0.3)",
              color: "white",
              borderRadius: "10rem",
              display: "flex",
              overflow: "hidden",
              backdropFilter: "blur(2rem)",
              gap: "0.6rem",
              padding: "0.4rem",
              alignItems: "center",
              transition: "500ms ease",
              "&:hover": {
                maxWidth: "9.8rem",
              },
              "&:hover .card-share-icon": {
                width: "0",
              },
            }}
            onClick={handleOpen}
          >
            <ShareIcon
              className="card-share-icon"
              sx={{ transition: "500ms ease" }}
            />
            <Tooltip title="Copiar link" arrow>
              <button
                style={{
                  cursor: "pointer",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                onClick={HandleCopyClipboard}
              >
                <Icon
                  component={clipboard_button_icon}
                  sx={{
                    color: "white",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                />
              </button>
            </Tooltip>
            <Tooltip title="Whatsapp" arrow>
              <button
                style={{
                  cursor: "pointer",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?text=*${title}* ${URL_TO_SHARE}`,
                    "_blank"
                  )
                }
              >
                <Icon
                  component={WhatsAppIcon}
                  sx={{
                    color: "white",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                />
              </button>
            </Tooltip>
            <Tooltip title="Twitter" arrow>
              <button
                style={{
                  cursor: "pointer",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                onClick={() =>
                  window.open(
                    `https://twitter.com/share?url=${title} ${URL_TO_SHARE}`,
                    "_blank"
                  )
                }
              >
                <Icon
                  component={TwitterIcon}
                  sx={{
                    color: "white",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                />
              </button>
            </Tooltip>
            <Tooltip title="Facebook" arrow>
              <button
                style={{
                  cursor: "pointer",
                  border: "0",
                  backgroundColor: "transparent",
                }}
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${URL_TO_SHARE}%3Futm_source%3Dfacebook%26utm_medium%3Dsocial%26utm_campaign%3Dcompfb`,
                    "_blank"
                  )
                }
              >
                <Icon
                  component={FacebookIcon}
                  sx={{
                    color: "white",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                />
              </button>
            </Tooltip>
          </Box>

          <div
            style={{
              opacity: 0,
              // transition: "500ms cubic-bezier(0.8,0,.01,.80)",
              backdropFilter: "blur(2rem)",
              padding: "0.2rem 0.8rem",
              paddingBottom: "0.4rem",
              borderRadius: "10rem",
              fontSize: "1.2rem",
              backgroundColor: "rgba(0,0,0,0.3)",
              color: "white",
            }}
            className="card-read-more"
          >
            Ler mais
          </div>
        </div>

        <div
          style={{
            padding: "1rem",
            color: "rgb(90, 90, 90)",
          }}
        >
          <h5>{title}</h5>
          <p
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {summary}
          </p>

          <span style={{ fontSize: "0.9rem" }}>
            {formattedDate} às {formattedTime} por {author}
          </span>
        </div>
      </Box>
    </>
  );
};

export default Card;
