import React from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { URL } from '../../variables';
import AddUser from './AddUser';
import { CircularProgress } from '@mui/material';

const EditUser = () => {
  const params = useParams()
  const id = params.id
  const [state, setState] = React.useState({
    data: {},
    token: '',
    redirect: false,
  })
  state.token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    if (id !== null) {
      fetch(`${URL}api/edit/${id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${state.token}`,
          // 'Content-Type': 'application/json',
        }
      })
        .then(async (response) => {
          console.log(response)
          const json = await response.json();
          console.log(json)
          return json;
        }).then(async (json) => {
          setState({ ...state, data: json, redirect: true })
        });
    }
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center">
        {!state.redirect && <CircularProgress color="secondary" />}
      </div>
      {state.redirect && <AddUser edit={state.data} />}
    </>
  )
}

export default EditUser