import React from 'react';
import { Link, Redirect, useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useSelector } from 'react-redux';
import { Button, IconButton, Input } from '@mui/material';
import { URL } from '../../variables';

const ListClasses = () => {
  const [state, setState] = React.useState({
    classes: [],
    pagination: { current_page: '', total_pages: '', per_page: '' },
    pageNumber: '',
    loading: true,
    reload: false,
    search: '',
  })
  const history = useNavigate();
  const token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    fetch(`${URL}api/classroom/list?page=${state.pageNumber}&search=${state.search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        console.log('json', json)
        setState({
          ...state,
          classes: json.classes,
          pagination: json.pagination,
          loading: false,
        })
      });
  }, [state.pageNumber, state.search, state.reload]);


  async function deleteClass(id) {
    setState({ ...state, reload: !state.reload })
    let result = await fetch(`${URL}api/classroom/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    });
    // setState({...state, })
  }

  function editClass(id) {
    history('/home/classes/edit/' + id);
  }

  function pagination() {
    const { current_page, total_pages, per_page } = state.pagination;
    return (
      <div className='d-flex justify-content-end me-5'>
        <Pagination
          activePage={current_page}
          totalItemsCount={total_pages}
          itemsCountPerPage={per_page}
          onChange={(page) => setState({ ...state, pageNumber: page })}
          firstPageText="Primeiro"
          lastPageText="Ultimo"
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    )
  }

  return (
    <div className="card">
      <div className='card-body'>
        <div className="d-flex">
          <div className="flex-column">
            <h1 className='header-title'>Lista de Aulas</h1>
            <Input placeholder='Buscar...' endAdornment={<SearchIcon />} onChange={(e) => setState({ ...state, search: e.target.value })}></Input>
          </div>
          <div className="align-self-end ms-auto">
            <Button variant="contained" size='large' endIcon={<PersonAddIcon />} onClick={() => history('/home/classes/add')}>Adicionar</Button>
          </div>
        </div>
        <table className="table table-striped table-hover text-center mt-5">
          <thead className='text-center'>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Descrição</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          {state.classes &&
            <tbody>
              {state.classes.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.description}</td>
                    <td>
                      <IconButton size='large'>
                        <EditIcon size='large' color='primary' onClick={() => editClass(item.id)} />
                      </IconButton>

                      <IconButton size='large'>
                        <DeleteIcon color='error' onClick={() => deleteClass(item.id)} />
                      </IconButton>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          }
        </table>
        {state.loading && <div className="row"> <div className="col-12 p-5 d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"  ></div></div></div>}
        {state.classes && pagination()}
      </div>
    </div>
  )
}
export default ListClasses