import styled from "styled-components"


export const StyledCardHoverBg = styled.img`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: 1;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 30%, transparent 100%);

  transition: 800ms 400ms ease;
`;

export const StyledCardHover = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 0.1rem solid rgba(255, 255, 255, 0.4);
  max-width: 30rem;
  height: 25rem;
  align-items: center;
  justify-content: center;
  flex-basis: 30rem;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;

  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0);

  transition: 800ms 400ms ease;

  &:hover {
    transform: translateY(-1rem);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    z-index: 3;
    transition: 200ms ease;

    ${StyledCardHoverBg} {
      opacity: 0.6;
      transition: 400ms ease;
    }

    p {
      background-color: rgba(0, 0, 0, 0.08);
      border-radius: 0.4rem;
      backdrop-filter: blur(1rem);
      transition: 400ms ease, backdrop-filter 0ms;
    }
  }
`;
