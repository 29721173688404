import React from "react";
import { URL } from "../../variables";
import { connect, useSelector, useDispatch } from "react-redux";
import { login } from "../../components/actions/AppActions";
import Logo from "../../assets/es-logo-colorido.png";
import Footer from "../../components/Footer";
import BG from "../../assets/perguntas1.jpg";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import Input from "../../components/Input/Input";
// import Link from "../Link"
import Navbar from "../../components/Navbar";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

// mui icons
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";

import login_image from "../../assets/static/login_image.svg";
import bm_world from "../../assets/static/bm_world.png";
import ShowAlert from "../../components/ShowAlert";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      msgErro: "",
      email: "",
      password: "",
      redirect: false,
      path: "",
      loading_save: false,
      hide_password: true,
    };
  }

  async login(e) {
    e.preventDefault();
    this.setState({ loading_save: true, msgErro: "" });
    if (this.state.email === "") {
      this.setState({ msgErro: "Preencha o campo de email", loading_save: false });
      return;
    }
    console.log(this.state.password);
    if (this.state.password.length < 1) {
      this.setState({ msgErro: "Preencha o campo de senha", loading_save: false });
      return;
    }
    fetch(`${URL}api/auth/login`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        // 'Authorization': `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors.length !== 0) {
            let erro = resp.errors;

            this.setState({
              loading: false,
              msgErro: erro,
              refresh: false,
              loadingMore: false,
              loading_save: false,
            });
          } else {
            // login sucesso
            console.log("sucesso");
            this.setState({ loading_save: false });

            localStorage.setItem("token", resp.access_token);
            localStorage.setItem("user", JSON.stringify(resp.user));

            this.props.login({ token: resp.access_token, user: resp.user });

            if (resp.user.first_access) {
              window.location.href = "/primeiro-acesso";
            } else if (resp.user?.type_user[0]?.student) {
              window.location.href = "/painel-aluno";
            }

            // this.props.history.push("/home/dashboard");
          }
        } catch (err) {
          console.log(err);
          this.setState({
            loading: false,
            msgErro: "Erro ao pegar resposta do servidor",
            refresh: false,
            loadingMore: false,
            loading_save: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          msgErro:
            "Erro ao pegar resposta do servidor. Você está conectado a internet?",
          refresh: false,
          loadingMore: false,
          loading_save: false,
        });
      });
  }

  componentDidMount() {
    // const {
    //   match: { params },
    // } = this.props;

    // console.log(this.props);
    let current_token = localStorage.getItem("token");
    console.log("current_token,", current_token);

    if (current_token != null) {
      console.log("ta logado. redirecionado");

      // return <Redirect to='/home' />
    }
  }

  changeEmail(event) {
    let text = event.target.value;
    let final_num = "";
    final_num = text;
    this.setState({ email: final_num });
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  // foca o input da div
  focusInput(e) {
    e.currentTarget.lastChild.focus();
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          backgroundColor: "rgb(240, 244, 247)",
          background: `url(${bm_world}) no-repeat`,
          backgroundPositionX: "120%",
          backgroundPositionY: "20rem",
          paddingTop: "4rem",
        }}
      >
        <Navbar />
        {/* <div className="row vh-100 vw-100 justify-content-center align-items-center bg-light">
          <div className="col-8" style={{ maxWidth: 600 }}>
            <div className="card py-5">
              <div className="card-body">
                <div className="text-center mb-5">
                  <h1 className="display-6">Login</h1>
                  <p className="lead">Venha fazer parte do nosso masterclass</p>
                </div>
                <div className="d-flex flex-column col-12 col-md-6 m-auto">
                  <Input label={'Usuário'} state={this.state} setState={(e) => this.setState(e)} type={'email'}></Input>
                  <Input label={'Senha'} state={this.state} setState={(e) => this.setState(e)} type={'password'}></Input>
                  <Button variant='outlined' size='large' onClick={this.login.bind(this)}>Entrar</Button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {this.state.msgErro !== "" && this.state.msgErro !== null && (
          <ShowAlert message={this.state.msgErro} type="error" />
        )}

        <Typography
          variant="h5"
          sx={{
            fontFamily: "var(--Raleway)",
            margin: "auto 0",
            marginBottom: "0.2rem",
            textAlign: "center",
            fontWeight: "bold",
            color: "rgb(70, 70, 70)",
            display: { xs: "block", md: "block" },
          }}
        >
          Crie uma conta para se tornar cliente da BM Words.
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "var(--Raleway)",
            margin: "auto 0",
            marginTop: "0",
            marginBottom: 0,
            textAlign: "center",
            color: "rgb(70, 70, 70)",
            display: { xs: "block", md: "block" },
          }}
        >
          Após o seu cadastro, entraremos em contato com você.
        </Typography>

        <Box
          sx={{
            display: "flex",
            margin: "auto",
            marginTop: { xs: "2rem", md: "2rem" },
            borderRadius: "1rem",
            overflow: "hidden",
            boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.1)",
            flexWrap: { xs: "wrap", md: "nowrap" },
            flexDirection: { xs: "column-reverse", md: "row-reverse" },
            width: {
              xs: "auto",
              md: "80%",
              lg: "60%",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "var(--purple)",
              padding: {
                xs: "2rem",
                md: "4rem",
              },
              color: "white",
              gap: "1rem",
              // display: "flex",
              display: { xs: "none", md: "flex" },
              flexDirection: "column",
              width: { xs: "100%!important", md: "max-content" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={login_image}
              alt="Imagem de login"
              style={{ objectFit: "contain", width: "14rem" }}
            />
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              padding: {
                xs: "2rem",
                md: "4rem",
              },
              width: { xs: "100%!important", md: "max-content" },
            }}
          >
            <form
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div
                className="normal-input"
                onClick={(e) => {
                  this.focusInput(e);
                }}
              >
                <EmailIcon sx={{ color: "rgb(100, 100, 100)" }} />
                <input
                  type="text"
                  placeholder="E-mail"
                  value={this.state.email}
                  onChange={(e) =>
                    this.setState({ ...this.state, email: e.target.value })
                  }
                  error={this.state.msgErro}
                />
              </div>

              <div
                className="normal-input"
                onClick={(e) => {
                  this.focusInput(e);
                }}
              >
                <HttpsIcon sx={{ color: "rgb(100, 100, 100)" }} />
                <input
                  type={this.state.hide_password ? "password" : "text"}
                  placeholder="Senha"
                  value={this.state.password}
                  onChange={(e) =>
                    this.setState({ ...this.state, password: e.target.value })
                  }
                  error={this.state.msgErro}
                />
                {this.state.hide_password ? (
                  <VisibilityOffIcon
                    sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                    onClick={() => this.setState({ hide_password: false })}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                    onClick={() => this.setState({ hide_password: true })}
                  />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2rem",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "var(--Raleway)" }}
                >
                  Se você esqueceu sua senha,{" "}
                  <a className="normal-archor purple" href={"/forgot-password"}>
                    clique aqui.
                  </a>
                </Typography>

                {!this.state.loading_save ? (
                  <input
                    type="submit"
                    className="form-submit-button"
                    style={{
                      padding: "1rem 2rem",
                      backgroundColor: "var(--purple)",
                      border: "none",
                      outline: "none",
                      color: "white",
                      fontFamily: "var(--Raleway)",
                      width: "max-content",
                      alignSelf: "flex-end",
                      borderRadius: "1rem",
                      fontSize: "1rem",
                    }}
                    onClick={this.login.bind(this)}
                    value="Entrar"
                  />
                ) : (
                  <div
                    style={{
                      padding: "1rem 2rem",
                      backgroundColor: "var(--purple)",
                      border: "none",
                      outline: "none",
                      width: "max-content",
                      alignSelf: "flex-end",
                      borderRadius: "1rem",
                    }}
                  >
                    <CircularProgress
                      style={{ color: "white" }}
                      size="1.1rem"
                    />
                  </div>
                )}
              </div>
              <Typography variant="body1" sx={{ fontFamily: "var(--Raleway)" }}>
                <a className="normal-archor purple" href={"/signup"}>
                  Criar nova conta
                </a>
              </Typography>
            </form>
          </Box>
        </Box>

        <div style={{ marginTop: "2rem" }}>
          <Footer />
        </div>
      </div>
    );
  }
}

const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { login })(Login);
