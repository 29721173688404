// the content of the Serviços route pages will be stored here and later rendered.
// use renderAsComponent=true to render a component

export const page_data = {
  "/BM-Kids": {
    question: "Sobre o BM-Kids:",
    answer: [
      {
        data: "O curso de idiomas BM-Kids é voltado para crianças, cuja faixa etária vai de 3 a 12 anos. Oferecido em inglês, francês ou português, esse curso segue uma metodologia criada pela BM Words, sendo, portanto, única no mercado. Nosso(a)s aluninho(a)s aprenderão de uma forma bem divertida com os nossos especialistas em ensino/aprendizagem de idiomas para crianças.",
      },
      {
        data: "Os cursos BM-Kids são divididos em dois subtipos, considerando as idades do(a)s aluno(a)s:",
      },
      {
        renderAsComponent: true,
        data: (
          <ul style={{fontFamily: 'var(--Raleway)'}}>
            {" "}
            <li>BM-Kids I, voltado para crianças de 3 a 7 anos;</li>{" "}
            <li>BM-Kids II, direcionado para crianças de 8 a 12 anos.</li>{" "}
          </ul>
        ),
      },
      {
        data: "Em ambos os subtipos, os cursos BM-Kids são oferecidos individualmente ou em grupos de até seis crianças e acontecem de forma on-line.",
      },
    ],
  },
  "/BM-Pro": {
    question: "Sobre o BM-Pro:",
    answer: [
      {
        data: "O curso de idiomas BM-Pro é voltado para adolescentes e adultos. Oferecido em inglês, francês ou português, ele atende às necessidades de aluno(a)s que tenham idade entre 13 e 50 anos.",
      },
      {
        data: "Os cursos BM-Pro são divididos em dois subtipos, considerando as idades do(a)s aluno(a)s:",
      },
      {
        renderAsComponent: true,
        data: (
          <ul style={{fontFamily: 'var(--Raleway)'}}>
            {" "}
            <li>BM-Pro I, voltado para pessoas de 13 a 23 anos;</li>{" "}
            <li>BM-Pro II, direcionado para pessoas de 24 a 50 anos.</li>{" "}
          </ul>
        ),
      },
      {
        data: "O BM-Pro I e o BM-Pro II são oferecidos individualmente ou para grupos de até seis pessoas e acontecem de forma on-line.",
      },
    ],
  },
  "/BM-Senior": {
    question: "Sobre o BM-Sênior:",
    answer: [
      {
        data: "O curso de idiomas BM-Sênior é voltado para adultos com idade superior a 50 anos. Oferecido em inglês, francês ou português, esse curso segue uma metodologia criada pela BM Words, que é diferente do curso BM-Pro e única no mercado. Nosso principal objetivo é atender às necessidades desse público-alvo.",
      },
      {
        data: "O curso BM-Sênior é oferecido individualmente ou para grupos de até três pessoas e acontece de forma on-line.",
      }
    ],
  },
  "/BM-Voyage": {
    question: "Sobre o BM-Voyage:",
    answer: [
      {
        data: "O curso de idiomas BM-Voyage é voltado especialmente para quem vai realizar uma viagem para o exterior. Esse curso prepara o(a)s aluno(a)s para que saibam se comunicar em diferentes situações, tais como em aeroportos, restaurantes, hotéis, meios de transporte, dentre outras. É, nesse sentido, uma imersão na língua e na cultura do país que será visitado.",
      },
      {
        data: "O curso BM-Voyage é oferecido individualmente ou para grupos de até seis pessoas e acontece de forma on-line.",
      }
    ],
  },
  "/BM-Test": {
    question: "Sobre o BM-Test:",
    answer: [
      {
        data: "Os cursos BM-Test consistem em preparatórios para provas de proficiência. Desse modo, se você precisa realizar uma prova desse tipo, é importante que você conheça o seu formato, o tempo para realizar cada uma de suas questões, a sua organização, dentre outros aspectos.",
      },
      {
        data: "Assim, não basta saber a língua: é preciso saber como realizar a prova de proficiência de forma adequada.",
      },
      {
        data: "Para quais provas de proficiência nossa equipe pode lhe oferecer uma assessoria?",
      },
      {
        data: "Nós podemos ajudar no seu preparo para realizar provas como DELF, DALF, TOEFL, TOIEC, etc, bem como exames de proficiência para ingressar em programas de pós-graduação, dentre outros.",
      },
      {
        data: "O curso BM-Test é oferecido individualmente ou para grupos de até seis pessoas e acontece de forma on-line.",
      },
    ],
  },
  "/BM-VIP": {
    question: "Sobre o BM-VIP:",
    answer: [
      {
        data: "O curso BM-VIP é direcionado para quem tem um objetivo bastante específico para aprender inglês, francês ou português. Por exemplo, se você tiver interesse por estudar, além da língua desejada, outros aspectos relacionados, como Arte e Literatura de forma mais específica; ou se tem necessidades particulares de estudo e de acompanhamento com relação à aprendizagem da língua em questão; dentre outros. Desse modo, a assessoria oferecida no curso BM-VIP é mais personalizada, seguindo as suas reais necessidades.",
      },
      {
        data: "O curso BM-VIP é oferecido individualmente ou para grupos de até seis pessoas e acontece de forma on-line.",
      }
    ],
  },
  "/BM-Talks": {
    question: "Sobre o BM-Talks:",
    answer: [
      {
        data: "O curso BM-Talks é voltado para quem deseja desenvolver a competência de conversação de forma geral. Se você já estudou inglês, francês ou português e deseja apenas aprimorar a sua fala e a sua compreensão oral, este BM-Curso foi feito para você. Com ele, nosso principal objetivo é atender às suas necessidades no que diz respeito à prática oral da língua estudada no(s) contexto(s) desejado(s).",
      },
      {
        data: "O curso BM-Talks é oferecido individualmente ou para grupos de até seis pessoas e acontece de forma on-line.",
      }
    ],
  },
};
