import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button, CircularProgress, IconButton, Input, Pagination, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { useSelector } from 'react-redux';
import { URL } from '../../variables';
import Close from '@mui/icons-material/Close';
import ReplyIcon from '@mui/icons-material/Reply';

// ------------------------------------------------------Modal style----------------------------------------------------------
const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '100%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: 5,
  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

const ModalPartners = ({ open, setOpen, fill, state, setState }) => {
  const [search, setSearch] = React.useState('')
  const [loading, setLoading] = React.useState({
    element: true,
    table: false,
  })
  state.token = useSelector(state => state.AppReducer.token);

  // -------------------------------------------------Effect to load users------------------------------------------------------
  React.useEffect(() => {
    setLoading({ ...loading, table: true })
    fetch(`${URL}api/schedule/list_partner?page=${state.pageNumber}&search=${search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setLoading({ ...loading, element: false, table: false })
        setState({ ...state, fillParticipantes: json.users, pagination: json.pagination })
      });
  }, [search, state.pageNumber])

  // ------------------Funciton to add user to the list of participants of the AddSchedules component---------------------------
  function addPartner(id) {
    let item = state.fillParticipantes.filter(item => item.id == id)[0]
    let participante = {
      id: item.id,
      name: item.name,
    }
    let inseridos = [...state.participantesInseridos, participante]
    setState({ ...state, participantesInseridos: inseridos, nullParticipantes: false })
  }

  // -----------------------------------------Funciton to remove user from list-------------------------------------------------
  function removePartner(id) {
    let inseridos = state.participantesInseridos.filter(item => item.id != id)
    setState({ ...state, participantesInseridos: inseridos })
  }

  const renderTypeUser = (typeUser) => {
    if (typeUser.adm) return 'Admin'
    else if (typeUser.collaborator) return 'Colaborador'
    else if (typeUser.professor) return 'Professor'
    else if (typeUser.student) return 'Aluno'
  }

  // -------------------------------------------------Component return----------------------------------------------------------
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {!loading.element ?
            <div className='d-flex flex-column justify-content-between h-100'>
              <div className="top">
                {/* --------------------------------------------Pagination------------------------------------------------------ */}
                <div className='d-flex'>
                  <Tooltip arrow title='Fechar'>
                    <IconButton onClick={() => setOpen(false)}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                  <div className="align-self-center m-auto">
                    <Pagination count={Math.ceil(state.pagination.total_pages / state.pagination.per_page)} page={state.pageNumber} onChange={(e, page) => setState({ ...state, pageNumber: page })} />
                  </div>
                </div>
                {/* --------------------------------------------Search Input---------------------------------------------------- */}
                <div className="my-5">
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    Selecione os participantes
                  </Typography>
                  <Input className='my-2' value={search} placeholder='Buscar...' fullWidth endAdornment={<SearchIcon />} onChange={({ target }) => {
                    setState({ ...state, pageNumber: 1 })
                    setSearch(target.value)
                  }}></Input>
                </div>
                {/* --------------------------------------------Users table---------------------------------------------------- */}
                <table className='table text-center'>
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">Tipo</th>
                      <th scope="col">Adicionar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.fillParticipantes && !loading.table && state.fillParticipantes.map(item => {
                      let inserted = false
                      state.participantesInseridos.forEach(item2 => {
                        if (item.id == item2.id) inserted = true
                      })
                      console.log('teste', item)
                      return (
                        <tr>
                          <td>{item.name}</td>
                          <td>{renderTypeUser(item.type_user[0])}</td>
                          <td>
                            {!inserted ? <IconButton size='small'>
                              <PlaylistAddIcon size='large' color='success' onClick={() => addPartner(item.id)} />
                            </IconButton>
                              :
                              <IconButton size='small'>
                                <PlaylistAddCheckIcon color='error' onClick={() => removePartner(item.id)} />
                              </IconButton>}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                {loading.table && <div className="d-flex justify-content-center p-5 m-5"><CircularProgress /></div>}
              </div>

              <div className="bottom">
                <Button variant='outlined' startIcon={<ReplyIcon />} onClick={() => setOpen(false)}>Voltar</Button>
              </div>
            </div> : <div className="d-flex justify-content-center p-5 m-5"><CircularProgress /></div>}
        </Box>
      </Fade>
    </Modal>
  )
}

export default ModalPartners