import { Container } from "@mui/system";

const Section100vh = ({
  children,
  className,
  center,
  bg_color = "white",
  bg_image = false,
}) => {
  return (
    <section
      className={className ?? ""}
      style={{
        display: "grid",
        minHeight: "100vh",
        // width: "100vw",
        backgroundColor: bg_color,
        boxShadow: '0 0 0 0.1rem rgba(105, 59, 159, 0.1)',
        // padding: '4rem 1rem',
        ...(center ? { justifyContent: "center", alignItems: "center" } : ""),
        ...(bg_image ? { background: `url(${bg_image}) no-repeat`, backgroundPosition: "center bottom", backgroundSize: `cover`  } : ""),
      }}
    >
        <div style={{ display: "flex", height: "100%", width: "100vw"}}>
          {children}
        </div>
    </section>
  );
};

export default Section100vh;
