import { useState } from 'react';

const useForm = (initialState) => {
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({})

  const handleChange = ({ target }) => {
    const { name, value, files } = target
    setField(name, files ? files[0] : value)
    resetError(name)
  }

  // Identifies errors according to the field value
  const handleBlur = ({ target }) => {
    const { name, value, maxLength, minLength } = target
    const whiteSpace = value.match(/ /g)?.length ?? 0

    if (!value) {
      setError(name, 'Campo em branco')
      return
    }

    if (minLength > 0 && value.length < minLength + whiteSpace) {
      return setError(name, `São necessários ${minLength} caracteres`)
    }

    if (maxLength > 0 && value.length < maxLength) {
      return setError(name, `São necessários ${maxLength} caracteres`)
    }
  }

  // Sets the value of a form field
  const setField = (fieldName, fieldValue) => {
    setForm({
      ...form,
      [fieldName]: fieldValue
    })
  };

  // Sets an error message for a form field
  const setError = (fieldName, errorMessage) => {
    setErrors({
      ...errors,
      [fieldName]: errorMessage
    })
  };

  // Resets the error message for a form field
  const resetError = (fieldName) => {
    setErrors(state => {
      delete state[fieldName]
      return { ...state }
    })
  };

  // Resets the form state and error messages
  const resetForm = () => {
    setForm(initialState);
    setErrors({});
  };

  // Define a function called `isValid` that takes an array of `ignoredFields` as a parameter.
  const validateForm = (ignoreFields) => {
    let hasErrors = true
    for (const [key, value] of Object.entries(form)) {
      if (!value && !ignoreFields.includes(key)) {
        setError(key, 'Campo em branco')
        hasErrors = false
      }
    }

    // if (Object.keys(errors).length !== 0) {
    //   hasErrors = false
    // }

    return hasErrors
  };

  // Handles changes to file input fields and updates the form state with the selected file and its URL
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (!file) {
      return
    }

    generateImageUrl(file, (url) => {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: { value: file, url },
      }));
    });
  };

  // Reads a file using the FileReader API and generates a URL for the file
  const generateImageUrl = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      callback(event.target.result);
    };
    reader.readAsDataURL(file);
  };

  // Validates the type of a form field value against a regular expression and sets an error message if the validation fails
  const validateFieldType = (fieldName, regex, errorMessage) => {
    const fieldValue = form[fieldName];
    if (fieldValue && !fieldValue.match(regex)) {
      setError(fieldName, errorMessage);
      return false;
    }
    return true;
  };


  return {
    form,
    setForm,
    errors,
    setErrors,
    handleChange,
    handleFileChange,
    generateImageUrl,
    setField,
    setError,
    resetError,
    resetForm,
    validateForm,
    validateFieldType,
    handleBlur
  };
};

export default useForm;
