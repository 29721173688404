import Navbar from "../../../components/Navbar";
import bm_world from "../../../assets/static/bm_world.png";
import { Box, CircularProgress, Typography } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HttpsIcon from "@mui/icons-material/Https";
import { useState } from "react";
import { lengthCheck } from "../../../utilities/checkers";
import swal from "sweetalert";
import { post } from "../../../utilities/requests";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../variables";

export default function PrimeiroAcessoPage() {
  let navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const [fetch_loading, setFetchLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [hide_password, setHidePassword] = useState(true);
  const [hide_password2, setHidePassword2] = useState(true);
  const [msgErro, setMsgErro] = useState("");

  const focusInput = (e) => {
    e.currentTarget.lastChild.focus();
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (!lengthCheck(password, 8))
      return swal({
        title: "Erro",
        text: "Senha deve ter no mínimo 8 caracteres.",
        icon: "error",
        button: "Ok",
      });

    if (password !== confirm_password)
      return swal({
        title: "Erro",
        text: "Senhas não coincidem.",
        icon: "error",
        button: "Ok",
      });

    const res = await post(
      `${URL}api/auth/first-access`,
      JSON.stringify({ email: user?.email, password: password, confirm_password: confirm_password })
    );

    if (res.status == true) {
      swal({
        title: "Senha alterada",
        text: "Sua senha foi alterada com sucesso.",
        icon: "success",
        button: "Ok",
      });
      navigate("/painel-aluno");
    } else {
      swal({
        title: "Erro",
        text: res.error[0] || "Erro ao alterar a senha",
        icon: "error",
        button: "Ok",
      });
      return;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "rgb(240, 244, 247)",
        background: `url(${bm_world}) no-repeat`,
        backgroundPositionX: "120%",
        backgroundPositionY: "20rem",
      }}
    >
      <Navbar />

      <Typography
        variant="h5"
        sx={{
          fontFamily: "var(--Raleway)",
          margin: "auto 0",
          marginBottom: "0.2rem",
          // marginTop: '8rem',
          textAlign: "center",
          fontWeight: "bold",
          color: "rgb(70, 70, 70)",
          display: "block" ,
        }}
      >
        Primeiro acesso
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontFamily: "var(--Raleway)",
          margin: "auto 0",
          marginTop: "0",
          marginBottom: 0,
          textAlign: "center",
          color: "rgb(70, 70, 70)",
          display: "block" ,
        }}
      >
        Insira sua nova senha
      </Typography>

      <Box
        sx={{
          display: "flex",
          margin: "auto",
          marginTop: { xs: "2rem", md: "2rem" },
          borderRadius: "1rem",
          overflow: "hidden",
          boxShadow: "0 1rem 2rem rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          padding: "4rem",
        }}
      >
        <form
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          onSubmit={(e) => {
            // handleSubmit();
          }}
        >
          <div
            className="normal-input"
            onClick={(e) => {
              focusInput(e);
            }}
          >
            <HttpsIcon sx={{ color: "rgb(100, 100, 100)" }} />
            <input
              type={hide_password ? "password" : "text"}
              placeholder="Senha"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              error={msgErro}
            />
            {hide_password ? (
              <VisibilityOffIcon
                sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                onClick={() => setHidePassword(false)}
              />
            ) : (
              <VisibilityIcon
                sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                onClick={() => setHidePassword(true)}
              />
            )}
          </div>

          <div
            className="normal-input"
            onClick={(e) => {
              focusInput(e);
            }}
          >
            <HttpsIcon sx={{ color: "rgb(100, 100, 100)" }} />
            <input
              type={hide_password2 ? "password" : "text"}
              placeholder="Confirmar senha"
              value={confirm_password}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              error={msgErro}
            />
            {hide_password2 ? (
              <VisibilityOffIcon
                sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                onClick={() => setHidePassword2(false)}
              />
            ) : (
              <VisibilityIcon
                sx={{ color: "rgb(100, 100, 100)", cursor: "pointer" }}
                onClick={() => setHidePassword2(true)}
              />
            )}
          </div>

          {!fetch_loading ? (
            <input
              type="submit"
              className="form-submit-button"
              style={{
                padding: "1rem 2rem",
                backgroundColor: "var(--purple)",
                border: "none",
                outline: "none",
                color: "white",
                fontFamily: "var(--Raleway)",
                width: "max-content",
                alignSelf: "flex-end",
                borderRadius: "1rem",
                fontSize: "1rem",
              }}
              onClick={handleChangePassword}
              value="Entrar"
            />
          ) : (
            <div
              style={{
                padding: "1rem 2rem",
                backgroundColor: "var(--purple)",
                border: "none",
                outline: "none",
                width: "max-content",
                alignSelf: "flex-end",
                borderRadius: "1rem",
              }}
            >
              <CircularProgress style={{ color: "white" }} size="1.1rem" />
            </div>
          )}
        </form>
      </Box>
    </div>
  );
}
