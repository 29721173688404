import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from 'react-redux';
import { Button, IconButton, Input, ThemeProvider, Pagination, CircularProgress } from '@mui/material';
import { URL } from '../../variables';
import Theme from '../Theme/Theme';
import swal from 'sweetalert';

const ListSubject = () => {
  const [state, setState] = React.useState({
    subjects: [],
    pagination: { current_page: '', total_pages: '', per_page: '' },
    pageNumber: 1,
    loading: true,
    loadingSearch: false,
    reload: false,
    search: '',
  })
  const history = useNavigate();
  const token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    fetch(`${URL}api/subject/list?page=${state.pageNumber}&search=${state.search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    })
    .then(async (result) => {
      const json = await result.json();
      return json;
    })
    .then((json) => {
      console.log('json', json)
      console.log('token', token)
      setState({
        ...state,
        subjects: json.subjects,
        pagination: json.pagination,
        loading: false,
        loadingSearch: false,
      })
    });
  }, [state.pageNumber, state.search, state.reload]);

  function renderAlert(id) {
    return (
      swal({
        title: "Deletar compromisso selecionado?",
        text: "Uma vez deletado, não dará para recuperá-lo!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          swal("Compromisso deletado com sucesso!", {
            icon: "success",
          });
          Delete(id)
          setState({ ...state, pageNumber: 0, reload: !state.reload })
        }
      })
    )
  }

  function Delete(id) {
    fetch(`${URL}api/subject/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    });
    // setState({...state, })
  }

  function EditSubject(id) {
    history('/home/subjects/edit/' + id);
  }

  function ShowInfo(id) {
    history('/home/subjects/subject/' + id);
  }

  function pagination() {
    const { total_pages, per_page } = state.pagination;
    return (
      <div className='d-flex justify-content-end'>
        <Pagination color='primary' shape="rounded" count={Math.ceil(total_pages / per_page)} page={state.pageNumber} onChange={(e, page) => setState({ ...state, pageNumber: page })} />
      </div>
    )
  }

  let timer
  function search(e) {
    clearTimeout(timer);
    timer = setTimeout(() => { setState({ ...state, search: e.target.value, loadingSearch: true }) }, 750);
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className='card-body'>
          <div className="d-flex">
            <div className="flex-column">
              <h1 className='header-title'>Lista de Matérias</h1>
              <Input placeholder='Buscar...' endAdornment={state.loadingSearch ? <CircularProgress size={24} /> : <SearchIcon />} onChange={(e) => search(e)}></Input>
            </div>
            <div className="align-self-end ms-auto">
              <Button classes={{ contained: 'bg-primary-bm' }} variant="contained" size='large' endIcon={<AutoStoriesIcon />} onClick={() => history('/home/subjects/add')}>Adicionar</Button>
            </div>
          </div>
          <table className="table table-striped table-hover text-center mt-5">
            <thead className='text-center'>
              <tr>
                <th scope="col">Foto</th>
                <th scope="col">Nome</th>
                <th scope="col">Descrição</th>
                <th scope="col">Aulas</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            {state.subjects && !state.loading && <tbody>
              {state.subjects.map((dado) => {
                return (
                  <tr key={dado.id}>
                    <td><img className='m-auto' style={{ width: 75, height: 75, borderRadius: '50%' }} src={dado.img ? `${URL}storage/${dado.img}` : `${URL}storage/fotos/user_not_found.png`} alt="subject" /></td>
                    <td>{dado.name}</td>
                    <td>{dado.description}</td>
                    <td>{dado.classes.length}</td>
                    <td>
                      <IconButton size='small'>
                        <EditIcon color='secondary' size='large' onClick={() => EditSubject(dado.id)} />
                      </IconButton>

                      <IconButton size='small'>
                        <VisibilityIcon color='success' onClick={() => ShowInfo(dado.id)} />
                      </IconButton>

                      <IconButton size='small'>
                        <DeleteIcon color='error' onClick={() => renderAlert(dado.id)} />
                      </IconButton>
                    </td>
                  </tr>
                )
              })}
            </tbody>}
          </table>
          {state.loading && <div className="row"> <div className="col-12 p-5 d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"  ></div></div></div>}
          {state.subjects && pagination()}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default ListSubject