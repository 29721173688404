import React from 'react';
import { ThemeProvider } from '@mui/material';
import Theme from '../Theme/Theme';
import ToastContent from '../Toasts/Toasts';

const Container = (props) => {
  return (
    <ThemeProvider theme={Theme}>
      <div className="card m-auto" style={{ maxWidth: 1200 }}>
        <div className="card-body">
          <div className="row">
            <h1 className="title">{props.title}</h1>
            <span className='lead'>{props.subTitle}</span>
          </div>
          <div className="mt-3">
            {props.children}
          </div>
        </div>
      </div>
      <ToastContent />
    </ThemeProvider>
  )
}

export default Container