import { Navigate, Route, Routes } from "react-router-dom";
import { page_data } from "./saiba_mais_data.js";
import ServicosComponent from "../ServicosComponent";

const pages = [
  "/BM-Scripta"
]

const SaibaMais = () => {
  return (
    <Routes>
      {pages.map((page, index) => (
        <Route
          key={index}
          path={page}
          element={<ServicosComponent data={page_data[page]} />}
        />
      ))}

      {/* generic 404 */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default SaibaMais;