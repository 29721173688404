import { HorizontalDropdown, VerticalDropdown } from "../../Dropdown";
import { Category, Item } from "../../Dropdown/DropdownUtils";

// mui icons
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import BusinessIcon from "@mui/icons-material/Business";

const pessoa_fisica = [
  {
    title: "BM-Cursos",
    items: [
      { title: "BM-Kids", href: "bm-kids" },
      { title: "BM-Pro", href: "bm-pro" },
      { title: "BM-Sênior", href: "bm-senior" },
      { title: "BM-Voyage", href: "bm-voyage" },
      { title: "BM-Test", href: "bm-test" },
      { title: "BM-VIP", href: "bm-vip" },
      { title: "BM-Talks", href: "bm-talks" },
    ],
  },
];

const empresa_profissionais = [
  {
    title: "BM-Cursos",
    items: [
      { title: "BM-Business", href: "bm-business" },
      { title: "BM-Voyage", href: "bm-voyage" },
      { title: "BM-Talks", href: "bm-talks" },
    ],
  },
  // {
  //   title: "BM-Trad",
  //   items: [
  //     { title: "BM-Tec" },
  //     { title: "BM-Acad" },
  //     { title: "BM-Net" },
  //     { title: "BM-Localiza" },
  //   ],
  // },
  // {
  //   title: "BM-Rev",
  //   items: [
  //     { title: "BM-Tec" },
  //     { title: "BM-Acad" },
  //     { title: "BM-Net" },
  //     { title: "BM-Normas" },
  //   ],
  // },
];

const ForNaturalPeoplesPATH = "/servicos/para-pessoas-fisicas/";
const ForCompaniesOrProfessionalsPATH =
  "/servicos/para-empresas-ou-profissionais/";

const Servicos = ({ item, index, mobile }) => {
  return (
    <VerticalDropdown
      key={index}
      title={item.text}
      className={"normal-archor purple"}
    >
      <Category title="Para pessoas físicas" icon={<PersonRoundedIcon />}>
        {/* render pessoa_fisica elements with a dropdown */}
        {pessoa_fisica.map((dropdown_data, index) => (
          <HorizontalDropdown
            key={index}
            title={dropdown_data.title}
            dropdownAsItem
          >
            <Item
              title={"O que são?"}
              href={`${ForNaturalPeoplesPATH}bm-cursos/o-que-sao`}
            />
            {dropdown_data.items.map((item, index) => (
              <Item
                key={index}
                title={item.title}
                href={`${ForNaturalPeoplesPATH}${item.href}`}
              />
            ))}
          </HorizontalDropdown>
        ))}
      </Category>
      <Category title="Para empresas ou profissionais" icon={<BusinessIcon />}>
        {/* render empresa_profissionais elements with a dropdown */}
        {empresa_profissionais.map((dropdown_data, index) => (
          <HorizontalDropdown
            key={index}
            title={dropdown_data.title}
            dropdownAsItem
          >
            <Item
              title={"O que são?"}
              href={`${ForCompaniesOrProfessionalsPATH}bm-cursos/o-que-sao`}
            />
            {dropdown_data.items.map((item, index) => (
              <Item
                key={index}
                title={item.title}
                href={`${ForCompaniesOrProfessionalsPATH}${item.href}`}
              />
            ))}
          </HorizontalDropdown>
        ))}

        <HorizontalDropdown title={"BM-Scripta"} dropdownAsItem>
          <Item
            title={"O que é?"}
            href={`${ForCompaniesOrProfessionalsPATH}bm-cursos/saiba-mais/bm-scripta`}
          />
          <Item
            key={index}
            title={"BM-Red"}
            href={`${ForCompaniesOrProfessionalsPATH}bm-red`}
          />
          <Item
            title={"BM-MKT"}
            href={`${ForCompaniesOrProfessionalsPATH}bm-mkt`}
          />
          <Item
            title={"BM-Term"}
            href={`${ForCompaniesOrProfessionalsPATH}bm-term`}
          />
          <Item
            title={"BM-Ação"}
            href={`${ForCompaniesOrProfessionalsPATH}bm-acao`}
          />
        </HorizontalDropdown>

        <Item
          title={"BM-Trad"}
          href={`${ForCompaniesOrProfessionalsPATH}bm-trad`}
        />
        <Item
          title={"BM-Rev"}
          href={`${ForCompaniesOrProfessionalsPATH}bm-rev`}
        />
      </Category>
    </VerticalDropdown>
  );
};

export default Servicos;
