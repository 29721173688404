import React from 'react';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { CircularProgress, IconButton, Input, Pagination, ThemeProvider } from '@mui/material';
import { STORAGE_URL, URL } from '../../variables';
import swal from 'sweetalert';
import Theme from '../Theme/Theme';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ListWorkWithUs = () => {
  const [state, setState] = React.useState({
    work_with_us_list: [],
    pagination: { current_page: '', total_pages: '', per_page: '' },
    pageNumber: 1,
    loading: true,
    loadingSearch: false,
    reload: false,
    msg: false,
    token: '',
    search: '',
  })
  const history = useNavigate();
  state.token = useSelector(state => state.AppReducer.token);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    fetch(`${URL}api/work-with-us/list?page=${state.pageNumber}&search=${state.search}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setState({
          ...state,
          work_with_us_list: json.work.data,
          pagination: json.pagination,
          loading: false,
          loadingSearch: false
        })
      });
  }, [state.pageNumber, state.search, state.reload]);

  function renderWorkWithUsList() {
    return (
      <>
        <tbody>
          {state.work_with_us_list.map((data) => {
            console.log(data)
            // const sizeMsg = Array.from(data.message)
            let msg = ''
            let error = false
            // sizeMsg.forEach((item, index) => {
            //   if (index < 30) msg = msg + item; else error = true
            // })
            return (
              <tr key={data.id}>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.phone}</td>
                <td>{data.linkedin_profile}</td>
                {/* {open && <td>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Mensagem
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {data.message}
                      </Typography>
                    </Box>
                  </Modal>
                </td>} */}
                <td>
                  <IconButton size='large'>
                    <RemoveRedEyeIcon size='large' color='primary' onClick={() => window.open(`${STORAGE_URL}${data.resume}`, '_blank')} />
                  </IconButton>

                  <IconButton size='large'>
                    <DeleteIcon color='error' onClick={() => renderAlert(data.id)} />
                  </IconButton>
                </td>
              </tr>
            )
          })}
        </tbody>
      </>
    )
  }

  function renderAlert(id) {
    return (
      swal({
        title: "Deletar proposta selecionada?",
        text: "Uma vez deletada, não dará para recuperá-la!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          swal("Proposta deletada com sucesso!", {
            icon: "success",
          });
          Delete(id)
          setState({ ...state, pageNumber: 0, reload: !state.reload })
        }
      })
    )
  }

  function Delete(id) {
    try {
      fetch(`${URL}api/work-with-us/delete/${id}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${state.token}`,
          // 'Content-Type': 'application/json',
        }
      });
      // setState({...state, })
    } catch {
      console.log('deu bigode');
    }
  }

  function ReplyMessage(id) {
    history('/home/work-with-us/reply/' + id);
  }

  function pagination() {
    const { total_pages, per_page } = state.pagination;
    return (
      <div className='d-flex justify-content-end'>
        <Pagination color='primary' shape="rounded" count={Math.ceil(total_pages / per_page)} page={state.pageNumber} onChange={(e, page) => setState({ ...state, pageNumber: page })} />
      </div>
    )
  }

  let timer
  function search(e) {
    clearTimeout(timer);
    timer = setTimeout(() => { setState({ ...state, search: e.target.value, loadingSearch: true }) }, 750);
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className='card-body'>
          <div className="d-flex">
            <div className="flex-column">
              <h1 className='header-title'>Lista de propostas</h1>
              <Input placeholder='Buscar...' endAdornment={state.loadingSearch ? <CircularProgress size={24} /> : <SearchIcon />} onChange={(e) => search(e)}></Input>
            </div>
          </div>
          <table className="table table-striped table-hover text-center mt-5">
            <thead className='text-center'>
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">Email</th>
                <th scope="col">Telefone</th>
                <th scope="col">Perfil no LinkedIn</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            {state.work_with_us_list && state.loading == false && renderWorkWithUsList()}
          </table>
          {state.loading && <div className="row"> <div className="col-12 p-5 d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"  ></div></div></div>}
          {state.work_with_us_list && pagination()}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default ListWorkWithUs