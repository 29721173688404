import { Typography } from "@mui/material";
import CardHover from "./CardHover";
import Section100vh from "../../../components/Section100vh";

import LogosObj from "../../../utilities/logos";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
// import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";

// images
import business from "../../../assets/static/business.jpg";
import holding_earth from "../../../assets/static/holding_earth.png";
import holding_lamp from "../../../assets/static/holding_lamp.jpg";
import choices from "../../../assets/static/choices.jpg";

const SobreNosCards = () => {
  const logo = LogosObj.circle.white;

  return (
    <Section100vh
      bg_color={"var(--purple)"}
      custom_style={{ flexDirection: "column" }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", padding: "6rem 0.8rem", width: '100%'}}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: "var(--Freight)",
            maxWidth: "30rem",
            textAlign: "center",
            margin: "0 auto 4rem auto",
            color: "white",
          }}
        >
          Conte com a BM!
        </Typography>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4rem",
            justifyContent: "center",
            maxWidth: "1200px",
            margin: '0 auto'
          }}
        >
          <CardHover
            icon={logo}
            title={"O que é a BM Words?"}
            text={
              "A BM Words é uma assessoria que surgiu com o principal objetivo de revolucionar os serviços linguísticos no mercado. A empresa tem, portanto, o papel de ser uma ponte sólida e segura entre as necessidades de nossos clientes e uma performance linguística de sucesso."
            }
            bg_image={business}
          />
          <CardHover
            mui_icon={ThumbUpAltOutlinedIcon}
            title={"Por que nos escolher?"}
            text={
              "Nossa equipe é composta por Mestres, Doutores e Pós-Doutores em Linguística formados em renomadas universidades do Brasil e do exterior. Nossa formação e experiência de mercado acumuladas há mais de 10 anos nos colocam à frente em matéria de serviços relacionados à linguagem."
            }
            bg_image={choices}
          />
          <CardHover
            mui_icon={VolunteerActivismIcon}
            title={"Qual é a nossa missão?"}
            text={
              "É aperfeiçoar a formação de nossos clientes em termos de performance linguística. É mostrar que as palavras (em língua materna ou estrangeira) consistem no ingrediente fundamental para a receita de seu sucesso profissional e pessoal."
            }
            bg_image={holding_earth}
          />
          <CardHover
            mui_icon={StarBorderOutlinedIcon}
            title={"Qual é o nosso diferencial?"}
            text={
              "Nossa formação nos permite aplicar no mercado um conhecimento científico em Linguística consolidado e nossa proposta transforma serviços relacionados a idiomas em assessorias personalizadas, acompanhando o desenvolvimento de nossos clientes de perto e adequando o nosso trabalho às necessidades deles."
            }
            bg_image={holding_lamp}
          />
        </div>
      </div>
    </Section100vh>
  );
};

export default SobreNosCards;
