import Section100vh from "../../../components/Section100vh";

import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";

// background
import people_talking from "../../../assets/static/people_talking.svg";
// import landing_page_bg from "../../../assets/static/landing_page_bg.png";
import landing_page_bg from "../../../assets/static/landing_page_bg_v2.png";


const LandingPage = () => {
  const [bg_image_size, Setbg_image_size] = useState("80%");

  useEffect(() => {
    function handleWindowResize() {
      if (window.innerWidth < 768) {
        Setbg_image_size("200%");
      } else {
        Setbg_image_size("80%");
      }
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Section100vh bg_image={landing_page_bg}>
      {/* bg_image_size={bg_image_size} */}
      <Container
        maxWidth="xl"
        sx={{
          height: "100%",
          width: "100%",
          paddingTop: { xs: "20vh", sm: "30vh", md: "14vh" },
          color: "var(--purple)",
          textAlign: "center",
          gap: "2rem",
          display: "flex",
          position: "relative",
          flexDirection: "column",
          // backgroundColor: { xs: 'white', sm: 'gray', md: 'red', xl: 'blue' },
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontSize: { xs: "2.8rem", md: "4.2rem" },
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
          }}
        >
          Aqui, você é a nossa prioridade.
        </Typography>

        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            fontSize: { xs: "1rem", md: "1.4rem" },
            fontFamily: "var(--Raleway)",
            color: "black",
            maxWidth: '80rem',
            margin: '0 auto',
            position: 'relative',
            zIndex: 4,
            backdropFilter: 'blur(2rem)',
          }}
        >
          A BM Words é uma empresa especializada em serviços de assessoria linguística, como revisões, traduções, marketing linguístico, cursos de idiomas, dentre outros.
        </Typography>
        {/* <img src={people_talking} alt="Pessoas conversando" style={{
          position: 'absolute',
          bottom: 0,
          // width: bg_image_size,
          width: {
            xs: '60%',
            sm: '80%',
            md: '100%',
          },
          left: '50%',
          transform: 'translateX(-50%)',
        }} /> */}

        <Box
          component="img"
          src={people_talking}
          alt="Pessoas conversando" 
          sx={{
            position: 'absolute',
            bottom: 0,
            // width: bg_image_size,
            height: '50%',
            left: '50%',
            transform: 'translateX(-50%)',
          }} 
        />
      </Container>
    </Section100vh>
  );
};

export default LandingPage;
