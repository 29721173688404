import { Skeleton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Card from "../Card";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { URL } from "../../../variables";
import { get } from "../../../utilities/requests";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import RenderTags from "./RenderTags";

import empty from "../../../assets/static/empty.svg";

const BlogPage = () => {
  const [posts, setPosts] = useState(['loading']);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch(`${URL}api/blog/list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // 'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        setPosts(json.blog.data || []);
        setIsLoading(false);
      });
  }, []);

  const renderSkeleton = () => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          marginTop: "1rem",
          justifyContent: "space-between",
          opacity: 0.5,
        }}
      >
        {/* main card */}
        {/* <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "100%", height: "16rem" }}
        /> */}

        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
        <Skeleton
          variant="rectangular"
          // animation="wave"
          sx={{ borderRadius: "0.6rem", width: "30rem", height: "20rem" }}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(240, 244, 247)",
        width: "100vw",
        minHeight: "100vh",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "6rem 0 4rem 0",
          minHeight: "100vh",
        }}
      >
        {/* render all tags */}
        <div>
          <Typography
            variant="h5"
            sx={{
              color: "rgb(70, 70, 70)",
              alignItems: "center",
              display: "flex",
              marginBottom: "1rem",
              gap: "0.5rem",
              padding: {
                xs: "0 1rem",
                sm: "0",
              }
            }}
          >
            <FiberManualRecordIcon sx={{ color: "var(--purple)" }} /> Explorar
          </Typography>
          <RenderTags />
        </div>

        <Typography
          variant="h5"
          sx={{
            color: "rgb(70, 70, 70)",
            alignItems: "center",
            marginTop: "2rem",
            display: "flex",
            gap: "0.5rem",
            padding: {
              xs: "0 1rem",
              sm: "0",
            }
          }}
        >
          <FiberManualRecordIcon sx={{ color: "var(--purple)" }} /> Mais recente
        </Typography>

        {!isLoading ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1rem",
              marginTop: "1rem",
              // justifyContent: "space-between",
            }}
          >
            {/* {posts && posts[0] !== 'loading' && posts[0] && <Card {...posts[0]} firstCard />} */}
            {posts &&
              posts?.map((post, index) => {
                if (posts[0] === 'loading') return renderSkeleton();
                // if (index === 0) return null;
                return <Card {...post} />;
              })}
            {posts?.length === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  height: "100vh",
                  width: "100vw",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  backgroundColor: "rgb(240, 244, 247)",
                  zIndex: 2,
                  padding: "0 1rem",
                }}
              >
                <img
                  src={empty}
                  alt="empty"
                  style={{
                    width: "60%",
                    maxWidth: "22rem",
                    display: "block",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h3"
                  sx={{
                    color: "rgb(70, 70, 70)",
                    fontWeight: "bold",
                    fontFamily: "var(--Raleway)",
                    textAlign: "center",
                    fontSize: {
                      xs: "2rem",
                      sm: "2.5rem",
                      md: "3.2rem",
                    }
                  }}
                >
                  Por hora, não há nada aqui.
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "rgb(70, 70, 70)",
                    fontFamily: "var(--Raleway)",
                    textAlign: "center",
                  }}
                >
                  Mas fique ligado porque em breve teremos novidades!
                </Typography>
              </div>
            )}
          </div>
        ) : (
          renderSkeleton()
        )}
      </Container>
    </div>
  );
};

export default BlogPage;
