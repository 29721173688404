import { Route, Routes, Navigate } from "react-router-dom";

// custom components
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ShowAlert from "../../components/ShowAlert";

// pages
import Home from "../../pages/Home";
import SobreNosPage from "../../pages/SobreNos";
import ContatoPage from "../../pages/Contato";
import PoliticaPrivacidadePage from "../../pages/PoliticaPrivacidade";
import TermosUsoPage from "../../pages/TermosUso";
import Schedules from "../Schedule/Schedules";
import ServicosPage from "../../pages/Servicos";
import TrabalheConoscoPage from "../../pages/TrabalheConosco";
// blog
  import BrowseBlog from "../../pages/Blog/Browse";
  import ReadBlog from "../../pages/Blog/Read";
import BMeBookStorePage from "../../pages/BMeBookStore";
import FacaUmaDenuncia from "../../pages/Denuncia";

const GuestRoutes = () => {
  return (
    <>
      <Navbar />

      <Routes>
        {/* index */}
        <Route path={"/*"} element={<Home />} />

        {/* secondary pages */}
        <Route path={"/sobre-nos"} element={<SobreNosPage />} />

        <Route path={"/servicos/*"} element={<ServicosPage />} />

        <Route path={"/contato"} element={<ContatoPage />} />

        <Route path={"/politica-de-privacidade"} element={<PoliticaPrivacidadePage />} />

        <Route path={"/termos-de-uso"} element={<TermosUsoPage />} />

        <Route path={"/trabalhe-conosco"} element={<TrabalheConoscoPage />} />

        {/* blog */}
        <Route path={"/blog/navegar"} element={<BrowseBlog />} />
        <Route path={"/blog/ler/:id"} element={<ReadBlog />} />

        <Route path={"/bm-ebook-store"} element={<BMeBookStorePage />} />

        <Route path={"/faca-uma-denuncia"} element={<FacaUmaDenuncia />} />

        {/* generic 404 */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Footer />
    </>
  );
};

export default GuestRoutes;
