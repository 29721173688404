import React from 'react';
import { TextField as InputMui, ThemeProvider } from '@mui/material';
import Theme from '../Theme/Theme';

const Input = ({ item, form, setForm, disabled }) => {
  return (
    <ThemeProvider theme={Theme}>
      <InputMui fullWidth className='mb-5' label={form[item].label} type={item} value={form[item].value} disabled={disabled} onChange={(e) => setForm({ ...form, [item]: { ...form[item], value: e.target.value, error: false } })} error={form[item].error} />
    </ThemeProvider>
  )
}

export default Input