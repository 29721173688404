import { Alert } from "@mui/material";
import { useEffect, useState } from "react";

const ShowAlert = ({ message, type }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [destroyAlert, setDestroyAlert] = useState(false);

  const handleDestroyAlert = () => {
    setShowAlert(false);
    setTimeout(() => {
      setDestroyAlert(true);
    }, 700);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(true);
    }, 1000);

    setTimeout(() => {
      handleDestroyAlert();
    }, 10000);
  }, []);


  return (
    !destroyAlert && (
      <Alert
        severity={type}
        onClose={handleDestroyAlert}
        sx={{
          position: "absolute",
          bottom: "4rem",
          height: "max-content",
          top: showAlert ? '10vh' : '-10rem',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 100,
          opacity: showAlert ? 1 : 0,
          transition: "opacity 600ms ease, top 800ms ease",
        }}
      >
        <b>{message}</b>
      </Alert>
    )
  );
};

export default ShowAlert;
