import { Box } from "@mui/material";
import Section100vh from "../../components/Section100vh";
import ContatoForm from "./ContatoForm";

const Contato = () => {
  return (
    <Section100vh bg_color="#f0f4f7">
      <Box
        sx={{
          display: "flex",
          margin: "auto",
          width: { xs: "100%", md: '80%', lg: "70%" },
        }}
      >
        <ContatoForm />
      </Box>
    </Section100vh>
  );
};

export default Contato;
