import { IconButton, Pagination } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { GET_DELETE_FETCH, URL } from '../../variables'
import Container from '../Form/Container'
import ListButton from '../Form/ListButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom'
import { Alert, notification } from '../Toasts/Toasts'

const ListEbooks = () => {
  const [state, setState] = React.useState({
    search: '',
    ebooks: [],
    pagination: {},
    pageNumber: 1,
    loadingSearch: false,
    loading: true,
    reload: false,
  })
  const token = useSelector(state => state.AppReducer.token)
  const history = useNavigate()

  React.useEffect(() => {
    async function getData() {
      const resp = await GET_DELETE_FETCH({ url: `${URL}api/ebook/list?page=${state.pageNumber}&search=${state.search}`, token, method: 'GET' })
      setState({ ...state, ebooks: resp.ebooks, pagination: resp.pagination, loadingSearch: false, loading: false })
    }
    getData()
  }, [state.search, state.reload])

  async function DeleteEbook(id) {
    const resp = await GET_DELETE_FETCH({ url: `${URL}api/ebook/delete/${id}`, token, method: 'DELETE' })
    if (resp.status) {
      setState({ ...state, reload: !state.reload })
    } else {
      notification({ erro: 'Ouve um erro ao deletar E-book', type: 'warning' })
    }
  }

  console.log('pagination', state.pagination)

  return (
    <Container>
      <ListButton path='/home/ebooks/add' title='Lista de E-Books' state={state} setState={(e) => setState(e)} />
      <table className="table table-striped table-hover text-center mt-5">
        <thead className='text-center'>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Autor</th>
            <th scope="col">Descrição</th>
            <th scope="col">Dias</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        {state.ebooks && !state.loading && <tbody>
          {state.ebooks.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.author}</td>
                <td>{item.description}</td>
                <td>{item.days}</td>
                <td>
                  <IconButton size='small' onClick={() => history(`/home/ebooks/edit/${item.id}`)}>
                    <EditIcon color='secondary' size='large' />
                  </IconButton>

                  {/* <IconButton size='small'>
                    <VisibilityIcon color='success' onClick={() => ShowInfo(item.id)} />
                  </IconButton> */}

                  <IconButton size='small' onClick={() => Alert({ id: item.id, state: state, setState: (e) => setState(e), Delete: (e) => DeleteEbook(e) })}>
                    <DeleteIcon color='error' />
                  </IconButton>
                </td>
              </tr>
            )
          })}
        </tbody>}
      </table>
      {state.loading && <div className="row"> <div className="col-12 p-5 d-flex justify-content-center align-items-center"><div className="spinner-border text-primary" role="status"  ></div></div></div>}
      {state.ebooks &&
        <div className='d-flex justify-content-end'>
          <Pagination color='primary' shape="rounded" count={Math.ceil(state.pagination.total_pages / state.pagination.per_page)} page={state.pageNumber} onChange={(e, page) => setState({ ...state, pageNumber: page })} />
        </div>}
    </Container>
  )
}

export default ListEbooks