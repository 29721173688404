import { Container, Icon, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Card from "../Card";

// mui icons
import LinkIcon from "@mui/icons-material/Link";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import ShareIcon from "@mui/icons-material/Share";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useParams } from "react-router-dom";
import { STORAGE_URL, THIS_URL, URL } from "../../../variables";
import { get } from "../../../utilities/requests";
import LoadingDots from "../../../components/LoadingDots";

const ReadBlog = () => {
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [background_image_path, setBackground_image_path] = useState("");
  const [tags, setTags] = useState("");
  const [created_at, setCreated_at] = useState("");
  const [author, setAuthor] = useState("");

  const [all_posts, setAll_posts] = useState([]);

  const [clipboard_button_icon, setClipboard_button_icon] = useState(LinkIcon);
  const [readyToRender, setReadyToRender] = useState(false);

  const params = useParams();
  const id = params.id;

  const getFormattedDate = (date) => {
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "numeric" });
    const day = d.getDate();
    const year = d.getFullYear();
    return `${day}/${month}/${year} às ${d.getHours()}:${d.getMinutes()}`;
  };

  const URL_TO_SHARE = `${THIS_URL}/blog/ler${id}`;

  const HandleCopyClipboard = () => {
    navigator.clipboard.writeText(URL_TO_SHARE).then(
      () => {
        setClipboard_button_icon(CheckRoundedIcon);
      },
      function (err) {
        console.error("Erro ao copiar o link. Erro: ", err);
        setClipboard_button_icon(ErrorOutlineRoundedIcon);
      }
    );

    setTimeout(() => {
      setClipboard_button_icon(LinkIcon);
    }, 1500);
  };

  const gettoOnlyOnePost = async () => {
    fetch(`${URL}api/blog/reply/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // 'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setTitle(res.post.title);
        setSummary(res.post.summary);
        setContent(res.post.content);
        setBackground_image_path(res.post.background_image_path);
        setTags(res.post.tags);
        setCreated_at(res.post.created_at);
        setAuthor(res.post.author);

        setReadyToRender(true);
      });
  };

  const gettoAllPosts = async () => {
    fetch(`${URL}api/blog/list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // 'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setAll_posts(res.blog.data);
      });
  };

  useEffect(() => {
    gettoAllPosts();
    gettoOnlyOnePost();
  }, []);

  // this function returns a limited number of posts in cards
  const renderMorePosts = () => {
    let posts = [];
    for (let i = 0; i <= 3; i++) {
      if (all_posts[i] !== undefined) {
        if (all_posts[i]["id"] !== id) {
          posts.push(<Card key={i} {...all_posts[i]} />);
        }
      }
    }
    return posts;
  };

  return readyToRender ? (
    <div
      style={{
        backgroundColor: "rgb(240, 244, 247)",
        width: "100vw",
        minHeight: "100vh",
        animation: "show_section 1s",
        position: "relative",
        padding: {
          xs: "0 1rem",
          sm: "0",
        }
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40vh",
        }}
      >
        <img src={STORAGE_URL+background_image_path} alt="Capa" style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }} />
      </div>
      <Container
        maxWidth="xl"
        sx={{
          display: "grid",
          gridTemplateColumns: "auto max-content",
          gap: "1rem",
          padding: "2rem 0 4rem 0",
        }}
      >
        <section style={{ fontFamily: "var(--Raleway)!important" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <h1
              style={{
                fontFamily: "var(--Freight)",
              }}
            >
              <b>{title}</b>
            </h1>
            <Typography variant="h6" sx={{ color: "rgb(0, 0, 0, 0.6)" }}>
              {summary}
            </Typography>

            <div>
              <span>
                <b>Por {author}, para a BM Words</b>
              </span>
              {" • "}
              <span>{getFormattedDate(created_at)}</span>
            </div>

            <div
              style={{
                display: "flex",
                gap: "1.2rem",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f3f3f3",
                  borderRadius: "0.4rem",
                  width: "max-content",
                  padding: "0.4rem",
                  marginBottom: "1rem",
                  backgroundColor: "rgb(220, 220, 220)",
                  display: "flex",
                  gap: "0.8rem",
                  cursor: "pointer",
                  maxWidth: "9.8rem",
                  overflow: "hidden",
                  transition: "500ms 1s ease",
                  "&:hover": {
                    transition: "500ms ease",
                    maxWidth: "26rem",
                  },
                }}
              >
                <ShareIcon />
                <span>
                  <b>Compartilhar</b>
                </span>
                <Tooltip title="Copiar link" arrow>
                  <button
                    style={{
                      cursor: "pointer",
                      border: "0",
                      backgroundColor: "transparent",
                    }}
                    onClick={HandleCopyClipboard}
                  >
                    <Icon
                      component={clipboard_button_icon}
                      sx={{
                        "&:hover": {
                          color: "var(--purple)",
                        },
                      }}
                    />
                  </button>
                </Tooltip>
                <Tooltip title="Whatsapp" arrow>
                  <button
                    style={{
                      cursor: "pointer",
                      border: "0",
                      backgroundColor: "transparent",
                    }}
                    onClick={() =>
                      window.open(
                        `https://web.whatsapp.com/send?text=*${title}* ${URL_TO_SHARE}`,
                        "_blank"
                      )
                    }
                  >
                    <Icon
                      component={WhatsAppIcon}
                      sx={{
                        "&:hover": {
                          color: "var(--purple)",
                        },
                      }}
                    />
                  </button>
                </Tooltip>
                <Tooltip title="Twitter" arrow>
                  <button
                    style={{
                      cursor: "pointer",
                      border: "0",
                      backgroundColor: "transparent",
                    }}
                    onClick={() =>
                      window.open(
                        `https://twitter.com/share?url=${title} ${URL_TO_SHARE}`,
                        "_blank"
                      )
                    }
                  >
                    <Icon
                      component={TwitterIcon}
                      sx={{
                        "&:hover": {
                          color: "var(--purple)",
                        },
                      }}
                    />
                  </button>
                </Tooltip>
                <Tooltip title="Facebook" arrow>
                  <button
                    style={{
                      cursor: "pointer",
                      border: "0",
                      backgroundColor: "transparent",
                    }}
                    onClick={() =>
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${URL_TO_SHARE}%3Futm_source%3Dfacebook%26utm_medium%3Dsocial%26utm_campaign%3Dcompfb`,
                        "_blank"
                      )
                    }
                  >
                    <Icon
                      component={FacebookIcon}
                      sx={{
                        "&:hover": {
                          color: "var(--purple)",
                        },
                      }}
                    />
                  </button>
                </Tooltip>
              </Box>

              {tags?.map((tag, index) => (
                <span
                  key={index}
                  style={{
                    backgroundColor: "#f3f3f3",
                    borderRadius: "0.4rem",
                    padding: "0.4rem",
                    marginBottom: "1rem",
                    backgroundColor: "rgb(220, 220, 220)",
                    fontWeight: "bold",
                  }}
                >
                  <span
                    style={{
                      color: "rgb(150, 150, 150)",
                      fontWeight: "normal",
                    }}
                  >
                    <b>#</b>
                  </span>
                  {tag.label}
                </span>
              ))}
            </div>
          </div>
          <hr
            style={{
              width: "100%",
              border: "1px solid var(--purple)",
              margin: "0",
              marginBottom: "1rem",
            }}
          />
          <Box dangerouslySetInnerHTML={{ __html: content }} />
        </section>

        <Box
          sx={{
            display: { xs: "none", md: "none", lg: "block" },
          }}
        >
          <h4
            style={{
              fontFamily: "var(--Raleway)",
              alignItems: "center",
              display: "flex",
              gap: "0.5rem",
            }}
          >
            <FiberManualRecordIcon sx={{ color: "var(--purple)" }} />
            Mais notícias
          </h4>
          {renderMorePosts()}
        </Box>
      </Container>

      <Box
        sx={{
          display: { xs: "block", md: "block", lg: "none" },
          // backgroundColor: "var(--purple)",
          width: "max-content",
          margin: "4rem auto",
        }}
      >
        <h4
          style={{
            fontFamily: "var(--Raleway)",
            alignItems: "center",
            display: "flex",
            gap: "0.5rem",
          }}
        >
          <FiberManualRecordIcon sx={{ color: "var(--purple)" }} />
          Mais notícias
        </h4>
        {renderMorePosts()}
      </Box>
    </div>
  ) : (
    <div
      style={{
        backgroundColor: "rgb(240, 244, 247)",
        minWidth: "100vw",
        minHeight: "100vh",
      }}
    >
      <LoadingDots />
    </div>
  );
};

export default ReadBlog;
