import React from 'react'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { GET_DELETE_FETCH, URL } from '../../variables'
import AddEbook from './AddEbook'

const EditEbook = () => {
  const params = useParams()
  const [data, setData] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    async function getData() {
      let resp = await GET_DELETE_FETCH({ url: `${URL}api/ebook/edit/${params.id}`, token, method: 'GET' })
      setData(resp.ebook)
      setLoading(false)
    }
    getData()
  }, [params.id, token]);
  return (
    <>
      <div className="d-flex justify-content-center">
        {loading && <CircularProgress color="secondary" />}
      </div>
      {!loading && <AddEbook edit={data} />}
    </>
  )
}

export default EditEbook