import { useEffect, useRef, useState } from "react";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const Collapse = (props) => {
  const { question, answer } = props;
  console.log("props", props);

  const ref = useRef(null);

  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref.current.scrollHeight);
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f6f6f6",
        boxShadow: "0 0 0 0.1rem #e4dcec",
        borderRadius: "0.6rem",
        width: "100%",
        transition: `250ms ease`,
        opacity: 1,
        "&:active": {
          transition: `0ms ease`,
          transform: "scale(0.99)",
          opacity: 0.6,
        },
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          padding: "0.8rem",
          fontWeight: "bold",
        }}
        onClick={handleClick}
      >
        {question}

        <Box
          sx={{
            backgroundColor: "#ededed",
            padding: {
              xs: "0.2rem 0.4rem",
              sm: "0.2rem 0.4rem 0.2rem 0.8rem",
            },
            borderRadius: "0.4rem",
            display: "flex",
          }}
        >
          <Box
            sx={{
              color: "rgb(90, 90, 90)",
              fontWeight: "normal",
              display: {
                xs: "none",
                sm: "block",
              },
              whiteSpace: "nowrap",
            }}
          >
            {open ? "esconder resposta" : "mostrar resposta"}
          </Box>
          <ExpandLessIcon
            sx={{
              transition: `400ms ease`,
              ...(open && { transform: "rotate(180deg)" }),
            }}
          />
        </Box>
      </div>

      <div
        ref={ref}
        style={{
          transition: "1s ease",
          maxHeight: open ? height + 32 + 150 : 0,
          backgroundColor: "#ededed",
          overflow: "hidden",
          padding: open ? "16px" : "0 16px",
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
        }}
      >
        {answer.map((item, index) =>
          item.renderAsComponent ? (
            item.data
          ) : (
            <Typography
              key={index}
              variant="subtitle1"
              sx={{ fontFamily: "var(--Raleway)" }}
            >
              {item.data}
            </Typography>
          )
          // if (item.renderAsComponent) {
          //   return (item.data)
          // } else {
          //   return (
          //     <Typography key={index} variant="subtitle1" sx={{fontFamily: "var(--Raleway)",}}>{item.data}</Typography>
          //   )
          // }
        )}
      </div>
    </Box>
  );
};

export default Collapse;
