// the content of the Serviços route pages will be stored here and later rendered.
// use renderAsComponent=true to render a component

import { Typography } from "@mui/material";
import Collapse from "./ServicosComponent/Collapse";

export const page_data = {
  "/BM-Business": {
    question: "Sobre o BM-Business:",
    answer: [
      {
        data: "O curso BM-Business é direcionado para quem tem um objetivo bastante específico para aprender inglês, francês ou português e que está diretamente relacionado ao seu trabalho. Por exemplo, se você quiser aprender a terminologia da sua área de trabalho e precisar saber se comunicar de forma oral ou escrita no contexto empresarial em uma dessas línguas, todas as atividades serão preparadas com esse foco.",
      },
      {
        data: "Desse modo, a assessoria oferecida no curso BM-Business é mais especializada e conta com o apoio de terminólogos da equipe da BM Words formados em universidades de grande renome no Brasil e no exterior.",
      },
      {
        data: "O curso BM-Business é oferecido individualmente ou para grupos de até seis pessoas e pode acontecer de forma on-line.",
      },
      // {
      //   renderAsComponent: true,
      //   data: (
      //     <ul>
      //       {" "}
      //       <li>BM-Kids I, voltado para crianças de 3 a 7 anos;</li>{" "}
      //       <li>BM-Kids II, direcionado para crianças de 8 a 12 anos.</li>{" "}
      //     </ul>
      //   ),
      // },
    ],
  },
  "/BM-Voyage": {
    question: "Sobre o BM-Voyage:",
    answer: [
      {
        data: "O curso de idiomas BM-Voyage é voltado especialmente para quem vai realizar uma viagem de negócios para o exterior. Esse curso prepara nosso(a)s clientes para saberem se comunicar em situações que envolvam tanto situações de rotina (aeroportos, restaurantes e hotéis, por exemplo) quanto reuniões, eventos, visitas técnicas, dentre outras. É, nesse sentido, uma imersão na língua do país em que as atividades de trabalho serão realizadas com um enfoque na linguagem dos negócios.",
      },
      {
        data: "O curso BM-Voyage é oferecido individualmente ou para grupos de até seis pessoas e pode acontecer de forma on-line.",
      },
    ],
  },
  "/BM-Talks": {
    question: "Sobre o BM-Talks:",
    answer: [
      {
        data: "O curso BM-Talks é voltado para quem deseja desenvolver a competência de conversação, bem como preparar apresentações que devem ser realizadas em empresas, congressos, dentre outros. Oferecido em inglês, francês ou português, esse curso segue uma metodologia criada pela BM Words e única no mercado. Nosso principal objetivo é atender às necessidades desse público-alvo no que diz respeito à prática oral da língua estudada em diversos contextos.",
      },
      {
        data: "O curso BM-Talks é oferecido individualmente ou para grupos de até seis pessoas e pode acontecer de forma on-line.",
      },
    ],
  },
  "/BM-Trad": {
    question: "Sobre o BM-Trad:",
    answer: [
      {
        data: "A assessoria BM-Trad consiste em um serviço de tradução (passar o texto em uma língua estrangeira para o português) e de versão (trazer o texto em português para uma língua estrangeira). Esse trabalho pode ser realizado nos idiomas inglês e francês.",
      },
      {
        data: "A equipe da BM Words tem experiência em traduzir textos em diversos formatos, incluindo sites, artigos científicos, apresentações, manuais, relatórios, dentre outros.",
      },
      {
        data: "⮚ Entre em contato conosco para solucionarmos suas dúvidas com relação à assessoria BM-Trad e fazermos um orçamento para você.",
      },
    ],
  },
  "/BM-Rev": {
    question: "Sobre o BM-Rev:",
    answer: [
      {
        data: "A assessoria BM-Rev é um serviço de revisão de textos em português, inglês e francês. Esse trabalho é oferecido para quem deseja que seus textos sejam revisados de modo a aperfeiçoá-los do ponto de vista de regras gramaticais e normas técnicas, tais como ABNT e APA, por exemplo, dentre outros aspectos.",
      },
      {
        data: "A equipe da BM Words tem experiência em revisar textos em diversos formatos, incluindo sites, artigos científicos, apresentações, manuais, relatórios, dentre outros documentos.",
      },
      {
        data: "⮚ Entre em contato conosco para solucionarmos suas dúvidas com relação à assessoria BM-Rev e fazermos um orçamento para você.",
      },
    ],
  },
  "/BM-Scripta": {
    question: "Sobre o BM-Scripta:",
    answer: [
      {
        data: "A assessoria BM-Scripta é um serviço que oferece orientações de escrita em inglês, francês ou português. Neste trabalho, nossos assessores auxiliam nosso(a) cliente a redigir textos que apresentam coesão e coerência, que tenham a organização textual adequada ao seu gênero, que exponham suas ideias do modo mais claro possível, dentre outros aspectos.",
      },
      {
        data: "A equipe da BM Words é formada por Mestres, Doutores e Pós-Doutores em Linguística com vasta experiência em redigir textos nos mais diversos tipos de gêneros. Dessa forma, a assessoria BM-Scripta fornece orientações de técnicas de escrita voltadas para relatórios, documentos empresariais, dentre outros.",
      },
      {
        data: "⮚ Entre em contato conosco para solucionarmos suas dúvidas e fazermos um orçamento para você.",
      },
      {
        renderAsComponent: true,
        data: (
          <Typography variant="h6" sx={{ color: "var(--purple)" }}>
            Veja também:{" "}
            <a
              href="bm-cursos/saiba-mais/bm-scripta"
              className="normal-archor purple"
              style={{ display: "inline" }}
            >
              O que é BM-Scripta? - Saiba mais
            </a>
          </Typography>
        ),
      },
    ],
  },
  "/BM-Term": {
    question: "Sobre o BM-Term:",
    answer: [
      {
        data: "A assessoria BM-Term é um serviço que busca melhorar a comunicação especializada dentro de empresas e entre empresas/profissionais e seus clientes, promovendo a acessibilidade terminológica de seu público-alvo. Nossos assessores, que são terminólogos especialistas em diferentes áreas técnicas e que têm Mestrado, Doutorado e Pós-Doutorado em Terminologia pelas melhores universidades do Brasil e do exterior, buscarão entender as necessidades terminológicas dos funcionários de sua empresa e de seus clientes e atuarão de modo a melhorar a comunicação empresarial nos ambientes interno e externo.",
      },
      {
        data: "⮚ Entre em contato conosco para solucionarmos suas dúvidas com relação à assessoria BM-Term e fazermos um orçamento para você.",
      },
    ],
  },
  "/BM-MKT": {
    question: "Sobre o BM-MKT:",
    answer: [
      {
        data: "A assessoria BM-MKT é oferecida para empresas ou profissionais que desejam divulgar seus produtos, serviços e conteúdos em sites, blogs e redes sociais, seguindo os princípios do Marketing Digital e do Marketing de Conteúdo. O marketing linguístico é essencial para que os objetivos de seu conteúdo compartilhado na web sejam atingidos e para que as empresas possam captar clientes para o seu negócio e/ou vender seus produtos e seus serviços.",
      },
      {
        data: "A assessoria BM-MKT compreende duas modalidades que envolvem:",
      },
      {
        renderAsComponent: true,
        data: (
          <ul style={{ fontFamily: "var(--Raleway)" }}>
            {" "}
            <li>
              <Typography
                variant="subtitle1"
                sx={{ fontFamily: "var(--Raleway)", textAlign: "justify" }}
              >
                Treinamentos personalizados focados em posicionamento no
                digital, comunicação escrita e falada, dentre outros aspectos,
                para que o cliente aprenda a produzir seu próprio conteúdo de
                forma estratégica.
              </Typography>
            </li>{" "}
            <li>
              <Typography
                variant="subtitle1"
                sx={{ fontFamily: "var(--Raleway)", textAlign: "justify" }}
              >
                Assessorias completas com nossa equipe especialista em social
                media, por meio das quais oferecemos orientações que ajudam
                empresas e profissionais a conseguirem se posicionar
                adequadamente no digital, a serem assertivos em sua performance
                linguística nesse contexto, bem como realizamos seu marketing de
                conteúdo completo de forma estratégica, planejamos lançamentos,
                montamos o copywriting necessário, dentre outras tarefas
                relacionadas.
              </Typography>
            </li>{" "}
          </ul>
        ),
      },
      {
        data: "⮚ Entre em contato conosco para solucionarmos suas dúvidas com relação à assessoria BM-MKT e fazermos um orçamento para você.",
      },
    ],
  },
  "/BM-Acao": {
    question: "Sobre o BM-Ação:",
    answer: [
      {
        data: "A assessoria BM-Ação é um serviço direcionado para clientes que necessitem de orientações com relação ao seu comportamento linguístico em contextos variados. Neste trabalho, nossos assessores auxiliam nosso(a) cliente a estar apto(a) para realizar uma boa comunicação oral, de modo que ele(a) consiga transmitir suas ideias com calma e clareza, compreender e ser compreendido(a), e estar atento(a) à sua postura de linguagem em diferentes situações comunicacionais, tais como entrevistas, atendimento por telefone, reuniões, e-mails, redes sociais, dentre outras.",
      },
      {
        data: "Assim, a assessoria BM-Ação ensina técnicas de comunicação, que envolvem tanto conhecimentos sobre a linguagem quanto noções de psicologia comportamental e do contexto em que o(a) cliente atua.",
      },
      {
        data: "⮚ Entre em contato conosco para solucionarmos suas dúvidas e fazermos um orçamento para você.",
      },
    ],
  },
  "/BM-Red": {
    question: "Sobre o BM-Red:",
    answer: [
      {
        data: "A assessoria BM-Red é um serviço que oferece orientações de escrita em inglês, francês ou português. Neste trabalho, nossos assessores auxiliam nosso(a) cliente a redigir textos que apresentem coesão e coerência, que tenham a organização textual adequada ao seu gênero, que exponham suas ideias do modo mais claro possível, dentre outros aspectos.",
      },
      {
        data: "A equipe da BM Words é formada por Mestres, Doutores e Pós-Doutores em Linguística com vasta experiência em redigir textos nos mais diversos tipos de gêneros. Dessa forma, a assessoria BM-Red fornece orientações de técnicas de escrita voltadas para relatórios, documentos empresariais, dentre outros.",
      },
      {
        data: "⮚ Entre em contato conosco para solucionarmos suas dúvidas e fazermos um orçamento para você.",
      },
    ],
  },
};
