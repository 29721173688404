import LockIcon from '@mui/icons-material/Lock';
import { useState } from "react";
import { Typography } from "@mui/material";

const AuthCode = ({ section_index, code, setCode }) => {

  // foca o input da div
  const focusInput = (e) => {
    e.currentTarget.lastChild.focus();
  }

  return (
    <div
      style={{
        display: section_index === 1 ? "flex" : "none",
        flexDirection: "column",
        gap: "1rem",
        top: "50%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Typography
          variant="h6"
          sx={{
            fontFamily: "var(--Raleway)",
            margin: "auto 0",
            marginTop: "0",
            marginBottom: 0,
            textAlign: "center",
            color: "rgb(70, 70, 70)",
            display: { xs: "block", md: "block" },
            maxWidth: '30rem',
          }}
        >
          Insira o código de verificação que foi enviado para o e-mail inserido anteriormente.
        </Typography>

      <form style={{ display: "flex", flexDirection: "column", gap: "1rem", maxWidth: '30rem', width: '100%'}}>
        <div
          className="normal-input"
          onClick={(e) => {
            focusInput(e);
          }}
        >
          <LockIcon sx={{ color: "rgb(100, 100, 100)" }} />
          <input
            type="text"
            placeholder="Código de verificação"
            value={code}
            maxLength={6}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
};

export default AuthCode;
