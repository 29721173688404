import { Box, Container } from "@mui/material";
import LogosObj from "../../utilities/logos";
import LinkBtn from "../../components/LinkBtn";

// mui icons
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const footer_links = {
  "sobre-nos": { title: "Sobre nós", behavior: "_self" },
  servicos: { title: "Serviços", behavior: "_self" },
  'blog/navegar': { title: "Blog", behavior: "_self" },
  contato: { title: "Contato", behavior: "_self" },
  "trabalhe-conosco": { title: "Trabalhe conosco", behavior: "_self" },
  "politica-de-privacidade": {
    title: "Política de privacidade",
    behavior: "_self",
  },
  "termos-de-uso": { title: "Termos de uso", behavior: "_self" },
  "faca-uma-denuncia": { title: "Faça uma denúncia", behavior: "_self" },
};

const social_medias_button_style = {
  fontSize: "2.1rem",
  opacity: "0.6",
  cursor: "pointer",
  color: 'white',
  "&:hover": { opacity: 1 },
};

const social_medias = [
  {
    link: "https://www.instagram.com/bm.words/",
    icon: <InstagramIcon sx={social_medias_button_style} />,
  },
  {
    link: "https://www.facebook.com/bmwords.com.br",
    icon: <FacebookIcon sx={social_medias_button_style} />,
  },
  {
    link: "https://www.linkedin.com/company/bmwords/about/?viewAsMember=true",
    icon: <LinkedInIcon sx={social_medias_button_style} />,
  },
];

const Footer = () => {
  const logo = LogosObj.main.white;

  return (
    <footer style={{ backgroundColor: "var(--purple)", padding: "4rem 0" }}>
      <Container
        maxWidth="lg"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {/* <div>
          <a href="/">
            <img style={{width: '10rem'}} src={logo} alt="BM Words logotipo" />
          </a>
          <div style={{display: 'flex', }}>

          </div>
        </div> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            height: { md: "10rem", xs: "auto" },
            gap: "0.2rem",
            // transform: { md: "translateX(60%)", xs: "auto" },
          }}
        >
          {Object.keys(footer_links).map((key) => (
            <span key={key} style={{ marginLeft: "1.4rem" }}>
              <LinkBtn
                href_content={"/" + key}
                archor_content={footer_links[key].title}
                archor_target={footer_links[key].behavior}
                archor_type={'white'}
                style={{
                  fontSize: {
                    xs: "0.9rem",
                    md: "1rem",
                  },
                }}
              />
            </span>
          ))}
        </Box>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <a href="/">
            <img
              style={{ width: "10rem" }}
              src={logo}
              alt="BM Words logotipo"
            />
          </a>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
            }}
          >
            {social_medias.map((social_media, index) => (
              <a key={index} href={social_media.link} target={"_blank"}>
                {social_media.icon}
              </a>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
