import { Box, Typography } from "@mui/material";

import "./index.modules.css";

const Card = ({ title, text, bg_image, to_right=false }) => {
  const image_element = (
    <div>
      <img
        className={'custom-card-image ' + `${to_right ? "to-right" : ""}`}
        src={bg_image}
        alt="Fundo do cartão"
      />
    </div>
  );

  return (
    <Box className="custom-card" sx={{
      padding: {
        xs: '1rem',
        sm: '2rem',
        md: '4rem 6rem',
      }
    }}>
      {to_right ? null : image_element}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          zIndex: 3
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            textAlign: "justify",
            fontFamily: "var(--Raleway)",
            color: "white",
            fontSize: "1.2rem",
          }}
          variant="p"
        >
          {text}
        </Typography>
      </div>

      {to_right ? image_element : null}
    </Box>
  );
};

export default Card;
