import React from 'react'
import { FormControlLabel, Switch as SwitchMui } from '@mui/material';

const Switch = ({ item, perm, setPerm }) => {
  function teste() {
    const keys = Object.keys(perm)
    let perm2 = { ...perm }
    keys.forEach(item2 => {
      if (item != item2) {
        perm2[item2] = false
      } else {
        perm2[item2] = !perm2[item]
      }
    })
    setPerm(perm2)
  }

  return (
    <FormControlLabel control={<SwitchMui checked={perm[item]} onChange={() => teste()} />} label={item} />
  )
}

export default Switch