import React, { useRef } from "react";
import { CircularProgress, Icon, Typography } from "@mui/material";
import { URL } from "../../variables";
import ShowAlert from "../../components/ShowAlert";

// icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import work_with_us from "../../assets/static/work_with_us.svg";

// local css
import "./index.css";
import { Box } from "@mui/system";
import { useEffect } from "react";

const TrabalheConosco = () => {
  const [form, setForm] = React.useState({
    name: { value: "", error: false },
    email: { value: "", error: false },
    linkedin_profile: { value: "", error: false },
    phone: { value: "", error: false },
  });

  const [file, setFile] = React.useState(null);
  const [fileError, setFileError] = React.useState(false);
  const [formError, setFormError] = React.useState('');

  const ref = useRef(null);

  const [formSize, setFormSize] = React.useState(0);

  const [submitted, setSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // foca o input da div
  const focusInput = (e) => {
    e.currentTarget.lastChild.focus();
  };

  const phoneMaskBrazil = (e) => {
    var element = e.target;
    var isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    var inputValue = element.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
    inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

    setForm({ ...form, phone: { value: inputValue, error: false } });
  };

  async function storeNews(e) {
    e.preventDefault();
    setIsLoading(true);

    for (const [key, value] of Object.entries(form)) {
      if (value.value === "") {
        return setForm({ ...form, [key]: { value: value.value, error: true } });
      } else {
        setForm({ ...form, [key]: { value: value.value, error: false } });
      }

      if (file === null || file === undefined) {
        return setFileError(true);
      } else {
        setFileError(false);
      }
    }

    const formData = new FormData();
    formData.append("name", form.name.value);
    formData.append("email", form.email.value);
    formData.append("phone", form.phone.value);
    formData.append("linkedin_profile", form.linkedin_profile.value);
    formData.append("resume", file);
    console.log(...formData);
    let result = "";
    try {
      result = await fetch(`${URL}api/work-with-us/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          // 'Authorization': `Bearer ${this.props.token}`,
        },
        body: formData,
      }).then((response) => {
        if (response.status === 200) {
          let keys = Object.keys(form);
          let form2 = { ...form };
          keys.forEach((item) => {
            form2[item].value = "";
          });
          setForm(form2);
          setFormSize(ref.current.offsetWidth);
          setTimeout(() => {
            setSubmitted(true);
          }, 1000);
        } else if (response.status === 413) {
          throw new Error(
            "O arquivo é muito grande"
          );
        } else {
          setSubmitted(null);
        }
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
      setFormError(error.message);
      setTimeout(() => {
        setFormError('');
      }, 5000);
    }
  }

  useEffect(() => {
    setFileError(false);
  }, [file]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f4f7",
        width: { xs: "100%", md: '80%', lg: "70%" },
        margin: "0 auto",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontFamily: "var(--Raleway)",
          marginBottom: "2rem",
          textAlign: "center",
          fontWeight: "bold",
          color: "var(--purple)",
          display: { xs: "none", md: "block" },
        }}
      >
        Venha fazer parte do time BM!
      </Typography>
      
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          borderRadius: "1rem",
          overflow: "hidden",
          marginTop: { xs: "6rem", md: 0 },
          boxShadow: { xs: "none", md: "0 1rem 2rem rgba(0, 0, 0, 0.1)" },
          flexWrap: { xs: "wrap", md: "nowrap" },
          width: '100%',
        }}
      >
        {submitted && (
          <ShowAlert
            message="Sua mensagem foi enviada com sucesso! Em breve, falaremos com você."
            type="success"
          />
        )}
        {/* {submitted === null && (
          <ShowAlert message="Erro ao enviar o formulário." type="error" />
        )} */}
        {formError !== '' && formError !== null && (
          <ShowAlert message={`Erro ao enviar o formulário. ` + formError} type="error" />
        )}
        <Box
          sx={{
            backgroundColor: "var(--purple)",
            color: "white",
            padding: {
              xs: "4rem 1rem",
              md: "4rem",
            },
            gap: {
              xs: "4rem",
              md: "1rem",
            },
            display: "flex",
            width: { xs: "100%!important" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Typography
              variant="h3"
              sx={{ fontFamily: "var(--Freight)", fontWeight: "bold" }}
            >
              #BeMoreanywhere
            </Typography>
            {/* {submitted ? (
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "var(--Raleway)",
                  color: "rgb(210, 210, 210)",
                  width: { xs: "100%", md: "30rem" },
                  marginBottom: "0.6rem",
                }}
              >
                Agradecemos o seu contato. Sua mensagem será respondida em
                breve. Enquanto isso, que tal acompanhar nossas redes sociais?
                #BeMoreanywhere
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "var(--Raleway)",
                  color: "rgb(210, 210, 210)",
                  width: { xs: "100%", md: "30rem" },
                  marginBottom: "0.6rem",
                }}
              >
                Envie-nos uma mensagem e entraremos em contato com você.
              </Typography>
            )} */}
          </div>
          <img
            src={work_with_us}
            style={{ objectFit: "contain", maxWidth: "22rem" }}
          />
        </Box>

        <Box
          ref={ref}
          sx={{
            backgroundColor: "white",
            padding: {
              xs: "1rem",
              md: "4rem",
            },
            width: { xs: "100%!important", md: "max-content" },
            overflow: "hidden",
            opacity: 1,
            maxWidth: formSize === 0 ? "auto" : formSize,
            transition: "1s ease",
            ...(submitted && {
              maxWidth: "0px",
              padding: "4rem 0",
              opacity: 0,
            }),
          }}
        >
          <form
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            onSubmit={(e) => storeNews(e)}
          >
            <div
              className="normal-input"
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <PersonIcon sx={{ color: "rgb(100, 100, 100)" }} />
              <input
                id="teste"
                type="text"
                placeholder="Nome completo"
                value={form.name.value}
                onChange={(e) =>
                  setForm({ ...form, name: { value: e.target.value } })
                }
                error={form.name.error}
                required
              />
            </div>

            <div
              className="normal-input"
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <EmailIcon sx={{ color: "rgb(100, 100, 100)" }} />
              <input
                id="teste"
                type="email"
                placeholder="E-mail"
                value={form.email.value}
                onChange={(e) =>
                  setForm({ ...form, email: { value: e.target.value } })
                }
                required
              />
            </div>

            <div
              className="normal-input"
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <LocalPhoneIcon sx={{ color: "rgb(100, 100, 100)" }} />
              <input
                maxLength="15"
                type="text"
                placeholder="Telefone"
                value={form.phone.value}
                onChange={(e) => {
                  setForm({ ...form, phone: { value: e.target.value } });
                  phoneMaskBrazil(e);
                }}
                required
              />
            </div>

            <div
              className="normal-input"
              onClick={(e) => {
                focusInput(e);
              }}
            >
              <LinkedInIcon sx={{ color: "rgb(100, 100, 100)" }} />
              <input
                id="teste"
                type="text"
                placeholder="Link do seu perfil LinkedIn"
                value={form.linkedin_profile.value}
                onChange={(e) =>
                  setForm({
                    ...form,
                    linkedin_profile: { value: e.target.value },
                  })
                }
                error={form.linkedin_profile.error}
                required
              />
            </div>

            <div>
              <label
                htmlFor="input-resume-file"
                className="normal-input"
                style={{
                  cursor: "pointer",
                  backgroundColor: fileError ? "rgba(255, 0,0,0.2)" : "#f0f4f7",
                }}
              >
                {file ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <DescriptionRoundedIcon
                      sx={{ color: "rgb(100, 100, 100)" }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--Raleway)",
                        color: "rgb(100, 100, 100)",
                      }}
                    >
                      {file.name}
                    </Typography>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <DescriptionRoundedIcon
                      sx={{ color: "rgb(100, 100, 100)" }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--Raleway)",
                        color: "rgb(100, 100, 100)",
                      }}
                    >
                      Clique para anexar o currículo
                    </Typography>
                  </div>
                )}
                <input
                  hidden
                  type="file"
                  name="upload"
                  id="input-resume-file"
                  accept="application/pdf,application/vnd.ms-excel"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </label>
            </div>

            {!isLoading ? (
              <input
                type="submit"
                className="form-submit-button"
                style={{
                  padding: "1rem 2rem",
                  backgroundColor: "var(--purple)",
                  border: "none",
                  outline: "none",
                  color: "white",
                  fontFamily: "var(--Raleway)",
                  width: "max-content",
                  alignSelf: "flex-end",
                  borderRadius: "1rem",
                  fontSize: "1rem",
                }}
              />
            ) : (
              <div
                style={{
                  padding: "1rem 2rem",
                  backgroundColor: "var(--purple)",
                  border: "none",
                  outline: "none",
                  width: "max-content",
                  alignSelf: "flex-end",
                  borderRadius: "1rem",
                }}
              >
                <CircularProgress style={{color: 'white'}} size="1.1rem" />
              </div>
            )}
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default TrabalheConosco;
