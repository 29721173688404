import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Button, ThemeProvider } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import React from "react";
import Input from "./Input";
import Switch from "./Switch";
import validator from "validator";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { URL } from "../../variables";
import { useNavigate } from "react-router-dom";
import Theme from "../Theme/Theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const AddUser = ({ edit }) => {
  const [form, setForm] = React.useState({
    name: { label: "Nome*", value: "", error: false },
    email: { label: "Email*", value: "", error: false },
    phone: { label: "Telefone", value: "", error: false },
    password: { label: "Senha*", value: "", error: false },
  });
  const [perm, setPerm] = React.useState({
    ADM: true,
    Professor: false,
    Colaborador: false,
    Aluno: false,
  });
  const [state, setState] = React.useState({
    token: "",
  });
  const [random_password, setRandomPassword] = React.useState(false);
  const [file, setFile] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const history = useNavigate();
  state.token = useSelector((state) => state.AppReducer.token);

  React.useEffect(() => {
    if (edit) {
      setForm({
        ...form,
        name: { ...form.name, value: edit.user.name },
        email: { ...form.email, value: edit.user.email },
        phone: { ...form.phone, value: edit.user.phone },
      });
      console.log(edit.user);
      if (edit.user.type_user != undefined) {
        console.log('all perms', edit.user.type_user[0]);
        setPerm({
          ADM: edit.user.type_user[0].adm,
          Professor: edit.user.type_user[0].professor,
          Colaborador: edit.user.type_user[0].collaborator,
          Aluno: edit.user.type_user[0].student,
        });
      }
    }
  }, []);

  async function addUser(type) {
    const keys = Object.keys(form);
    let form2 = { ...form };
    keys.forEach((item) => {
      console.log(item);
      if (item === "password" && random_password) {
        if (form2[item].value == "") form2[item].error = false;
        return;
      }

      if (form2[item].value == "") form2[item].error = true;
    });
    if (validator.isEmail(form2.email.value)) {
      form2.email.error = false;
    } else {
      form2.email.error = true;
    }
    setForm(form2);

    console.log("form", form2);

    if (!form2.name.error && !form2.email.error && !form2.password.error) {
      const formData = new FormData();
      formData.append("name", form.name.value);
      formData.append("email", form.email.value);
      formData.append("phone", form.phone.value);
      if (!random_password) {
        formData.append("password", form.password.value);
      }
      // formData.append("file_path", file);

      formData.append("adm", perm.ADM);
      formData.append("prof", perm.Professor);
      formData.append("colab", perm.Colaborador);
      formData.append("student", perm.Aluno);
      if (edit) formData.append("id", edit.user.id);
      console.log("formdata", formData);

      const new_type = type == "add" ? "new-user/add" : "user/update";
      let result = await fetch(`${URL}api/${new_type}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
        body: formData,
      });
      renderInput();
      history("/home/list");
    }
  }

  function renderInput() {
    const keys = Object.keys(form);
    return keys.map((item) => {
      return (
        <Input
          key={item.label}
          disabled={item == "password" && random_password ? true : false}
          item={item}
          form={form}
          setForm={(e) => setForm(e)}
        />
      );
    });
  }

  function renderSwitch() {
    const keys = Object.keys(perm);

    console.log('perm', perm);
    return keys.map((item, index) => {
      return (
        <Switch
          key={index}
          item={item}
          perm={perm}
          setPerm={(e) => setPerm(e)}
        />
      );
    });
  }

  return (
    <ThemeProvider theme={Theme}>
      <div className="card">
        <div className="card-body">
          <div className="row p-sm-5">
            <div className="col-12 col-md-8 m-auto">
              <h1 className="title mb-5">CRIAR NOVO USUÁRIO</h1>
              {renderInput()}

              <div className="form-check mb-5" hidden={edit ? true : false}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={() => setRandomPassword(!random_password)}
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Senha aleatória
                </label>
              </div>
              <div className="d-flex mb-5">
                {/* <div className="me-3">
                  <h1 className="lead">
                    <strong>Imagem</strong>
                  </h1>
                  <Button
                    variant="contained"
                    component="label"
                    endIcon={<PhotoCamera />}
                  >
                    {file ? file.name : "Arquivo de Imagem"}
                    <input
                      hidden
                      onChange={(e) => setFile(e.target.files[0])}
                      accept="image/*"
                      multiple
                      type="file"
                    />
                  </Button>
                  <span
                    style={{
                      padding: "0.2rem 0.4rem",
                      borderRadius: "0.4rem",
                      backgroundColor: "#f1f1f1",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.4rem",
                      width: "max-content",
                      marginTop: "0.8rem",
                    }}
                  >
                    <ErrorOutlineIcon sx={{ color: "var(--purple)" }} />{" "}
                    <b>Proporção de tela recomendada:</b> 1:1
                  </span>
                </div> */}
                {edit && (
                  <div className="ms-auto">
                    <h1 className="lead">
                      <strong>Permissões</strong>
                    </h1>
                    {renderSwitch()}
                  </div>
                )}
              </div>
              <div className="d-flex mt-5">
                <div className="align-self-center">
                  <Button
                    variant="outlined"
                    onClick={() => history("/home/list")}
                    startIcon={<ReplyAllIcon />}
                  >
                    {" "}
                    Voltar
                  </Button>
                </div>
                <div className="align-self-center ms-auto">
                  <LoadingButton
                    loading={saving}
                    onClick={() => (edit ? addUser("update") : addUser("add"))}
                    loadingPosition="end"
                    endIcon={<SaveIcon />}
                    variant="outlined"
                  >
                    Salvar
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AddUser;
