import React from 'react';
import { FormControl, InputLabel, Input as InputMui, TextField, Select, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const Input = ({ item, form, setForm, fill }) => {
  // ---------------------Verificação de cada tipo de input de acordo com o item pasado no componente pai, exemplo de uso: 
  //em um state para os valores de formulário é passado um objeto com vários outros objetos dentro, ex:
  // {agenda: {id: '', label: '', value: '', type: ''}}
  //onde ao passar o componente de input, é esperado o seu nome(nesse caso 'agenda'), o state em que se encontra(form nesse
  //caso), e a função de atualizar o state(setForm nesse caso)
  //então será buscado o form[item] que seria igual a form.agenda e assim passando seus respectivos valores como label ou value
  //-----------------------
  if (form[item].type == 'text') {
    return (
      <div className="col-6">
        <FormControl fullWidth>
          <InputLabel htmlFor='id'>{form[item].label}</InputLabel>
          <InputMui id='id' error={form[item].error} value={form[item].value} onChange={(e) => setForm({ ...form, [item]: { ...form[item], value: e.target.value, error: false } })}></InputMui>
        </FormControl>
      </div>
    )
  } else if (form[item].type == 'multiline') {
    return (
      <div className="col-12 my-3">
        <TextField fullWidth multiline rows={1} error={form[item].error} label={form[item].label} value={form[item].value} onChange={(e) => setForm({ ...form, [item]: { ...form[item], value: e.target.value, error: false } })}></TextField>
      </div>
    )
  } else if (form[item].type == 'select') {
    return (
      <FormControl variant="standard" className='mx-3' fullWidth>
        <InputLabel htmlFor='id'>{form[item].label}</InputLabel>
        <Select id='id' error={form[item].error} label={form[item].label} value={form[item].id} onChange={(e) => {
          setForm({ ...form, [item]: { ...form[item], id: e.target.value, value: fill.filter(item2 => item2.id == e.target.value)[0].name, error: false } })
        }}>{fill.map(item => (<MenuItem value={item.id}>{item.name}</MenuItem>))}</Select>
      </FormControl>
    )
  } else if (form[item].type == 'time') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticTimePicker
          label={form[item].label}
          value={form[item].value}
          onChange={(newValue) => {
            setForm({ ...form, [item]: { ...form[item], value: newValue } });
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
    )
  }
}

export default Input