import { useState } from "react";
import { FaArrowLeft, FaArrowRight, FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../../components/Navbar";
import { emailCheck, lengthCheck } from "../../../utilities/checkers";
import GetEmail from "./Sections/GetEmail";
import AuthCode from "./Sections/AuthCode";
import NewPassword from "./Sections/NewPassword";
import CustomizedSteppers from "./Stepper";
import bm_world from "../../../assets/static/bm_world.png";
import Footer from "../../../components/Footer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import swal from "sweetalert";
import { post } from "../../../utilities/requests";
import { URL } from "../../../variables";

const ForgotPassword = () => {
  let navigate = useNavigate();

  const [section_index, setSectionIndex] = useState(0);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  const handleChangePassword = async () => {
    if (!lengthCheck(password, 8))
      return swal({
        title: "Erro",
        text: "Senha deve ter no mínimo 8 caracteres.",
        icon: "error",
        button: "Ok",
      });

    if (password !== password_confirmation)
      return swal({
        title: "Erro",
        text: "Senhas não coincidem.",
        icon: "error",
        button: "Ok",
      });

    const res = await post(
      URL + "api/forgot-password/update-password",
      JSON.stringify({ email: email, password: password, password_confirmation: password_confirmation, auth_code: code })
    );

    if (res.status == true) {
      swal({
        title: "Senha alterada",
        text: "Sua senha foi alterada com sucesso.",
        icon: "success",
        button: "Ok",
      });
      navigate("/login");
    } else {
      swal({
        title: "Erro",
        text: res.error[0] || "Erro ao alterar a senha",
        icon: "error",
        button: "Ok",
      });
      return;
    }
  };

  const handleSectionChange = async (index) => {
    if (index < 0 || index > 2) return;

    console.log(index);

    if (index === 1 && section_index === 0) {
      if (!emailCheck(email))
        return swal({
          title: "Erro",
          text: "E-mail inválido.",
          icon: "error",
          button: "Ok",
        });

      const res = await post(
        URL + "api/forgot-password/check-email",
        JSON.stringify({ email: email })
      );

      if (res.status == true) {
        const res = await post(
          URL + "api/forgot-password/create_code",
          JSON.stringify({ email: email })
        );
        if (res.status == true) {
          swal({
            title: "Código de verificação enviado",
            text: "Verifique sua caixa de entrada e digite o código de verificação",
            icon: "success",
            button: "Ok",
          });
        } else {
          swal({
            title: "Erro",
            text: res.error[0] || "Erro ao enviar o código",
            icon: "error",
            button: "Ok",
          });
          return;
        }
      } else {
        swal({
          title: "Erro",
          text: "E-mail não encontrado.",
          icon: "error",
          button: "Ok",
        });
        return;
      }
    }

    if (index === 2 && section_index === 1) {
      const res = await post(
        URL + "api/forgot-password/check-code",
        JSON.stringify({ email: email, auth_code: code })
      );

      if (res.status == true) {
        swal({
          title: "Verificação concluída",
          text: "Digite uma nova senha",
          icon: "success",
          button: "Ok",
        });
      } else {
        swal({
          title: "Erro",
          text: res.error[0],
          icon: "error",
          button: "Ok",
        });
        return;
      }
    }
    setSectionIndex(index);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "rgb(240, 244, 247)",
        paddingTop: "6rem",
      }}
    >
      <Navbar />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          paddingBottom: "4rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            backgroundColor: "white",
            padding: "4rem 2rem",
            borderRadius: "1rem",
            maxWidth: "50rem",
            width: "100%",
          }}
        >
          <CustomizedSteppers {...{ section_index }} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              // onSubmit={handleSubmit}
              style={{
                position: "relative",
                width: "100%",
                marginTop: "4rem",
              }}
            >
              <GetEmail
                {...{
                  section_index,

                  email,
                  setEmail,
                }}
              />

              <AuthCode
                {...{
                  section_index,

                  code,
                  setCode,
                }}
              />

              <NewPassword
                {...{
                  section_index,

                  password,
                  setPassword,
                  password_confirmation,
                  setPasswordConfirmation,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent:
                  section_index === 0 ? "flex-end" : "space-between",
                marginTop: "1rem",
                maxWidth: "30rem",
                width: "100%",
                margin: "2rem auto 0 auto",
              }}
            >
              <button
                onClick={() => handleSectionChange(section_index - 1)}
                style={{
                  padding: "1rem 2rem",
                  paddingLeft: "1.6rem",
                  backgroundColor: "var(--purple)",
                  border: "none",
                  outline: "none",
                  color: "white",
                  fontFamily: "var(--Raleway)",
                  width: "max-content",
                  alignSelf: "flex-end",
                  borderRadius: "1rem",
                  fontSize: "1rem",
                  display: section_index === 0 ? "none" : "flex",
                }}
              >
                <ArrowBackIcon style={{ marginRight: "0.5rem" }} />

                <span style={{ zIndex: 2, position: "relative" }}>Voltar</span>
              </button>

              <button
                onClick={() =>
                  section_index === 2
                    ? handleChangePassword()
                    : handleSectionChange(section_index + 1)
                }
                style={{
                  padding: "1rem 2rem",
                  paddingRight: "1.6rem",
                  backgroundColor: "var(--purple)",
                  border: "none",
                  outline: "none",
                  color: "white",
                  fontFamily: "var(--Raleway)",
                  width: "max-content",
                  alignSelf: "flex-end",
                  borderRadius: "1rem",
                  fontSize: "1rem",
                }}
              >
                <span style={{ zIndex: 2, position: "relative" }}>
                  {section_index === 0
                    ? "Próximo"
                    : section_index === 1
                    ? "Próximo"
                    : "Finalizar"}
                </span>

                <ArrowForwardIcon style={{ marginLeft: "0.5rem" }} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Link to={"/login"}>
          <button
            className={`Transition-1 ${styles.customButton}`}
            style={{
              margin: "0 auto",
              marginTop: "1rem",
            }}
            on
          >
            <div className={`${styles.cardFlipEffect} Transition-1`} />

            <FaUserPlus
              style={{
                marginRight: "0.6rem",
                display: "flex",
                zIndex: 2,
              }}
              className="Transition-1"
            />

            <span style={{ zIndex: 2, position: "relative" }}>Faça login</span>
          </button>
        </Link> */}

      <Footer />
    </div>
  );
};

export default ForgotPassword;
