import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

const SaveButton = (props) => {
  const history = useNavigate();

  SaveButton.defaultProps = {
    path: '',
    loading: false,
    disabled: false,
    saveCallback: null,
  };

  return (
    <div className="d-flex mt-5">
      <div className="align-self-center">
        <Button
          variant="outlined"
          onClick={() => history(props.path)}
          startIcon={<ReplyAllIcon />}>
          Voltar
        </Button>
      </div>
      <div className="align-self-center ms-auto">
        <LoadingButton
          disabled={props.disabled}
          loadingPosition="end"
          loading={props.loading}
          endIcon={<SaveIcon />}
          type={!props.saveCallback ? 'submit' : 'button'}
          onClick={() => props.saveCallback && props.saveCallback()}
          variant="outlined">
          Salvar
        </LoadingButton>
      </div>
    </div>
  )
}

export default SaveButton