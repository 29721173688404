import { Container, Typography } from "@mui/material";
import React from "react";
import OwlCarousel from "react-owl-carousel";

import logo1 from "../../../assets/bm_clients_logo/es.png";
import logo2 from "../../../assets/bm_clients_logo/testeon.png";
import logo3 from "../../../assets/bm_clients_logo/nexuscare.png";
import logo4 from "../../../assets/bm_clients_logo/santarita.svg";
import logo5 from "../../../assets/bm_clients_logo/guilherme.png";

const responsive = {
  0:{
    items: 2
  },
  480:{
    items: 3
  },
  769:{
    items: 4
  },
  1024:{
    items: 5
  },
  1025:{
    items: 6
  }
}

class Clientes extends React.Component {
  render() {
    return (
      <Container
        maxWidth="xl"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.4rem",
          margin: "auto",
          padding: "4rem 0",
          width: "100vw",
        }}
      >
        <Typography
          variant="h3"
          component="div"
          sx={{
            fontFamily: "var(--Freight)",
            fontWeight: "bold",
            alignSelf: "center",
          }}
        >
          Nossos clientes
        </Typography>

        <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: "var(--Raleway)",
            alignSelf: "center",
            textAlign: "center",
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "40px",
              borderTop: "2px solid #444",
              margin: "30px auto 0",
              marginBottom: "2rem",
            },
          }}
        >
          Conheça alguns de nossos clientes.
        </Typography>

        <OwlCarousel
          className="owl-theme"
          loop
          dots={false}
          nav={false}
          items={5}
          autoplay={true}
          autoplayTimeout={2000}
          responsive={responsive}
        >
          <div className="item">
            <img style={{ height: "4rem" }} src={logo1} />
          </div>
          <div className="item">
            <img style={{ height: "4rem" }} src={logo2} />
          </div>
          <div className="item">
            <img style={{ height: "4rem" }} src={logo3} />
          </div>
          <div className="item">
            <img style={{ height: "4rem" }} src={logo4} />
          </div>
          <div className="item">
            <img style={{ height: "4rem" }} src={logo5} />
          </div>
        </OwlCarousel>
      </Container>
    );
  }
}

export default Clientes;
