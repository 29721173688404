import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import BusinessIcon from "@mui/icons-material/Business";
import { Box, Typography } from "@mui/material";
import { Category, Item } from "../../../components/Dropdown/DropdownUtils";

// imgs
// import natural_person from "../../../assets/static/student.jpg";
// import companies_or_professionals from "../../../assets/static/executive.jpg";

const ForNaturalPeoplesPATH = "/servicos/para-pessoas-fisicas/";
const ForCompaniesOrProfessionalsPATH =
  "/servicos/para-empresas-ou-profissionais/";

const pessoa_fisica = [
  {
    title: "BM-Cursos",
    items: [
      { title: "BM-Kids", href: "bm-kids" },
      { title: "BM-Pro", href: "bm-pro" },
      { title: "BM-Sênior", href: "bm-senior" },
      { title: "BM-Voyage", href: "bm-voyage" },
      { title: "BM-Test", href: "bm-test" },
      { title: "BM-VIP", href: "bm-vip" },
      { title: "BM-Talks", href: "bm-talks" },
    ],
  },
];

const empresa_profissionais = [
  {
    title: "BM-Cursos",
    items: [
      { title: "BM-Business", href: "bm-business" },
      { title: "BM-Voyage", href: "bm-voyage" },
      { title: "BM-Talks", href: "bm-talks" },
    ],
    info: (
      <div>
        <Typography
          variant="h6"
          sx={{ marginBottom: "1rem", color: "rgb(30, 30, 30)" }}
        >
          <BusinessIcon /> O que é um BM-Curso?
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "rgb(60, 60, 60)", textAlign: "justify" }}
        >
          Os BM-Cursos são cursos de idioma preparados de uma forma única para
          você. Isso porque eles são organizados de um modo diferente do que
          existe atualmente no mercado. Nesse sentido, nosso(a)s clientes têm,
          além de aulas preparadas especialmente para ele(a)s, um acompanhamento
          semanal feito por assessores da equipe BM Words.
          <a
            href={`${ForCompaniesOrProfessionalsPATH}bm-cursos/o-que-sao`}
            className="normal-archor purple"
            style={{ fontSize: "1rem" }}
          >
            Saiba mais
          </a>
        </Typography>
      </div>
    ),
  },
  {
    title: "BM-Scripta",
    items: [
      { title: "BM-Red", href: "bm-red" },
      { title: "BM-MKT", href: "bm-mkt" },
      { title: "BM-Term", href: "bm-term" },
      { title: "BM-Ação", href: "bm-acao" },
    ],
    info: (
      <div>
        <Typography
          variant="h6"
          sx={{ marginBottom: "1rem", color: "rgb(30, 30, 30)" }}
        >
          <BusinessIcon /> O que é BM-Scripta?
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "rgb(60, 60, 60)", textAlign: "justify" }}
        >
          O BM-Scripta consiste em uma modalidade de serviços prestados pela BM
          Words que compreende assessorias voltadas à prática escrita e falada
          em diferentes contextos.
          <a
            href={`${ForCompaniesOrProfessionalsPATH}bm-cursos/saiba-mais/bm-scripta`}
            className="normal-archor purple"
            style={{ fontSize: "1rem" }}
          >
            Saiba mais
          </a>
        </Typography>
      </div>
    ),
  },
];

const General = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f0f4f7",
        display: "flex",
        height: "max-content",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "8rem 0 4rem 0",
        width: "100vw",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          top: "6rem",
          zIndex: 5,
          color: "var(--purple)",
          fontFamily: "var(--Raleway)",
          fontWeight: "normal",
          marginBottom: "2rem",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Nossos cursos estão divididos em duas categorias:
      </Typography>
      <Box
        sx={{
          maxWidth: "2280px",
          gap: "1rem",
          display: "flex",
          flexDirection: { xs: "column", md: "row", lg: "row" },
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.2rem",
            minWidth: "26rem",
            flexBasis: "max-content",
            justifyContent: "center",
            // background: "var(--purple)",
            padding: "2rem 2.8rem",
            borderRadius: "0.8rem",
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              color: "var(--purple)",
              textAlign: "center",
            }}
          >
            <PersonRoundedIcon sx={{ marginRight: "0.4rem" }} />
            Para pessoas físicas
          </Typography>
          {pessoa_fisica.map((dropdown_data, index) => (
            <Category
              key={index}
              title={dropdown_data.title}
              icon={<></>}
              style={{
                borderRadius: "0.4rem",
                padding: "0.4rem 0",
                background: "#754ba7",
              }}
              info={
                <div>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "1rem", color: "rgb(30, 30, 30)" }}
                  >
                    <PersonRoundedIcon /> O que é um BM-Curso?
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "rgb(60, 60, 60)", textAlign: "justify" }}
                  >
                    Os BM-Cursos são cursos de idioma preparados de uma forma
                    única para você. Isso porque eles são organizados de um modo
                    diferente do que existe atualmente no mercado. Nesse
                    sentido, nosso(a)s aluno(a)s têm, além de aulas preparadas
                    especialmente para ele(a)s, um acompanhamento semanal feito
                    por assessores da equipe BM Words. Os BM-Cursos são
                    divididos em duas modalidades: <b>BM-Fast</b> (de até 6
                    meses) e <b>BM-Life</b> (mais de 6 meses)
                    <a
                      href={`${ForNaturalPeoplesPATH}bm-cursos/o-que-sao`}
                      className="normal-archor purple"
                      style={{ fontSize: "1rem" }}
                    >
                      Saiba mais
                    </a>
                  </Typography>
                </div>
              }
              darkMode
            >
              {dropdown_data.items.map((item, index) => (
                <Item
                  key={index}
                  title={item.title}
                  href={`${ForNaturalPeoplesPATH}${item.href}`}
                  darkMode
                />
              ))}
            </Category>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.2rem",
            flexBasis: "max-content",
            justifyContent: "center",
            minWidth: "26rem",
            padding: "2rem 2.8rem",
            borderRadius: "0.8rem",
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              color: "var(--purple)",
              textAlign: "center",
            }}
          >
            <BusinessIcon sx={{ marginRight: "0.4rem" }} />
            Para empresas ou profissionais
          </Typography>
          {empresa_profissionais.map((dropdown_data, index) => (
            <Category
              key={index}
              title={dropdown_data.title}
              icon={<></>}
              style={{
                borderRadius: "0.4rem",
                padding: "0.4rem 0",
                background: "#754ba7",
              }}
              info={dropdown_data.info}
              darkMode
            >
              {dropdown_data.items.map((item, index) => (
                <Item
                  key={index}
                  title={item.title}
                  href={`${ForCompaniesOrProfessionalsPATH}${item.href}`}
                  darkMode
                />
              ))}
            </Category>
          ))}
          <div
            style={{ gap: "0.8rem", display: "flex", flexDirection: "column" }}
          >
            <Item
              title={"BM-Trad"}
              href={`${ForCompaniesOrProfessionalsPATH}bm-trad`}
              darkMode
              style={{
                borderLeft: "0.1rem solid white",
                fontWeight: "bold",
                borderLeft: "var(--purple)",
                borderRadius: "0.4rem",

                background: "#754ba7",
              }}
            />
            <Item
              title={"BM-Rev"}
              href={`${ForCompaniesOrProfessionalsPATH}bm-rev`}
              darkMode
              style={{
                borderLeft: "0.1rem solid white",
                fontWeight: "bold",
                borderLeft: "var(--purple)",
                borderRadius: "0.4rem",

                background: "#754ba7",
              }}
            />
          </div>
        </div>
      </Box>
    </Box>
  );
};
export default General;
