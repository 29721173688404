import { Container, Typography } from "@mui/material";
import ContatoForm from "../../../../Contato/ContatoForm";
import Collapse from "../Collapse";

import holding_lamp from "../../../../../assets/static/holding_lamp.jpg";
import Card from "../../../../../components/Card";

// mui icons
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

const initial_data = [
  {
    question: "O que é um BM-Curso?",
    answer: [
      {
        data: "Os BM-Cursos são cursos de idioma preparados de uma forma única para você. Isso porque eles são organizados de um modo diferente do que existe atualmente no mercado. Nesse sentido, nosso(a)s aluno(a)s têm, além de aulas preparadas especialmente para ele(a)s, um acompanhamento semanal feito por assessores da equipe BM Words.",
      },
      {
        renderAsComponent: true,
        data: (
          <Typography variant="subtitle1" sx={{ fontFamily: "var(--Raleway)" }}>
            Os BM-Cursos são divididos em duas modalidades: <b>BM-Fast</b> (de
            até 6 meses) e <b>BM-Life</b> (mais de 6 meses).
          </Typography>
        ),
      },
    ],
  },
  {
    question: "Quais são os BM-Cursos?",
    answer: [
      {
        renderAsComponent: true,
        data: (
          <Typography variant="subtitle1" sx={{ fontFamily: "var(--Raleway)" }}>
            Nossos BM-Cursos foram criados com o intuito de melhor atender aos
            objetivos e às necessidades de nosso(a)s aluno(a)s. Há sete cursos
            no total, que são chamados de <b>BM-Kids</b>, <b>BM-Pro</b>,{" "}
            <b>BM-Sênior</b>, <b>BM-Voyage</b>,<b>BM-Test</b>, <b>BM-VIP</b> e{" "}
            <b>BM-Talks</b>.
          </Typography>
        ),
      },
    ],
  },
  {
    question: "Em quais idiomas os BM-Cursos são oferecidos?",
    answer: [
      {
        data: "Os BM-Cursos são oferecidos nas línguas inglesa, francesa e portuguesa. Ao contratar os serviços dos BM-Cursos, o(a)s aluno(a)s recebem assessoria da equipe BM Words que conta com Mestres e Doutores, que têm uma formação de excelência nas melhores universidades do Brasil e do exterior.",
      },
    ],
  },
  {
    question: "Quem pode fazer?",
    answer: [
      {
        data: "Os BM-Cursos podem ser cursados por pessoas que queiram aprender ou se aperfeiçoar em uma das línguas oferecidas pela BM Words. A nossa assessoria é voltada para pessoas com diferentes idades e objetivos. A faixa etária atendida vai desde crianças com idade mínima de 3 anos até adultos que tenham uma idade mais avançada.",
      },
      {
        renderAsComponent: true,
        data: (
          <Typography variant="subtitle1" sx={{ fontFamily: "var(--Raleway)" }}>
            Os BM-Cursos são oferecidos individualmente ou em grupos de até seis
            pessoas e acontecem de forma on-line.
          </Typography>
        ),
      },
    ],
  },
  // {
  //   question: "Qual é o nosso diferencial?",
  //   answer: [
  //     {
  //       data: "Com uma proposta que seja, de fato, personalizada, os BM-Cursos oferecem um acompanhamento mais próximo do(a)s cliente(s). Isso porque a BM words acredita que o domínio de uma língua (seja materna, seja estrangeira) em todas as suas competências (escrita, oral, de leitura e de fala) pode acontecer de um modo bastante eficaz se o(a)s clientes estiverem em contato constante com ela.",
  //     },
  //     {
  //       data: "Além disso, os BM-Cursos para empresas oferecem a oportunidade de profissionais se especializarem na terminologia de sua área de atuação em inglês, francês ou português.",
  //     },
  //     {
  //       data: "Os BM-Cursos oferecidos para empresas têm um diferencial de possuir um foco nas necessidades dos funcionários ou até mesmo no serviço e/ou produto oferecido pela mesma.",
  //     },
  //     {
  //       data: "⮚ Para saber mais sobre a nossa abordagem e receber nossa proposta de um BM-Curso feita especialmente para você ou para os funcionários de sua empresa, entre em contato conosco.",
  //     },
  //   ],
  // },
];

const BmCursosInfo = () => {
  return (
    <div style={{ background: "rgb(240, 244, 247)", width: '100vw' }}>
      <Container maxWidth="lg">
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            padding: "4rem 0",
            flexDirection: "column",
            gap: "2rem",
            justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2.8rem", md: "4.2rem" },
              fontFamily: "var(--Freight)",
              fontWeight: "bold",
              marginTop: "4rem",
              color: "var(--purple)",
              textAlign: "center",
            }}
          >
            Serviços BM-Words
          </Typography>

          <Typography
            variant="h4"
            sx={{
              fontFamily: "var(--Freight)",
              fontWeight: "bold",
              marginBottom: "2rem",
              color: "var(--purple)",
              textAlign: "center",
            }}
          >
            Perguntas frequentes
          </Typography>

          {/* these components will be loaded for all routes */}
          {initial_data.map((data, index) => (
            <Collapse key={index} {...data} />
          ))}

          {/* <Card
            title={"Nosso diferencial."}
            text={
              "Dois aspectos se destacam como diferenciais: em primeiro lugar, nossa formação, que nos permite aplicar, no mercado, um conhecimento científico em Linguística consolidado; em segundo lugar, nossa proposta, que transforma serviços de idiomas em assessorias personalizadas. Assim, somos especialistas que, além de prestarem consultoria sobre assuntos relativos à linguagem, atuam, principalmente, como assessores de nossos clientes, acompanhando seu desenvolvimento de perto e adequando seu trabalho às necessidades deles."
            }
            bg_image={holding_lamp}
          /> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              gap: "3rem",
              padding: "4rem 0",
            }}
          >
            <Typography
              variant="h3"
              component="div"
              sx={{
                fontFamily: "var(--Freight)",
                fontWeight: "bold",
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              Quer saber mais? Estamos aqui para te ajudar.
            </Typography>

            <ContatoForm />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BmCursosInfo;
