import React from 'react'
import { TextField } from '@mui/material'
import Container from '../../Form/Container'
import SaveButton from '../../Form/SaveButton'
import { useNavigate } from 'react-router-dom'
import { POST } from '../../../utilities/requests'
import useForm from '../../../utilities/hooks/useForm'
import { renderToast } from '../../../utilities/alerts'

const AddSubjectTag = ({ editData }) => {
  const history = useNavigate()
  const { name = '', description = '' } = editData || {};

  const [loadingSave, setLoadingSave] = React.useState(false)
  const { form, errors, handleChange, handleBlur, validateForm } = useForm({
    name,
    description,
  })

  const handleSave = async () => {
    const isValid = validateForm(['description'])

    if (!isValid) {
      return
    }

    setLoadingSave(true)
    const response = await (editData ? update() : create())
    setLoadingSave(false)

    if (response) {
      renderToast('success', response.message)
      setTimeout(() => history('/home/subject/tags/list'), 1500)
    }
  }

  const create = async () => {
    return await POST(`subject/tag/add`, JSON.stringify(form))
  }

  const update = async () => {
    return await POST(`subject/tag/update`, JSON.stringify({ ...form, id: editData.id }))
  }

  return (
    <Container title='Cadastrar tag' subTitle='Crie tags, disponibilizando-as no sistema!'>
      <form onSubmit={(e) => { e.preventDefault(); handleSave() }}>
        <TextField
          fullWidth
          className='mt-3'
          name='name'
          label='Nome *'
          value={form.name}
          onChange={handleChange}
          helperText={errors.name ?? ' '}
          onBlur={handleBlur}
          error={Boolean(errors.name)}
        />

        <TextField
          fullWidth
          className='mt-3'
          name='description'
          label='Descrição'
          value={form.description}
          onChange={handleChange}
        />

        <SaveButton path={'/home/subject/tags/list'} loading={loadingSave} />
      </form>
    </Container>
  )
}

export default AddSubjectTag