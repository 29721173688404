import React, { useRef } from "react";
import { CircularProgress, Icon, Typography } from "@mui/material";
import { URL } from "../../../variables";
import ShowAlert from "../../../components/ShowAlert";

// icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

// local css
import "./index.css";
import { Box } from "@mui/system";
import { useEffect } from "react";
import LogosObj from "../../../utilities/logos";

const social_medias_button_style = {
  fontSize: "2.1rem",
  opacity: "0.6",
  cursor: "pointer",
  color: "white",
  "&:hover": { opacity: 1 },
};

const social_medias = [
  {
    link: "https://www.instagram.com/bm.words/",
    icon: <InstagramIcon sx={social_medias_button_style} />,
  },
  {
    link: "https://www.facebook.com/bmwords.com.br",
    icon: <FacebookIcon sx={social_medias_button_style} />,
  },
  {
    link: "https://www.linkedin.com/company/bmwords/about/?viewAsMember=true",
    icon: <LinkedInIcon sx={social_medias_button_style} />,
  },
];

const ContatoForm = () => {
  const [form, setForm] = React.useState({
    name: { value: "", error: false },
    email: { value: "", error: false },
    message: { value: "", error: false },
    phone: { value: "", error: false },
  });

  const logo = LogosObj.main.white;

  const ref = useRef(null);

  const [formSize, setFormSize] = React.useState(0);

  const [submitted, setSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // foca o input da div
  const focusInput = (e) => {
    e.currentTarget.lastChild.focus();
  };

  const phoneMaskBrazil = (e) => {
    var element = e.target;
    var isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    var inputValue = element.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
    inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

    setForm({ ...form, phone: { value: inputValue, error: false } });
  };

  async function storeNews(e) {
    e.preventDefault();
    const store = {
      name: form.name.value,
      email: form.email.value,
      phone: form.phone.value,
      message: form.message.value,
    };
    let result = "";
    try {
      setIsLoading(true);
      result = await fetch(`${URL}api/newsletter/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          // 'Authorization': `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(store),
      });
      let keys = Object.keys(form);
      let form2 = { ...form };
      keys.forEach((item) => {
        console.log(item);
        form2[item].value = "";
      });
      setForm(form2);
      setFormSize(ref.current.offsetWidth);
      setIsLoading(false);
      setTimeout(() => {
        setSubmitted(true);
      }, 1000);
    } catch {
      console.log(result);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        borderRadius: "1rem",
        overflow: "hidden",
        marginTop: { xs: "6rem", md: 0 },
        boxShadow: { xs: "none", md: "0 1rem 2rem rgba(0, 0, 0, 0.1)" },
        flexWrap: { xs: "wrap", md: "nowrap" },
        width: "100%",
      }}
    >
      {/* {submitted && (
        <ShowAlert
          message="Sua mensagem foi registrada e logo será respondida. Obrigado por entrar em contato conosco. #bmwords"
          type="success"
        />
      )} */}
      <Box
        sx={{
          backgroundColor: "var(--purple)",
          padding: {
            xs: "4rem 1rem",
            md: "4rem",
          },
          gap: {
            xs: "4rem",
            md: "1rem",
          },
          color: "white",
          display: "flex",
          width: { xs: "100%!important" },
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: {
            xs: "center",
            md: "flex-start",
          },
          gap: {
            xs: "1rem",
            md: "0",
          },
        }}>
          <Typography
            variant="h3"
            sx={{ fontFamily: "var(--Freight)", fontWeight: "bold" }}
          >
            Fale conosco
          </Typography>
          {submitted ? (
            <Typography
              variant="body1"
              sx={{
                fontFamily: "var(--Raleway)",
                color: "rgb(230, 230, 230)",
                width: { xs: "100%" },
                marginBottom: "0.6rem",
                fontSize: "1.2rem",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              <b>Agradecemos o seu contato.</b> Sua mensagem será respondida em breve.
              Enquanto isso, que tal acompanhar nossas redes sociais?
              #BeMoreanywhere
            </Typography>
          ) : (
            <Typography
              variant="body1"
              sx={{
                fontFamily: "var(--Raleway)",
                color: "rgb(210, 210, 210)",
                width: { xs: "100%"},
                marginBottom: "0.6rem",
                textAlign: {
                  xs: "center",
                  md: "left",
                },
                maxWidth: {
                  xs: "24rem",
                  md: "auto",
                },
              }}
            >
              Envie-nos uma mensagem e entraremos em contato com você.
            </Typography>
          )}

          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            {social_medias.map((social_media, index) => (
              <a key={index} href={social_media.link} target={"_blank"}>
                {social_media.icon}
              </a>
            ))}
          </div>
        </Box>
        <div style={{ width: "100%" }}>
          <img
            src={logo}
            alt="BM Logotipo"
            style={{
              maxWidth: "14rem",
              margin: "0 auto",
            }}
          />
        </div>
      </Box>

      <Box
        ref={ref}
        sx={{
          backgroundColor: "white",
          padding: {
            xs: "1rem",
            md: "4rem",
          },
          width: { xs: "100%!important", md: "max-content" },
          overflow: "hidden",
          opacity: 1,
          // maxWidth: formSize === 0 ? "auto" : formSize,
          transition: "1s ease",
          ...(submitted && { maxWidth: "0px", padding: "4rem 0", opacity: 0 }),
        }}
      >
        <form
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          onSubmit={(e) => storeNews(e)}
        >
          <div
            className="normal-input"
            onClick={(e) => {
              focusInput(e);
            }}
          >
            <PersonIcon sx={{ color: "rgb(100, 100, 100)" }} />
            <input
              id="teste"
              type="text"
              placeholder="Nome"
              value={form.name.value}
              onChange={(e) =>
                setForm({ ...form, name: { value: e.target.value } })
              }
              error={form.name.error}
              required
            />
          </div>
          <div
            className="normal-input"
            onClick={(e) => {
              focusInput(e);
            }}
          >
            <EmailIcon sx={{ color: "rgb(100, 100, 100)" }} />
            <input
              id="teste"
              type="email"
              placeholder="E-mail"
              value={form.email.value}
              onChange={(e) =>
                setForm({ ...form, email: { value: e.target.value } })
              }
              required
            />
          </div>
          <div
            className="normal-input"
            onClick={(e) => {
              focusInput(e);
            }}
          >
            <LocalPhoneIcon sx={{ color: "rgb(100, 100, 100)" }} />
            <input
              maxLength="15"
              type="text"
              placeholder="Telefone"
              value={form.phone.value}
              onChange={(e) => {
                setForm({ ...form, phone: { value: e.target.value } });
                phoneMaskBrazil(e);
              }}
              required
            />
          </div>
          <textarea
            className="normal-input"
            placeholder="Mensagem"
            style={{
              height: "10rem",
              width: "100%",
              minHeight: "3.6rem",
              // minWidth: "26rem",
              maxHeight: "28vh",
            }}
            required
            value={form.message.value}
            onChange={(e) =>
              setForm({ ...form, message: { value: e.target.value } })
            }
          ></textarea>
          {!isLoading ? (
            <input
              type="submit"
              className="form-submit-button"
              style={{
                padding: "1rem 2rem",
                backgroundColor: "var(--purple)",
                border: "none",
                outline: "none",
                color: "white",
                fontFamily: "var(--Raleway)",
                width: "max-content",
                alignSelf: "flex-end",
                borderRadius: "1rem",
                fontSize: "1rem",
              }}
            />
          ) : (
            <div
              style={{
                padding: "1rem 2rem",
                backgroundColor: "var(--purple)",
                border: "none",
                outline: "none",
                width: "max-content",
                alignSelf: "flex-end",
                borderRadius: "1rem",
              }}
            >
              <CircularProgress style={{ color: "white" }} size="1.1rem" />
            </div>
          )}
        </form>
      </Box>
    </Box>
  );
};

export default ContatoForm;
