import { Container } from "@mui/system";
import LogosObj from "../../utilities/logos";
import Card from "../../components/Card";

import "./index.modules.css";

// imgs
import business from "../../assets/static/business.jpg";
import holding_earth from "../../assets/static/holding_earth.png";
import holding_lamp from "../../assets/static/holding_lamp.jpg";
import choices from "../../assets/static/choices.jpg";

const SobreNosPage = () => {
  const logo = LogosObj.main.purple;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2rem 1rem 6rem 1rem",
        backgroundColor: 'rgb(240, 244, 247)',
        width: '100vw'
      }}
    >
      <img
        style={{ maxWidth: "20rem", width: '100%', margin: "6rem auto" }}
        src={logo}
        alt="BM Words Logotipo"
      />

      <Container
        maxWidth="xl"
        sx={{ gap: "4rem", display: "flex", flexDirection: "column", padding: 0, minWidth: 'auto' }}
      >
        <Card
          title={"Quem somos?"}
          text={
            "A BM Words é uma empresa que presta serviços de assessoria linguística e é por isso que nosso time é composto por assessores (e não consultores). Somos especialistas que, além de resolver problemas específicos sobre linguagem, acompanham de perto nosso(a)s clientes durante nosso pós-atendimento, adequando o nosso trabalho às suas demandas individuais. Temos, portanto, o papel de ser uma ponte sólida e segura entre as necessidades de nossos clientes e uma performance linguística de sucesso."
          }
          to_right
          bg_image={business}
        />

        <Card
          title={"Qual é nossa missão?"}
          text={
            "Aperfeiçoar a formação de nossos clientes em termos de performance linguística. É mostrar que as palavras (em língua materna ou estrangeira) consistem no ingrediente fundamental para a receita de seu sucesso profissional e pessoal."
          }
          to_right
          bg_image={holding_earth}
        />

        <Card
          title={"Qual é o nosso diferencial?"}
          text={
            "Dois aspectos se destacam como diferenciais: em primeiro lugar, nossa formação, que nos permite aplicar, no mercado, um conhecimento científico em Linguística consolidado; em segundo lugar, nossa proposta, que transforma serviços relacionados a idiomas em assessorias personalizadas. Assim, nossos especialistas que, além de prestarem consultoria sobre assuntos relativos à linguagem, atuam, principalmente, como assessores de nossos clientes, acompanhando seu desenvolvimento de perto e adequando o nosso trabalho às necessidades deles."
          }
          to_right
          bg_image={holding_lamp}
        />

        <Card
          title={"Por que nos escolher?"}
          text={
            "Nossa equipe é composta por Mestres, Doutores e Pós-Doutores em Linguística formados em renomadas universidades do Brasil e do exterior. Nossa formação e experiência de mercado acumuladas há mais de 10 anos nos colocam à frente em matéria de serviços relacionados à linguagem."
          }
          to_right
          bg_image={choices}
        />
      </Container>
    </div>
  );
};

export default SobreNosPage;
